import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import styles from './recovery-form.module.sass';
import Button from '../ui/button/Button';
import { api } from '../../services/api';

const RecoveryForm = () => {
  const navigate = useNavigate();
  const [resetingEmail, setResettingEmail] = useState('');

  const resetEmailHandler = (e: any) => {
    e.preventDefault();
    api
      .post('/user/reset-password', { email: resetingEmail })
      .then((response) => {
        if (response.status === 200) {
          navigate('/recovery-alert');
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <form className={styles.recoveryForm} onSubmit={resetEmailHandler}>
      <h2 className={styles.title}>Забыли пароль?</h2>
      <p>
        Укажите адрес электронной почты для получения инструкции по смене пароля
      </p>
      <label>
        <input
          name="email"
          type="email"
          className={styles.emailResetInput}
          placeholder="Email"
          onChange={(evt: any) => setResettingEmail(evt.target.value)}
          value={resetingEmail}
        />
      </label>
      <Button type="submit" variant={'accent'}>
        Продолжить
      </Button>
      <button className={styles.returnLink} onClick={() => navigate(-1)}>
        Назад
      </button>
    </form>
  );
};
export default RecoveryForm;
