import { api } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import styles from '../ResetPasswordPage/reset-password.module.sass';
import { useState } from 'react';
import Logo from '../../components/ui/logo/Logo';

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');

  const getCode = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramValue = searchParams.get('reset_key');
    return paramValue;
  };

  const code = getCode();
  console.log(code);

  const postCodeAndPass = (e: any) => {
    e.preventDefault();
    api
      .post('/user/create-new-password', {
        reset_key: code,
        new_password: newPassword,
      })
      .then((response) => {
        if (response.status === 200) {
          navigate('/reset-password-success');
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <form className={styles.resetPassForm} onSubmit={postCodeAndPass}>
      <Logo />
      <div className={styles.resetPassInputContainer}>
        <h2 className={styles.resetPassHeader}>Создание нового пароля</h2>
        <label>
          <input
            className={styles.resetPassInput}
            type="text"
            value={newPassword}
            onChange={(evt: any) => setNewPassword(evt.target.value)}
            placeholder="Введите новый пароль"
            required
          />
        </label>
        <button type="submit" className={styles.resetPassBtn}>
          Создать
        </button>
      </div>
    </form>
  );
}
