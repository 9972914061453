import { CSSProperties, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import useMailingDaily from '../../../hooks/useMailingDaily';
import { api } from '../../../services/api';
import { setTariff } from '../../../store/tariff-slice/tariffSlice';
import { setMailedToday } from '../../../store/task-slice/taskSlice';
import { Roles } from '../../../consts/consts';
import { ParserTask } from '../../../types/parserTask';
import styles from './progress-item.module.sass';

interface ProgressItemProps {
  style?: CSSProperties;
  content: 'mailing' | 'parsing';
}

const ProgressItem = ({ style, content }: ProgressItemProps) => {
  const dispatch = useAppDispatch();
  const [dataTasks, setDataTasks] = useState<ParserTask[]>();
  const [sortedDates] = useMailingDaily(dataTasks as ParserTask[]);

  const currentTariff = useAppSelector((state) => state.Tariff.currentTariff);
  const mailedTodayComments = useAppSelector((state) => state.Task.mailedToday);
  const user = useAppSelector((state) => state.UserData.user);
  const userSubscribe = user?.subscribe;

  useEffect(() => {
    if (userSubscribe) {
      api
        .get(`/tariff/${userSubscribe.tariff_id}`)
        .then(({ data }) => dispatch(setTariff(data)))
        .catch((err) => console.error(err));
    }
  }, [userSubscribe]);

  useEffect(() => {
    if (
      user?.role.name === Roles.Admin ||
      user?.role.name === Roles.SuperUser
    ) {
      api
        .get('/telegram/tasks/')
        .then((response) => setDataTasks(response.data))
        .catch((error) => console.error(error));
    } else {
      api
        .get('/telegram/tasks/me')
        .then((response) => setDataTasks(response.data))
        .catch((error) => console.error(error));
    }
  }, []);

  useEffect(() => {
    dispatch(setMailedToday(sortedDates[sortedDates.length - 1]));
  }, [sortedDates]);

  const leftParsers: number | undefined =
    userSubscribe?.tariff_options.parsers_per_day;

  const totalParsers: number | undefined =
    currentTariff !== null
      ? Number(currentTariff.options.parsers_per_day)
      : undefined;

  const spentParsers =
    leftParsers && totalParsers ? totalParsers - leftParsers : 0;

  const totalComments: number | undefined =
    currentTariff !== null
      ? Number(currentTariff.options.count_of_messages)
      : undefined;

  const spentComments = mailedTodayComments ? mailedTodayComments : 0;

  const leftComments: number | undefined = totalComments
    ? totalComments - spentComments
    : 0;

  const progressParsing =
    leftParsers && totalParsers ? (leftParsers / totalParsers) * 100 : 0;

  const progressMailing =
    leftComments && totalComments ? (leftComments / totalComments) * 100 : 0;

  return (
    <div
      className={`${styles.progressWrapper} ${styles[content]}`}
      style={style}
    >
      <h3 className={styles.progressTitle}>
        <span className={styles.progressBold}>
          {content === 'parsing'
            ? currentTariff.options.parsers_per_day
            : currentTariff.options.count_of_messages}
        </span>{' '}
        {`${content === 'parsing' ? 'парсеров' : 'сообщений'}/день`}
      </h3>
      <div className={styles.progressBackground}>
        <div
          className={styles.progressBar}
          style={{
            width: `${
              content === 'parsing' ? progressParsing : progressMailing
            }%`,
          }}
        ></div>
      </div>
      <ul className={styles.progressParsers}>
        <li className={styles.progressParser}>
          <p className={styles.progressText}>
            <span className={styles.progressQuantity}>
              {content === 'parsing' ? leftParsers : leftComments}
            </span>
            Доступно
          </p>
        </li>
        <li className={styles.progressParser}>
          <p className={styles.progressText}>
            <span className={styles.progressQuantity}>
              {content === 'parsing' ? spentParsers : spentComments}
            </span>
            Потрачено
          </p>
        </li>
      </ul>
    </div>
  );
};
export default ProgressItem;
