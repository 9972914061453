import { FC, ReactNode } from 'react';
import { CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../table.module.sass';

interface TableContainerProps {
  children: ReactNode;
  style?: CSSProperties;
  onScroll?: (e: React.UIEvent<HTMLTableSectionElement>) => void;
}

const TableContainer: FC<TableContainerProps> = ({
  children,
  style,
  onScroll,
}) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className={styles.container}>
      <div
        className={`${styles.innerContainer}
          ${pathname === '/dashboard' ? styles.noScrollInnerContainer : ''}`}
        style={style}
        onScroll={onScroll}
      >
        {children}
      </div>
    </div>
  );
};
export default TableContainer;
