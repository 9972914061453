import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { patchUser } from '../../store/user-slice/apiActions';
import Profile from '../../components/Profile/Profile';
import DocumentsPage from '../DocumentsPage/DocumentsPage';
import PassData from '../../components/Profile/PassData/PassData';

import styles from './profilePage.module.sass';

const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.UserData.user);

  if (!user) return null;

  return (
    <main className={styles.profileWrapper}>
      <Profile
        onSubmit={(formData) => dispatch(patchUser(formData)).unwrap()}
        user={user}
      />
      <div className={styles.passWrapper}>
        <PassData />
      </div>
      {/* <div className={styles.footer}>
        <hr className={styles.footerHr} />
        <div className={styles.docs}>
          <DocumentsPage />
        </div>
      </div> */}
    </main>
  );
};

export default ProfilePage;
