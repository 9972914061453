import { createSlice } from '@reduxjs/toolkit';
import { fetchTasks, fetchDeletedTasks } from './apiActions';

export const taskSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasks: [],
    isLoading: false,
    error: null as string | null,
    deletedTasks: [],
    mailedToday: 0 as number | null,
    invitingToday: 0 as number | null,
  },
  reducers: {
    setMailedToday: (state, action) => {
      state.mailedToday = action.payload;
    },
    setInvitingToday: (state, action) => {
      state.invitingToday = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tasks = action.payload;
        state.error = null;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error?.message || null;
      })
      .addCase(fetchDeletedTasks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDeletedTasks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deletedTasks = action.payload;
        state.error = null;
      })
      .addCase(fetchDeletedTasks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error?.message || null;
      });
  },
});

export const { setMailedToday } = taskSlice.actions;
export const { setInvitingToday } = taskSlice.actions;
