import { FC, useEffect, useState } from 'react';

import { api } from '../../../services/api';
import { BASE_URL } from '../../../consts/consts';

import IconButton from '../iconButton/IconButton';
import Loader from '../loader/loader';
import { useAppDispatch } from '../../../hooks/redux';
import {
  fetchTasks,
  fetchDeletedTasks,
} from '../../../store/task-slice/apiActions';
import { ReactComponent as CalendarIcon } from '../../../assets/images/icons/calendar.svg';
import { ReactComponent as UsersIcon } from '../../../assets/images/icons/users.svg';
import { ReactComponent as TimeIcon } from '../../../assets/images/icons/time.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg';
import { ReactComponent as StarIcon } from '../../../assets/images/icons/star.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/images/icons/task-error-icon.svg';
import { ReactComponent as RepeatIcon } from '../../../assets/images/icons/repeat.svg';
import { ReactComponent as TrashIcon } from '../../../assets/images/icons/trash.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/icons/download.svg';
import { ReactComponent as WaitingIcon } from '../../../assets/images/icons/waiting.svg';
import { ParserTask, WorkStatus } from '../../../types/parserTask';
// import { RefreshTasks } from '../../CompletedTasks/RefreshTasks';
import styles from './taskItem.module.sass';
import Tooltip from '../tooltip/tooltip';

interface TaskItemProps {
  task: ParserTask;
  isFavorite?: boolean;
  isDeleted?: boolean;
  operation: 'parsing' | 'mailing';
}

const StatusIcon = ({ status }: { status: WorkStatus }) => {
  switch (status) {
    case 'success':
      return (
        <CheckIcon
          className={styles.iconSuccess}
          style={{ width: '12px', height: '12px' }}
        />
      );
    case 'in_waiting':
      return (
        <WaitingIcon
          className={styles.iconWaiting}
          style={{ width: '12px', height: '12px' }}
        />
      );
    case 'in_processing':
      return <Loader width={12} height={12} />;
    case 'failed':
      return (
        <ErrorIcon
          className={styles.iconFailed}
          style={{ width: '12px', height: '12px' }}
        />
      );
  }
};

const TaskItem: FC<TaskItemProps> = ({
  task,
  isFavorite,
  isDeleted,
  operation,
}) => {
  const [isTaskFavorite, setIsTaskFavorite] = useState<boolean>(task.favorite);
  const dispatch = useAppDispatch();

  const refreshTasks = () => {
    const limit = 10;
    const offset = 0;
    dispatch(fetchTasks({ limit, offset, operation }));
    dispatch(fetchDeletedTasks());
  };

  const toggleFavorite = () => {
    api
      .post(`/telegram/tasks/favorite_parsing/${task.id}`)
      .then(() => {
        refreshTasks();
      })
      .catch((e) => console.error(e));
  };

  const repeatHandler = () => {
    api
      .post(`/telegram/tasks/restart_parsing/${task.id}`)
      .then(() => {
        refreshTasks();
      })
      .catch((e) => console.error(e));
  };

  const deleteHandler = () => {
    isDeleted
      ? api
          .delete(`/telegram/tasks/me/trash?task_name=${task.title}`)
          .then(() => {
            refreshTasks();
          })
          .catch((e) => console.error(e))
      : api
          .delete(`/telegram/tasks/me?task_name=${task.title}`)
          .then(() => {
            refreshTasks();
          })
          .catch((e) => console.error(e));
  };

  useEffect(() => {
    setIsTaskFavorite(task.favorite);
  }, [task]);

  return (
    <div className={styles.toolItem}>
      <h5 className={styles.toolName}>
        <StatusIcon status={task.work_status} />
        {task.title}
      </h5>
      <div className={styles.toolCharacteristics}>
        <span className={styles.toolDate}>
          <CalendarIcon style={{ width: '10px', height: '10px' }} />
          <span>
            {new Date(task.created_at).toLocaleTimeString('ru-Ru', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </span>
        <span className={styles.toolMailings}>
          <UsersIcon style={{ width: '12px', height: '12px' }} />
          <span>{task.data_count}</span>
        </span>
        <span className={styles.toolDuration}>
          <TimeIcon style={{ width: '12px', height: '12px' }} />
          <span>{task.time_work}</span>
        </span>
      </div>
      <div className={styles.toolsOptions}>
        <IconButton
          onClick={() =>
            window.open(
              `${BASE_URL}/telegram/tasks/me/download?task_name=${task.title}`,
              '_blank',
            )
          }
          disabled={
            task.work_status === 'failed' || task.operation === 'mailing'
          }
        >
          <>
            <Tooltip text="Загрузить результат" direction="left">
              <DownloadIcon style={{ width: '16px', height: '16px' }} />
            </Tooltip>
            <span className="visually-hidden">Загрузить результат</span>
          </>
        </IconButton>
        <IconButton onClick={toggleFavorite}>
          <>
            <Tooltip
              text={
                isTaskFavorite
                  ? 'Удалить из избранного'
                  : 'Добавить в избранное'
              }
              direction="left"
            >
              <StarIcon
                fill={isTaskFavorite ? 'gold' : 'none'}
                style={{ width: '16px', height: '16px' }}
              />
            </Tooltip>
            <span className="visually-hidden">
              {isFavorite ? 'Удалить из избранного' : 'Добавить в избранное'}
            </span>
          </>
        </IconButton>
        <IconButton
          onClick={repeatHandler}
          disabled={
            task.work_status !== 'failed' || task.operation !== 'parsing'
          }
        >
          <>
            <Tooltip text="Повторить" direction="left">
              <RepeatIcon style={{ width: '12px', height: '12px' }} />
            </Tooltip>
            <span className="visually-hidden">Повторить</span>
          </>
        </IconButton>
        <IconButton onClick={deleteHandler}>
          <>
            <Tooltip
              text={isDeleted ? 'Удалить' : 'В корзину'}
              direction="left"
            >
              <TrashIcon style={{ width: '16px', height: '16px' }} />
            </Tooltip>
            <span className="visually-hidden">Удалить</span>
          </>
        </IconButton>
      </div>
    </div>
  );
};

export default TaskItem;
