import { object, number } from 'yup';
import { ValidationErrors } from '../../consts/validation';

export const replenishmentSchema = object().shape({
  amount: number()
    .typeError(ValidationErrors.numberValidation.numberType)
    .required(ValidationErrors.required)
    .test({
      message: ValidationErrors.numberValidation.minReplishment(200),
      test: (value) => typeof value === 'number' && value >= 200,
    }),
});
