import styles from './infoTooltip.module.sass';

import { ReactComponent as InfoIcon } from '../../../assets/images/icons/info.svg';
import Tooltip from '../tooltip/tooltip';

type InfoTooltipProps = {
  text: string;
  direction?: 'left' | 'right';
};

const InfoTooltip = ({ text, direction }: InfoTooltipProps) => {
  return (
    <Tooltip
      text={text}
      direction={direction}
      style={{ position: 'absolute', top: 0, right: 0 }}
    >
      <InfoIcon className={styles.infoIcon} />
    </Tooltip>
  );
};

export default InfoTooltip;
