import React, { useState, useRef } from 'react';
import styles from './profile.module.sass';
import { User } from '../../types/user';
import { BASE_URL } from '../../consts/consts';
import { Formik, Form, FormikHelpers } from 'formik';
import { mainDataValidation } from './MainData/main-validation-schema';
import { ReactComponent as PhotoChangeIcon } from '../../assets/images/icons/Photo change.svg';
import TextInput from '../ui/input/TextInput';
import Loader from '../ui/loader/loader';
import TimezonePicker from '../../components/TimezonePicker/TimezonePicker';
import { useAppDispatch } from '../../hooks/redux';
import { patchUser } from '../../store/user-slice/apiActions';

type InitialValues = {
  picture: string;
  firstname: string;
  lastname: string;
  email: string;
  phone_number: string;
};

type MainDataProps = {
  user: User;
  variant?: 'admin' | 'user';
  onSubmit: (formData: FormData) => Promise<User>;
};

export default function Profile({
  user,
  variant = 'user',
  onSubmit,
}: MainDataProps) {
  const [isFetching, setIsFetching] = useState(false);
  const avatarRef = useRef<HTMLImageElement>(null);
  const dispatch = useAppDispatch();

  const initialValues: InitialValues = {
    picture: '',
    firstname: user.firstname || '',
    lastname: user.lastname || '',
    email: user.email || '',
    phone_number: user.phone_number,
  };

  const handleSubmit = async (
    values: InitialValues,
    actions: FormikHelpers<InitialValues>,
  ) => {
    setIsFetching(true);
    const formData = new FormData();
    let key: keyof typeof values;
    for (key in values) {
      if (!(values[key] === initialValues[key])) {
        formData.append(key, values[key]);
      }
    }
    await onSubmit(formData)
      .then((updatedUser) => {
        actions.resetForm({
          values: {
            picture: '',
            firstname: updatedUser.firstname,
            lastname: updatedUser.lastname,
            email: updatedUser.email,
            phone_number: updatedUser.phone_number,
          },
        });
      })
      .catch(() => {
        actions.resetForm();
        if (!avatarRef.current) return;
        avatarRef.current.src = `${BASE_URL}/${user?.avatar_url}`;
      })
      .finally(() => setIsFetching(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={mainDataValidation}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        dirty,
        setFieldValue,
      }) => (
        <Form>
          <div className={styles.wrapper}>
            <div className={styles.avatarWrapper}>
              <div className={styles.avatar}>
                <img
                  ref={avatarRef}
                  className={styles.avatarImg}
                  src={`${BASE_URL}/${user?.avatar_url}`}
                  alt=""
                />
                <input
                  className={styles.avatarInput}
                  type="file"
                  name="picture"
                  id="avatar"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    if (e.target.files?.[0] && avatarRef.current) {
                      const avatar = e.target.files[0];
                      setFieldValue('picture', avatar);
                      avatarRef.current.src = URL.createObjectURL(avatar);
                    }
                  }}
                />
                <label className={styles.avatarlabel} htmlFor="avatar">
                  <PhotoChangeIcon />
                </label>
                <div className={styles.pictureError}>
                  {errors.picture && touched.picture ? errors.picture : ''}
                </div>
              </div>
              <span className={styles.pictureInfo}>JPEG, PNG до 10MB</span>
            </div>
            <div className={styles.wrap_mainInfo}>
              <div
                className={`${styles.columnWrapper} ${
                  variant === 'admin' ? styles.adminColumn : ''
                }`}
              >
                <h3 className={styles.header}>Основная информация</h3>
                <div className={styles.wrapper_mainInfo}>
                  <div className={styles.name}>
                    <div className={styles.firstName}>
                      <TextInput
                        name="firstname"
                        type="text"
                        placeholder="Имя"
                        value={values.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          dirty && errors.firstname && touched.firstname
                            ? errors.firstname
                            : ''
                        }
                      />
                    </div>
                    <div className={styles.lastName}>
                      <TextInput
                        name="lastname"
                        type="text"
                        placeholder="Фамилия"
                        value={values.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          dirty && errors.lastname && touched.lastname
                            ? errors.lastname
                            : ''
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.emailTel}>
                    <div className={styles.email}>
                      <TextInput
                        className={styles.inputEmail}
                        name="email"
                        type="text"
                        placeholder="Электронная почта"
                        hintMessage="Внимание! Используется для входа в аккаунт"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          dirty && errors.email && touched.email
                            ? errors.email
                            : ''
                        }
                      />
                    </div>
                    <div className={styles.telephone}>
                      <TextInput
                        name="phone_number"
                        type="text"
                        placeholder="Телефон"
                        value={values.phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.phone_number && touched.phone_number
                            ? errors.phone_number
                            : ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h3 className={styles.headerAdditionally}>Дополнительно</h3>
              <div className={styles.additionally}>
                <div className={styles.timeZone}>
                  <TimezonePicker
                    selectedTimezone={user.timezone}
                    onChange={(timezone) => {
                      const formData = new FormData();
                      formData.append('timezone', String(timezone));
                      dispatch(patchUser(formData));
                    }}
                  />
                </div>
                <div className={styles.promocode}>
                  <TextInput
                    disabled
                    className={styles.promocodeInput}
                    name="promocode"
                    type="text"
                    placeholder="Введите промокод"
                  />
                </div>
              </div>
              <div className={styles.buttonSave}>
                <button
                  disabled={!dirty && !isFetching}
                  type="submit"
                  className={styles.btn}
                >
                  {isFetching ? (
                    <div className={styles.loader}>
                      <Loader width={24} height={24} />
                    </div>
                  ) : (
                    'Сохранить'
                  )}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
