import { object, string } from 'yup';
import {
  // NUMBER_ONLY_REGEX,
  ValidationErrors,
} from '../../../../consts/validation';

// const NUMBER_LENGTH = 20;

export const createBotValidation = object({
  apiId: string().required(ValidationErrors.required),
  apiHash: string().required(ValidationErrors.required),
  phoneNumber: string().required(ValidationErrors.required),
  proxyHost: string().required(ValidationErrors.required),
  proxyPort: string().required(ValidationErrors.required),
});

export const verificationCodeValidation = object({
  code: string().required(ValidationErrors.required),
});
