import { useEffect, useState } from 'react';
import AdminBots from '../../../components/Admin/AdminBots/AdminBots';
import styles from './bots-admin-page.module.sass';
import Loader from '../../../components/ui/loader/loader';
import { longApi } from '../../../services/longApi';

export default function BotsAdminPageAll() {
  const [bots, setBots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    longApi
      .get('/telegram/tgaccount/')
      .then((response) => {
        setBots(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      ) : (
        <div className={styles.tableWrapper}>
          <AdminBots bots={bots} />
        </div>
      )}
    </div>
  );
}
