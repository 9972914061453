import { MenuCategory, MenuItem } from '../menu-items';
import styles from './admin-bar.module.sass';
import NavLink from '../../ui/navLink/NavLink';
import { FC, ReactElement, useState } from 'react';
import { Routes } from '../../../router/routes';
import Logo from '../../ui/logo/Logo';
import { ReactComponent as IconLogout } from '../../../assets/images/icons/logout.svg';
import { ReactComponent as IconPartner } from '../../../assets/images/icons/watch.svg';
import Button from '../../ui/button/Button';
import ModalWindow from '../../ui/modal-window/ModalWindow';
import { useAppDispatch } from '../../../hooks/redux';
import { logout } from '../../../store/user-slice/apiActions';
import { logoutChannel } from '../../../services/logout-channel';
import { useNavigate } from 'react-router-dom';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';

interface PropTypes {
  menuItems: { text: string; link: Routes; icon?: ReactElement }[];
  currentPage: MenuItem;
}

const AdminBar: FC<PropTypes> = ({ menuItems, currentPage }) => {
  const currentLinkPosition =
    currentPage.menuCategory === MenuCategory.Navbar
      ? currentPage.position
      : -1;

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const rederectToHomePage = async () => {
    setIsOpen(false);
    await dispatch(logout());
    logoutChannel.postMessage('logout');
    navigate(Routes.Home);
  };

  const handleLinkClick = () => {
    window.location.href = 'https://partner-system.ru/admin';
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.adminBar}>
        <nav className={styles.adminBar__links}>
          <div className={styles.header__logoWrapper}>
            <Logo />
          </div>
          {menuItems.map((item, index) => (
            <div className={styles.adminBar__link} key={item.text}>
              <NavLink
                link={item.link}
                text={item.text}
                icon={item.icon}
                isActive={index === currentLinkPosition}
              />
            </div>
          ))}
        </nav>
        <div className={styles.buttonOutWrapper}>
          <form action="https://partner-system.ru/main">
            <div className={styles.formContainer}>
              <span className={styles.iconPartner} onClick={handleLinkClick}>
                <IconPartner />
              </span>
              <button className={styles.partnerSystem}>
                Партнерский кабинет
              </button>
            </div>
          </form>
          <div className={styles.buttonOut} onClick={() => setIsOpen(true)}>
            <span className={styles.iconOut}>
              <IconLogout />
            </span>
            <button className={styles.profilePage_btn}>Выйти</button>
          </div>
        </div>
      </div>
      <ModalWindow
        isActive={isOpen}
        setActive={setIsOpen}
        style={{
          paddingTop: '34px',
          paddingBottom: '40px',
          paddingLeft: '24px',
          paddingRight: '24px',
          width: '500px',
        }}
      >
        <h2 style={{ marginBottom: '52px', textAlign: 'center' }}>
          Вы уверены, что хотите выйти?
        </h2>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
          <Btn_transparent variant="blue" onClick={() => setIsOpen(false)}>
            Нет
          </Btn_transparent>
          <Btn_transparent variant="standart" onClick={rederectToHomePage}>
            Да
          </Btn_transparent>
        </div>
      </ModalWindow>
    </div>
  );
};

export default AdminBar;
