import InvitingForm from '../../components/Inviting/InvitingForm';
import styles from './styles.module.sass';

const InvitingPage = () => {
  return (
    <div className={styles.wrapper}>
      <InvitingForm />
    </div>
  );
};

export default InvitingPage;
