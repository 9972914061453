import React, { useState } from 'react';
import styles from './examples.module.sass';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as Examples_1 } from '../../../assets/images/examples_1.svg';
import { ReactComponent as Examples_2 } from '../../../assets/images/examples_2.svg';
import { ReactComponent as Examples_3 } from '../../../assets/images/examples_3.svg';

export default function Examples() {
  const [currentSlide, setCurrentSlide] = useState<number>(1);

  const settings = {
    centerMode: true,
    centerPadding: '350px',
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'ease-in-out',
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next);
    },
  };

  return (
    <div className={styles.examples__wrapper}>
      <div className={styles.examples__header}>
        <span>
          Настройка рекламной кампании через личный кабинет за пару кликов
        </span>
      </div>
      <div className={styles.carouselContainer}>
        <Slider {...settings}>
          <div className={styles.img_containeer}>
            <Examples_1
              className={currentSlide === 0 ? styles.centerImage : ''}
            />
          </div>
          <div>
            <Examples_2
              className={currentSlide === 1 ? styles.centerImage : ''}
            />
          </div>
          <div>
            <Examples_3
              className={currentSlide === 2 ? styles.centerImage : ''}
            />
          </div>
        </Slider>
      </div>
    </div>
  );
}
