import { Form, Formik, FormikHelpers } from 'formik';
import { useRef, useState } from 'react';
import { ReactComponent as PhotoChangeIcon } from '../../../assets/images/icons/Photo change.svg';
import DefaultAvatarPath from '../../../assets/images/default-avatar.png';
import { Bot } from '../../../types/bot';
import { adminBotCard } from './validation-schema';
import { longApi } from '../../../services/longApi';
import TextInput from '../../../components/ui/input/TextInput';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';
import styles from './admin-bot-card.module.sass';
import Loader from '../../ui/loader/loader';
import ModalCentered from '../../../components/ui/modal-window/ModalCentered';

interface AdminBotCardProps {
  bot: Bot;
}

type InitialValues = {
  first_name: string;
  last_name: string;
  bio: string;
  username: string;
  avatar: string;
  [key: string]: string;
};

const AdminBotCard = ({ bot }: AdminBotCardProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [base64String, setBase64String] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalErrorShow, setModalErrorShow] = useState(false);

  const initialValues: InitialValues = {
    first_name: bot.first_name || '',
    last_name: bot.last_name || '',
    bio: bot.bio || '',
    username: bot.username || '',
    avatar: bot.avatar || '',
  };

  const handleSubmit = (
    values: InitialValues,
    actions: FormikHelpers<InitialValues>,
  ) => {
    setIsFetching(true);
    const changedFields: Partial<InitialValues> = {};
    Object.keys(values).forEach((key) => {
      if (values[key] !== initialValues[key]) {
        changedFields[key] = values[key];
      }
    });
    longApi
      .patch(`/telegram/tgaccount/tg_settings/${bot.id}`, changedFields)
      .then((response) => {
        if (response.data.result === 'OK') {
          setModalShow(true);
        } else {
          setModalErrorShow(true);
        }
      })
      .catch((error) => {
        console.log(error);
        actions.resetForm();
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <main>
      <ModalCentered
        show={modalShow}
        headerText="УСПЕХ"
        bodyText="Данные успешно отправлены на сервер"
        btnText="Закрыть"
        onHide={() => setModalShow(false)}
      />
      <ModalCentered
        show={modalErrorShow}
        headerText="ОШИБКА"
        bodyText="Произошла ошибка. Повторите попытку"
        btnText="Закрыть"
        onHide={() => setModalErrorShow(false)}
      />
      <div className={styles.wrapper}>
        <Formik
          // validationSchema={adminBotCard}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            dirty,
            setFieldValue,
          }) => (
            <Form className={styles.settingsWrapper}>
              <div className={styles.avatarWrapper}>
                <div className={styles.avatar}>
                  <img
                    ref={avatarRef}
                    src={bot.avatar || DefaultAvatarPath}
                    alt="Bot Avatar"
                    className={styles.botAvatar}
                  />
                  <input
                    className={styles.avatarInput}
                    type="file"
                    name="avatar"
                    id="avatar"
                    accept="image/png, image/jpeg"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files?.[0] && avatarRef.current) {
                        const avatar = e.target.files[0];
                        const reader = new FileReader();
                        reader.readAsDataURL(avatar);
                        reader.onload = () => {
                          const extension = avatar.name.split('.').pop();
                          const base64Prefix = `data:image/${extension};base64,`;
                          const newBase64String =
                            reader.result?.toString().split(',')[1] || '';
                          setBase64String(base64Prefix + newBase64String);
                          setSelectedFile(avatar);
                          if (avatarRef.current) {
                            avatarRef.current.src = URL.createObjectURL(avatar);
                          }
                          setFieldValue(
                            'avatar',
                            base64Prefix + newBase64String,
                          );
                        };
                      }
                    }}
                  />
                  <label className={styles.avatarlabel} htmlFor="avatar">
                    <PhotoChangeIcon />
                  </label>
                </div>
                <span className={styles.pictureInfo}>JPEG, PNG до 10MB</span>
              </div>
              <div className={styles.settings}>
                <h3 className={styles.header}>Настройки</h3>
                <TextInput
                  className={styles.input}
                  name="first_name"
                  value={values.first_name}
                  errorMessage={
                    errors.first_name && touched.first_name
                      ? errors.first_name
                      : ''
                  }
                  placeholder="Имя"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextInput
                  className={styles.input}
                  name="last_name"
                  value={values.last_name}
                  errorMessage={
                    errors.last_name && touched.last_name
                      ? errors.last_name
                      : ''
                  }
                  placeholder="Фамилия"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextInput
                  className={styles.input}
                  name="bio"
                  value={values.bio}
                  errorMessage={errors.bio && touched.bio ? errors.bio : ''}
                  placeholder="О себе"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextInput
                  className={styles.input}
                  name="username"
                  value={values.username}
                  errorMessage={
                    errors.username && touched.username ? errors.username : ''
                  }
                  placeholder="Никнейм"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Btn_transparent
                  disabled={!dirty && !isFetching}
                  type="submit"
                  variant={'standart'}
                  className={styles.btn}
                >
                  {isFetching ? (
                    <div className={styles.loader}>
                      <Loader width={24} height={24} />
                    </div>
                  ) : (
                    'Сохранить'
                  )}
                </Btn_transparent>
              </div>
            </Form>
          )}
        </Formik>
        <div className={styles.statuses}>
          <div>
            <h4 className={`${styles.header} ${styles.statusHeader}`}>
              Work status
            </h4>
            <p className={styles.status}>{bot.work_status}</p>
          </div>
          <div>
            <h4 className={`${styles.header} ${styles.statusHeader}`}>
              Block status
            </h4>
            <p className={styles.status}>{bot.block_status}</p>
          </div>
          <div>
            <h4 className={`${styles.header} ${styles.statusHeader}`}>apiId</h4>
            <p className={styles.status}>{bot.api_id}</p>
          </div>
          <div>
            <h4 className={`${styles.header} ${styles.statusHeader}`}>
              apiHash
            </h4>
            <p className={styles.status}>{bot.api_hash}</p>
          </div>
          <div>
            <h4 className={`${styles.header} ${styles.statusHeader}`}>
              Телефон
            </h4>
            <p className={styles.status}>{bot.phone_number}</p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AdminBotCard;
