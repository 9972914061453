import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import LogoBig from '../../../assets/images/Logo_big.svg';
import LogoMobile from '../../../assets/images/icons/Logo_mobile.svg';
import { ReactComponent as MenuIcon } from '../../../assets/images/icons/vector_menuIcon.svg';
import styles from './header.module.sass';

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuIcon, setIsMenuIcon] = useState(false);
  const [isMenu, setIsMenu] = useState(true);
  const [isLogo, setIsLogo] = useState(true);
  const [btnLogIn, setBtnLogIn] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
      if (scrollTop > 50) {
        setIsMenuIcon(true);
        setIsMenu(false);
        setIsLogo(false);
        setBtnLogIn(true);
      } else if (windowWidth <= 900) {
        setIsMenuIcon(true);
        setIsMenu(false);
        setIsLogo(true);
        setBtnLogIn(true);
      } else {
        setIsMenuIcon(false);
        setIsMenu(true);
        setIsLogo(true);
        setBtnLogIn(true);
      }
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 900) {
      setIsMenu(false);
      setIsMenuIcon(true);
    } else {
      setIsMenu(true);
      setIsMenuIcon(false);
    }
  }, [windowWidth]);

  const openMenu = () => {
    if (windowWidth >= 500) {
      setIsMenu(!isMenu);
      setIsMenuIcon(false);
    } else {
      setIsMenu(!isMenu);
      setIsMenuIcon(true);
      setBtnLogIn(isMenu);
    }
  };

  return (
    <div
      className={`${styles.header__wrapper} ${
        isScrolled ? styles.scrolled : ''
      }`}
    >
      {isLogo && (
        <div className={styles.header__logo}>
          <Link to={Routes.Login}>
            <picture>
              <source
                media="(max-width: 500px)"
                srcSet={LogoMobile}
                width="100px"
              />
              <img src={LogoBig} alt="Лого" width="100%" />
            </picture>
          </Link>
        </div>
      )}
      <div className={styles.header__menu}>
        {isMenu && (
          <div className={styles.header__menu_wrapper}>
            <a href="#about_us" className={styles.header__menu_item}>
              О нас
            </a>
            <a href="#KeyBenefis" className={styles.header__menu_item}>
              Возможности
            </a>
            <a href="#Contact" className={styles.header__menu_item}>
              Контакты
            </a>
          </div>
        )}
        {isMenuIcon && (
          <div className={styles.header__menu_icon}>
            <MenuIcon onClick={openMenu} />
          </div>
        )}
        {btnLogIn && (
          <Link to={Routes.Login}>
            <button className={styles.header__menu_btn}>Войти</button>
          </Link>
        )}
      </div>
    </div>
  );
}
