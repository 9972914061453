import { useNavigate } from 'react-router-dom';
import styles from '../../ResetPasswordPage/reset-password.module.sass';
import Logo from '../../../components/ui/logo/Logo';

export default function ResetPasswordSuccessPage() {
  const navigate = useNavigate();

  return (
    <div className={styles.resetPassForm}>
      <Logo />
      <div className={styles.resetPassInputContainer}>
        <h2 className={styles.resetPassHeader}>Пароль успешно создан!</h2>
        <p style={{ textAlign: 'center' }}>
          Теперь Вы можете войти в свой аккаунт с использовнием нового пароля.
        </p>
        <button
          type="submit"
          className={styles.resetPassBtn}
          onClick={() => navigate('/login')}
        >
          Перейти на страницу входа
        </button>
      </div>
    </div>
  );
}
