import { useEffect } from 'react';
import styles from './mailing-table.module.sass';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchTasks } from '../../../store/task-slice/apiActions';
import CompletedTasks from '../../CompletedTasks/CompletedTasks';

const MailingTable = () => {
  const dispatch = useAppDispatch();
  const tasks = useAppSelector((state) => state.Task.tasks);
  const isLoadingTasks = useAppSelector((state) => state.Task.isLoading);

  const refreshTasks = () => {
    const limit = 10;
    const offset = 0;
    dispatch(fetchTasks({ limit, offset, operation: 'mailing' }));
  };

  useEffect(() => {
    refreshTasks();
  }, []);

  return (
    <section className={styles.pageWrapper}>
      <CompletedTasks
        tasks={tasks}
        isLoading={isLoadingTasks}
        refreshTasks={refreshTasks}
        style={{ width: '100%' }}
        operation="mailing"
      />
    </section>
  );
};
export default MailingTable;
