import React, { useEffect, useState } from 'react';
import { longApi } from '../../../services/longApi';
import { useAppSelector } from '../../../hooks/redux';
import { api } from '../../../services/api';
import styles from './wallet.module.sass';
import ProgressBar from 'react-bootstrap/ProgressBar';

export default function TariffLimits() {
  const [data, setData] = useState(0);
  const [limitsMessage, setLimitsMessage] = useState(0);

  useEffect(() => {
    longApi
      .get('/telegram/tasks/me?operation=parsing')
      .then((response) => {
        const filteredData = response.data.filter(
          (task: any) => task.work_status === 'in_processing',
        );
        setData(filteredData.length);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    longApi
      .get(`/tariff/${tariffId}`)
      .then((response) => {
        setLimitsMessage(response.data.options.count_of_messages);
      })
      .catch((error) => console.error(error));
  }, []);

  const tariffId: number | undefined = useAppSelector(
    (state) => state.UserData.user?.subscribe?.tariff_id,
  );

  const userSimultaneousParsing: number | undefined = useAppSelector(
    (state) =>
      state.UserData.user?.subscribe?.tariff_options.simultaneous_parsing,
  );

  let simultaneousParsingProgress = 0;
  let progreeSimulationLabel = 0;
  if (userSimultaneousParsing) {
    simultaneousParsingProgress = 100 - (100 / userSimultaneousParsing) * data;
    progreeSimulationLabel = userSimultaneousParsing - data;
  }

  const userMailingLimits: number | undefined = useAppSelector(
    (state) => state.UserData.user?.subscribe?.tariff_options.count_of_messages,
  );
  const mailedToday = useAppSelector((state) => state.Task.mailedToday);

  let mailingProgress = userMailingLimits;
  if (userMailingLimits && mailedToday) {
    mailingProgress = 100 - (mailedToday / userMailingLimits) * 100;
  }

  const userInvitingLimits: number | undefined = useAppSelector(
    (state) => state.UserData.user?.subscribe?.tariff_options.invitings_per_day,
  );
  const invitingToday = useAppSelector((state) => state.Task.invitingToday);

  let invitingProgress = userInvitingLimits;
  if (userInvitingLimits && invitingToday) {
    invitingProgress = 100 - (invitingToday / userInvitingLimits) * 100;
  }

  return (
    <div className={styles.tariffLimits}>
      <div className={styles.tariffLimits__simultParsing}>
        <span className={styles.tariffLimits__header}>
          Количество одновременных парсингов
        </span>
        <div className={styles.tariffLimits__progress}>
          <span className={styles.tariffLimits__progressText}>Парсинг</span>
          <div className={styles.tariffLimits__progressbar}>
            <ProgressBar
              variant="success"
              now={simultaneousParsingProgress}
              label={progreeSimulationLabel}
            />
          </div>
        </div>
      </div>
      <div className={styles.tariffLimits__simultParsing}>
        <span className={styles.tariffLimits__header}>Лимиты на сегодня</span>
        <div className={styles.tariffLimits__progress}>
          <span className={styles.tariffLimits__progressText}>Таргетинг</span>
          <div className={styles.tariffLimits__progressbar}>
            <ProgressBar
              variant="success"
              now={mailingProgress}
              label={userMailingLimits}
            />
          </div>
        </div>
        <div className={styles.tariffLimits__progress}>
          <span className={styles.tariffLimits__progressText}>Инвайтинг</span>
          <div className={styles.tariffLimits__progressbar}>
            <ProgressBar
              variant="success"
              now={invitingProgress}
              label={userInvitingLimits}
            />
          </div>
        </div>
      </div>
      {/* <div className={styles.tariffLimits__simultParsing}>
        <span className={styles.tariffLimits__header}>Лимиты на месяц</span>
        <div className={styles.tariffLimits__progress}>
          <span className={styles.tariffLimits__progressText}>Парсинг</span>
          <div className={styles.tariffLimits__progressbar}>
            <ProgressBar
              variant="success"
              now={simultaneousParsingProgress}
              label={progreeSimulationLabel}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}
