import { FC } from 'react';
import AreaChartParsers from '../../ui/chart/AreaChartParsers';
import AreaChartMailing from '../../ui/chart/AreaChartMailing';
import styles from './style.module.sass';

const QtyParsMailing: FC = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.block__top}>
          <div className={styles.parserChart}>
            <AreaChartParsers />
          </div>
          <div className={styles.parserChart}>
            <AreaChartMailing />
          </div>
        </div>
        {/* <div className={styles.block__bottom}>
          <div className={styles.parserChart}>
            <AreaChartMailing />
          </div>
          <div className={styles.parserChart}>
            <AreaChartMailing />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default QtyParsMailing;
