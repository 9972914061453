import { useState } from 'react';
import CreateBotModal from '../../../components/Admin/AdminBots/CreateBotModal/CreateBotModal';
import styles from './bots-admin-page.module.sass';
import Btn_transparent from '../../../components/ui/btn_transparent/Btn_transparent';
import Nav from 'react-bootstrap/Nav';
import { Routes } from '../../../router/routes';
import { NavLink, Outlet } from 'react-router-dom';

const BotsAdminPage = () => {
  const [isCreateFormActive, setIsCreateFormActive] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <h2 className={styles.title}>Боты</h2>
        <div className={styles.buttonsWrapper}>
          <Btn_transparent
            className={styles.createButton}
            onClick={() => setIsCreateFormActive(true)}
            variant="standart"
          >
            Создать нового бота
          </Btn_transparent>
        </div>
      </div>
      <Nav variant="tabs">
        <Nav.Item>
          <Nav.Link as={NavLink} to={Routes.AdminBots}>
            Статистика
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to={Routes.AdminBotsAll}>
            Все
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Outlet />
      <CreateBotModal
        isActive={isCreateFormActive}
        setIsActive={setIsCreateFormActive}
      />
    </div>
  );
};
export default BotsAdminPage;
