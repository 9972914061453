import React, { useState } from 'react';
import styles from './footer.module.sass';
import { ReactComponent as IconVK } from '../../../assets/images/icons/icons_VK.svg';
import { ReactComponent as IconTG } from '../../../assets/images/icons/icons_TG.svg';
import { ReactComponent as VectorSendMe } from '../../../assets/images/icons/vector_sendMe.svg';
import { ReactComponent as RectangleWhite } from '../../../assets/images/icons/rectangle_white.svg';

export default function Footer() {
  const [isHoveredVK, setIsHoveredVK] = useState(false);
  const [isHoveredTG, setIsHoveredTG] = useState(false);
  const [isHoveredTGCanal, setIsHoveredTGCanal] = useState(false);
  const [isHoveredText, setIsHoveredText] = useState(false);

  return (
    <div className={styles.wrapper} id="Contact">
      <div className={styles.footer__content}>
        <div className={styles.footer__social}>
          <div
            onMouseEnter={() => setIsHoveredVK(true)}
            onMouseLeave={() => setIsHoveredVK(false)}
          >
            <a
              href="https://vk.com/tgparsing"
              target="_blank"
              className={styles.footer__social_element}
            >
              {isHoveredVK ? <RectangleWhite /> : <IconVK />}
              <span>Группа в VK</span>
            </a>
          </div>
          <div
            onMouseEnter={() => setIsHoveredTG(true)}
            onMouseLeave={() => setIsHoveredTG(false)}
          >
            <a
              href="https://t.me/tgparsin_bot"
              target="_blank"
              className={styles.footer__social_element}
            >
              {isHoveredTG ? <RectangleWhite /> : <IconTG />}
              <span>Чат-бот в телеграм</span>
            </a>
          </div>
          <div
            onMouseEnter={() => setIsHoveredTGCanal(true)}
            onMouseLeave={() => setIsHoveredTGCanal(false)}
          >
            <a
              href="https://t.me/kanale12efk"
              target="_blank"
              className={styles.footer__social_element}
            >
              {isHoveredTGCanal ? <RectangleWhite /> : <IconTG />}
              <span>Телеграм канал</span>
            </a>
          </div>
        </div>
        <div
          className={styles.footer__text}
          onMouseEnter={() => setIsHoveredText(true)}
          onMouseLeave={() => setIsHoveredText(false)}
        >
          <span>Вы можете всегда </span>
          <a
            href="https://t.me/tgscrapping"
            target="_blank"
            className={styles.footer__text_link}
          >
            {isHoveredText ? (
              <>
                <span> написать нам </span>
                <VectorSendMe />{' '}
              </>
            ) : (
              <>
                <VectorSendMe />
                <span> написать нам </span>
              </>
            )}
          </a>
          <span>если у Вас есть вопросы</span>
        </div>
      </div>
    </div>
  );
}
