import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form, Formik, FormikHelpers, FormikErrors } from 'formik';
import { Roles } from '../../../consts/consts';
import { useAppSelector } from '../../../hooks/redux';
import { api } from '../../../services/api';
import { geolocationValidation } from './geolocation-validation-schema';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';
import ModalCentered from '../../ui/modal-window/ModalCentered';
import { useAppDispatch } from '../../../hooks/redux';
import { fetchTasks } from '../../../store/task-slice/apiActions';
import Map from '../../Map/Map';
import DraggableCircle from '../../Map/DraggableCircle';
import SearchControl from '../../Map/SearchControl';

import RoundRadio from '../../ui/roundRadio/RoundRadio';
import TextInput from '../../ui/input/TextInput';
import InfoTooltip from '../../ui/infoTooltip/infoTooltip';
import MailingYoutubePlayer from '../../../pages/Mailing/MailingYoutubePlayer/MailingYoutubePlayer';
// import Button from '../../ui/button/Button';

import styles from './geolocation.module.sass';
import sharedStyles from '../index.module.sass';

const RADIUSES = [500, 1000, 2000, 3000, 5000] as const;

type FormValues = {
  radius: (typeof RADIUSES)[number];
  name: string;
  marker: [number, number] | null;
  id_bot: number | null;
};

const Geolocation = () => {
  const isDisabled = useOutletContext<boolean>();
  const [isFetching, setIsFetching] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState({ name: '', parsing: '', daily: '' });

  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.UserData.user?.role.name);
  const currentTariff = useAppSelector((state) => state.Tariff.currentTariff);

  const refreshTasks = () => {
    const limit = 10;
    const offset = 0;
    dispatch(fetchTasks({ limit, offset, operation: 'parsing' }));
  };

  useEffect(() => {
    refreshTasks();
  }, []);

  const initialValues: FormValues = {
    radius: RADIUSES[0],
    name: '',
    marker: null,
    id_bot: null,
  };

  const validateStepOne = (
    values: FormValues,
    errors: FormikErrors<FormValues>,
  ) => {
    return values.name && !errors.name;
  };

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsFetching(true);
    api
      .post('/telegram/parser/geomembers', {
        task_name: values.name.replaceAll(' ', ''),
        export_file_ext: 'xlsx',
        coordinates: [
          {
            latitude: values.marker?.[0],
            longitude: values.marker?.[1],
          },
        ],
        accuracy_radius: values.radius,
        id_bot: values.id_bot,
        //TODO
        rerun: false,
      })
      .then(() => {
        actions.resetForm();
        setModalShow(true);
        setError({ name: '', parsing: '', daily: '' });
        refreshTasks();
      })
      .catch((e) => {
        console.error(e);
        if (
          e.response.data.detail === 'Задание с таким именем уже существует'
        ) {
          setError({ ...error, name: e.response.data.detail });
        } else if (
          e.response.data.detail === 'Превышен лимит одновременных парсеров'
        ) {
          setError({ ...error, parsing: e.response.data.detail });
        } else if (
          e.response.data.detail === 'Превышен лимит дневных парсеров'
        ) {
          setError({ ...error, daily: e.response.data.detail });
        }
      })
      .finally(() => setIsFetching(false));
  };

  return (
    <section>
      <ModalCentered
        show={modalShow}
        headerText="Парсинг запущен"
        bodyText='Результаты парсинга отобразятся в таблице "История задач"'
        btnText="Закрыть"
        onHide={() => setModalShow(false)}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={geolocationValidation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form className={sharedStyles.form}>
            <a
              className={sharedStyles.instruction__link}
              href={require('../../../assets/pdfs/parsing-geo.pdf')}
              target="_blank"
            >
              Инструкция - Как собрать аудиторию в ТГ при помощи парсера
              "Геолокация"
            </a>
            <MailingYoutubePlayer videoID="ptjgU5u1cWU?si=epeQRXHCdPXaqEVc" />
            <div className={sharedStyles.fieldWrapper}>
              {/* <h3 className={sharedStyles.header}>Геоточка</h3> */}
              <div className={styles.mapWrapper}>
                <Map
                  width="100%"
                  height="344px"
                  center={[55.7504461, 37.6174943]}
                  zoom={12}
                >
                  {
                    <>
                      <SearchControl
                        setMarker={(coordinates) =>
                          setFieldValue('marker', coordinates)
                        }
                      />
                      {values.marker && (
                        <DraggableCircle
                          radius={values.radius}
                          center={values.marker}
                          setMarker={(coordinates) =>
                            setFieldValue('marker', coordinates)
                          }
                        />
                      )}
                    </>
                  }
                </Map>
              </div>
              <div className={sharedStyles.errorMessage}>
                {errors.marker && touched.marker ? errors.marker : ''}
              </div>
            </div>
            <div
              className={`${sharedStyles.fieldValidationBorder} ${
                styles.fieldWrapper
              } ${
                validateStepOne(values, errors) ? sharedStyles.success : ''
              } ${errors.name ? sharedStyles.errors : ''}`}
            >
              <div className={sharedStyles.fieldWrapper}>
                <h3 className={sharedStyles.header}>Название задачи</h3>
                <InfoTooltip text="Дайте название своему парсингу (задаче). Это название будет видно только Вам в таблице с историями по парсингу." />
                <TextInput
                  style={{ maxWidth: '610px' }}
                  name="name"
                  type="text"
                  placeholder="Придумайте название задачи"
                  hintMessage="Название будет видно только Вам"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    error.name ||
                    (errors.name && touched.name ? errors.name : '')
                  }
                />
                {role === Roles.Admin || role === Roles.SuperUser ? (
                  <TextInput
                    style={{ maxWidth: '610px' }}
                    name="id_bot"
                    type="text"
                    placeholder="Введите id бота"
                    value={values.id_bot || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : null}
              </div>
            </div>
            <div
              className={`${sharedStyles.fieldValidationBorder} ${
                validateStepOne(values, errors) ? sharedStyles.success : ''
              }`}
            >
              <div className={sharedStyles.fieldWrapper}>
                <h3 className={sharedStyles.header}>
                  Радиус действия геоточки (метры)
                </h3>
                <InfoTooltip text="Укажите радиус осуществления парсинга, относительно выбранной точки (500м / 1000м / 2000м / 3000м / 4000м / 5000м)" />
                <div className={styles.radiusSelect}>
                  {RADIUSES.map((radius) => (
                    <RoundRadio
                      key={radius}
                      title={String(radius)}
                      name="radius"
                      value={String(radius)}
                      currentValue={String(values.radius)}
                      radioHandler={handleChange}
                    />
                  ))}
                </div>
              </div>
            </div>
            {error.parsing && (
              <p
                className={sharedStyles.errorMessage}
                style={{ margin: '0 auto 16px', textAlign: 'center' }}
              >
                {`${error.parsing}. По Вашему тарифу их количество - ${currentTariff.options.simultaneous_parsing}. Подождите окончания одной из задач.`}
              </p>
            )}
            {error.daily && (
              <p
                className={sharedStyles.errorMessage}
                style={{ margin: '0 auto 16px', textAlign: 'center' }}
              >
                {`${error.daily}. По Вашему тарифу их количество - ${currentTariff.options.parsers_per_day}.`}
              </p>
            )}
            <Btn_transparent
              style={{ maxWidth: '610px' }}
              variant={'standart'}
              type="submit"
              disabled={isFetching || isDisabled}
            >
              {isDisabled
                ? 'Недоступно для вашего тарифа'
                : 'Начать сбор аудитории'}
            </Btn_transparent>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default Geolocation;
