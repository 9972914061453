import ModalWindow from '../ui/modal-window/ModalWindow';

import FileExamplePath from '../../assets/images/mailing-file-example.jpg';

import styles from './file-example-window.module.sass';

type FileExampleWindowProps = {
  isActive: boolean;
  setActive: (modalIsActive: boolean) => void;
};

const tooltips = [
  'Заполнить название столбцов, неважно как они будут называться.',
  'В 4 столбце расположить аккаунты Telegram.',
  'В остальных столбцах не важно какие будут данные. Можно их заполнять, можно оставить пустыми.',
  'ОБЯЗАТЕЛЬНО!!! Файл должен быть в формате .csv, разделители должны быть - запятые.',
];

const FileExampleWindow = ({ isActive, setActive }: FileExampleWindowProps) => {
  return (
    <ModalWindow
      clickByOut={false}
      isActive={isActive}
      setActive={setActive}
      style={{ margin: '25vh auto 0' }}
      buttonStyle={{ top: '-40px', right: '-40px' }}
    >
      <div className={styles.fileExampleWrapper}>
        <ul>
          <li className={styles.tooltipTopWrapper} key={5}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 17 23"
              fill="none"
              className={`${styles.tooltipArrow} ${styles.bottomTooltipArrow}`}
            >
              <path
                d="M1.88617 14.4772C0.0366885 13.0767 0.0366852 10.2971 1.88617 8.89663L10.8067 2.14179C13.1119 0.396187 16.4195 2.04048 16.4195 4.93208L16.4195 18.4418C16.4195 21.3334 13.1119 22.9777 10.8067 21.2321L1.88617 14.4772Z"
                fill="#8586A5"
                stroke="#8586A5"
              />
            </svg>
            <div
              className={`${styles.tooltipTip} ${styles.rightTooltipTip} ${styles.bigTooltipTip}`}
            >
              Файл должен быть в формате .csv
            </div>
          </li>
          {tooltips.map((tooltip, i) => (
            <li className={styles.tooltipWrapper} key={i}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 17 23"
                fill="none"
                className={`${styles.tooltipArrow} ${styles.rightTooltipArrow}`}
              >
                <path
                  d="M1.88617 14.4772C0.0366885 13.0767 0.0366852 10.2971 1.88617 8.89663L10.8067 2.14179C13.1119 0.396187 16.4195 2.04048 16.4195 4.93208L16.4195 18.4418C16.4195 21.3334 13.1119 22.9777 10.8067 21.2321L1.88617 14.4772Z"
                  fill="#8586A5"
                  stroke="#8586A5"
                />
              </svg>
              <div
                className={`${styles.tooltipTip} ${styles.rightTooltipTip} ${styles.bigTooltipTip}`}
              >
                {tooltip}
              </div>
            </li>
          ))}
        </ul>
        <img src={FileExamplePath} />
      </div>
    </ModalWindow>
  );
};

export default FileExampleWindow;
