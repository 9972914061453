import { FC, useState, useEffect } from 'react';
import styles from './mailingForm.module.sass';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import { Mailing } from '../../../types/mailing-form';
import { ParserTask } from '../../../types/parserTask';
import { fetchTasks } from '../../../store/task-slice/apiActions';
import FileExampleWindow from '../../../components/FileExampleWindow/FileExampleWindow';
import InfoTooltip from '../../../components/ui/infoTooltip/infoTooltip';
import TextInput from '../../../components/ui/input/TextInput';
import { api } from '../../../services/api';

interface MailingFormTypes {
  formData: Mailing;
  setFormData: (formValue: Mailing) => void;
}

// TODO: отрефакторить удаление выбранного файла в контактах и фото, решить проблему с кликом выбора файла при клике на крестик
const MailingForm: FC<MailingFormTypes> = ({ formData, setFormData }) => {
  const [file, setFile] = useState();
  const [fileText, setFileText] = useState('');
  const [isReplenishmentOpen, setIsReplenishmentOpen] = useState(false);
  const [contact1Name, setContact1Name] = useState('');
  const [contact2Name, setContact2Name] = useState('');
  const [errors, setErrors] = useState({
    users_filename: '',
    task_name: '',
    mailing_name: '',
    text: '',
  });

  const dispatch = useAppDispatch();
  const tasks: ParserTask[] | null = useAppSelector(
    (state) => state.Task.tasks,
  );

  const mailingTasks: ParserTask[] | null = tasks.filter(
    (item) => item.operation === 'mailing',
  );
  const parsingTasks: ParserTask[] | null = tasks.filter(
    (item) => item.operation === 'parsing' && item.work_status === 'success',
  );

  const fileHandlerOnChange = async (evt: any) => {
    evt.preventDefault();
    const file = await evt.target.files[0];
    setFile(file);

    function sendFile() {
      const formDataFile = new FormData();
      formDataFile.append('file', file);
      api
        .post('/telegram/parser/tgmailing/upload_file', formDataFile)
        .then((response) => {
          console.log(response);
          setErrors({ ...errors, users_filename: '' });
        })
        .catch((error) => {
          console.error(error);
          setErrors({ ...errors, users_filename: error.message });
        });
    }

    if (file) {
      const fileText = await evt.target.files[0].name;
      setFileText(fileText);
      setFormData({
        ...formData,
        users_filename: fileText,
      });
      sendFile();
    }
  };

  const validateUniqueName = (name: string) => {
    mailingTasks.forEach((task) => {
      if (name === task.title) {
        setErrors({
          ...errors,
          task_name: 'Название таргетинга должно быть уникальным',
        });
      }
    });
  };

  const validateStepOne = () => {
    return formData.task_name.length > 0 && !errors.task_name;
  };

  const validateStepTwo = () => {
    return formData.group.length > 0 && !errors.mailing_name;
  };

  const validateStepThree = () => {
    return (
      (formData.users_filename.length > 0 && !errors.users_filename) ||
      formData.users[0].length > 0
    );
  };

  const validateStepFour = () => {
    return formData.text.length > 0;
  };

  useEffect(() => {
    const limit = 20;
    const offset = 0;
    dispatch(fetchTasks({ limit, offset, operation: 'parsing' }));
  }, []);

  return (
    <>
      <FileExampleWindow
        isActive={isReplenishmentOpen}
        setActive={setIsReplenishmentOpen}
      />
      <div
        className={`${styles.mailingChannelWrapper} ${
          styles.fieldValidationBorder
        } ${validateStepOne() ? styles.success : ''} ${
          errors.task_name ? styles.errors : ''
        }`}
      >
        <div className={styles.formGroup}>
          <div className={styles.mailingChannelWrapperStep}>
            <div className={styles.wrapperMailingName}>
              <InfoTooltip text="Введите название Таргетинга. Это название будет видно только вам в истории задач." />
              <h3 className={styles.label}>Таргетинг</h3>
              <div className={styles.mailingNameInput}>
                <TextInput
                  name="task-name"
                  type="text"
                  value={formData.task_name}
                  placeholder="Название таргетинга"
                  onChange={(evt) => {
                    setFormData({
                      ...formData,
                      task_name: evt.target.value,
                    });
                    setErrors({ ...errors, task_name: '' });
                    if (!evt.target.value) {
                      setErrors({ ...errors, task_name: 'Обязательное поле' });
                    }
                    validateUniqueName(evt.target.value);
                  }}
                  errorMessage={(() => {
                    if (errors.task_name) return errors.task_name;
                  })()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.mailingChannelWrapper} ${
          styles.fieldValidationBorder
        } ${validateStepOne() && validateStepTwo() ? styles.success : ''} ${
          errors.mailing_name ? styles.errors : ''
        }`}
      >
        <div className={styles.formGroup}>
          <div className={styles.mailingChannelWrapperStep}>
            <div className={styles.wrapperMailingName}>
              <InfoTooltip text="Введите название группы, которое будут видеть пользователи при получении сообщения" />
              <h3 className={styles.label}>Название группы</h3>
              <div className={styles.mailingNameInput}>
                <TextInput
                  required
                  id="mailing-name"
                  type="text"
                  placeholder="Название группы"
                  value={formData.group}
                  onChange={(evt) => {
                    setFormData({ ...formData, group: evt.target.value });
                    setErrors({ ...errors, mailing_name: '' });
                    if (!evt.target.value) {
                      setErrors({
                        ...errors,
                        mailing_name: 'Обязательное поле',
                      });
                    }
                  }}
                  errorMessage={(() => {
                    if (errors.mailing_name) return errors.mailing_name;
                  })()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.mailingChannelWrapper} ${
          styles.fieldValidationBorder
        } ${
          validateStepOne() && validateStepTwo() && validateStepThree()
            ? styles.success
            : ''
        } ${
          validateStepOne() && validateStepTwo() && errors.users_filename
            ? styles.errors
            : ''
        }`}
      >
        <div className={`${styles.formGroup} ${styles.formGroupContacts}`}>
          <div
            className={`${styles.mailingChannelWrapperStep} ${styles.mailingChannelWrapperStepContacts}`}
          >
            <div className={styles.wrapperMailingName}>
              {/* <div className={styles.wrapperMailingName}> */}
              <InfoTooltip
                text={`Введите название файла из ранее спарсенных файлов или загрузите файл с компьютера в формате .csv.
                    Для Telegram: в файле в 4м столбце должны быть никнеймы пользователя в формате nickname.
                    Первая строка файла не учитывается. но она должна быть подписана. Остальная заполненность файла не важна.`}
              />
              <h3 className={`${styles.label} ${styles.contactsLabel}`}>
                Контакты для таргетинга
              </h3>
              <span
                className={`${styles.checkMailinMethod} ${styles.checkMailinMethodContacts}`}
              >
                Выберите один из способов для загрузки контактов:
              </span>
              <div className={styles.mailingNameInput}>
                <TextInput
                  type="text"
                  style={{ cursor: 'unset', marginBottom: '4px' }}
                  placeholder="Название файла"
                  value={contact1Name}
                  maxLength={950}
                  onChange={(evt) => {
                    setContact1Name(evt.target.value);
                    setFormData({
                      ...formData,
                      users_filename: evt.target.value,
                    });
                  }}
                  disabled={contact2Name.length > 0 || file}
                />
                <span className={styles.checkMailinMethod}>
                  Выберите файл из таблицы справа
                </span>
              </div>
              <div className={styles.mailingNameInput}>
                <TextInput
                  type="text"
                  style={{ marginBottom: '4px' }}
                  placeholder="Вставьте контакты"
                  value={formData.users}
                  onChange={(evt) => {
                    const inputValue = evt.target.value.replace(/\s/g, '');
                    setContact2Name(evt.target.value);
                    setFormData({
                      ...formData,
                      users: inputValue.trim().split(',') || [''],
                    });
                  }}
                  disabled={contact1Name.length > 0 || file}
                />
                <span className={styles.checkMailinMethod}>
                  Введите юзернеймы для таргетинга (через запятую без пробела):
                  user,user1,user2
                </span>
              </div>
              <div
                className={`${
                  contact1Name.length > 0 || contact2Name.length > 0
                    ? styles.uploadedFileInputDisabled
                    : ''
                } ${styles.uploadedFileInput}`}
              >
                <br />
                <input
                  type="file"
                  id="uploaded-file"
                  onChange={fileHandlerOnChange}
                  disabled={contact1Name.length > 0 || contact2Name.length > 0}
                />
                <label htmlFor="uploaded-file">
                  <p>{fileText ? fileText : 'Выберите файл'}</p>
                  {/* {file.name.length > 0 && (
                    <svg
                      className={styles.closeIcon}
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                      onClick={(e: any) => {
                        e.preventDefault();
                        setFile({ name: '' });
                        setFormData({ ...formData, users_filename: '' });
                      }}
                    > */}
                  {/* <line
                        y1="-0.5"
                        x2="15.1589"
                        y2="-0.5"
                        transform="matrix(0.695284 -0.718735 0.695284 0.718735 1.45996 12)"
                        stroke="currentColor"
                      />
                      <line
                        y1="-0.5"
                        x2="15.1589"
                        y2="-0.5"
                        transform="matrix(0.695284 0.718735 -0.695284 0.718735 1 1)"
                        stroke="currentColor"
                      />
                    </svg>
                  )} */}
                </label>
                <div style={{ marginTop: '2px' }}>
                  <span className={styles.fileExample}>
                    <p
                      onClick={() => setIsReplenishmentOpen(true)}
                      style={{ textDecoration: 'underline' }}
                    >
                      Пример файла
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.contactsTable}>
              <ul className={styles.contactTableList}>
                {parsingTasks.map((task, i) => (
                  <li
                    key={i}
                    className={styles.contactTableItem}
                    onClick={() => {
                      setContact1Name(task.settings.title);
                      setFormData({
                        ...formData,
                        users_filename: task.file_name,
                      });
                    }}
                    style={{ position: 'relative' }}
                  >
                    <p className={styles.contactsTableItemText}>
                      {task.settings.title}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.mailingChannelWrapper} ${
          styles.fieldValidationBorder
        } ${
          validateStepOne() &&
          validateStepTwo() &&
          validateStepThree() &&
          validateStepFour()
            ? styles.success
            : ''
        } ${errors.text ? styles.errors : ''}`}
      >
        <div className={`${styles.formGroup} ${styles.formGroupTextarea}`}>
          <div className={styles.mailingChannelWrapperStep}>
            <div className={styles.wrapperMailingName}>
              <InfoTooltip text="Введите текст сообщения. Максимальное количество символов: 950" />
              <h3 className={styles.labelText}>Текст сообщения</h3>
              <div className={styles.mailingNameInputText}>
                <div style={{ position: 'relative', width: '100%' }}>
                  <textarea
                    className={`${styles.textInputTextarea} ${
                      errors.text ? styles.errors : ''
                    }`}
                    placeholder="Текст сообщения"
                    value={formData.text}
                    maxLength={950}
                    onChange={(evt) => {
                      setFormData({ ...formData, text: evt.target.value });
                      setErrors({ ...errors, text: '' });
                      if (!evt.target.value) {
                        setErrors({
                          ...errors,
                          text: 'Обязательное поле',
                        });
                      }
                    }}
                    required
                  />
                  {errors.text && (
                    <span className={styles.errorMessage}>{errors.text}</span>
                  )}
                </div>
                <span className={styles.subText}>
                  {formData.text.length}/950
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailingForm;
