import { FC, useState, useEffect, CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';
import { ParserTask } from '../../types/parserTask';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { Routes } from '../../router/routes';
import { fetchTasks } from '../../store/task-slice/apiActions';
import { fetchDeletedTasks } from '../../store/task-slice/apiActions';
import Spinner from 'react-bootstrap/Spinner';
import Tabs from '../ui/tabs/tabs';
import { ToolTabs } from './tabs';
import TaskItem from '../ui/toolItem/taskItem';
import styles from './completed-tasks.module.sass';
import React from 'react';

interface CompletedTasksProps {
  tasks: ParserTask[];
  isLoading: boolean;
  refreshTasks: () => void;
  style?: CSSProperties;
  operation: 'parsing' | 'mailing';
}

const CompletedTasks: FC<CompletedTasksProps> = ({
  isLoading,
  refreshTasks,
  style,
  tasks,
  operation,
}) => {
  const [currentCategory, setCurrentCategory] = useState(ToolTabs[0].value);
  const [favoriteTasks, setFavoriteTasks] = useState<ParserTask[]>([]);
  const [reverseTasks, setReverseTasks] = useState<ParserTask[]>([]);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const deletedTasks: ParserTask[] = useAppSelector(
    (state) => state.Task.deletedTasks,
  );

  const dispatch = useAppDispatch();
  const location = useLocation();

  const fetchingTasks = (newLimit: number) => {
    const offset = 0;
    dispatch(fetchTasks({ offset, limit: newLimit, operation }));
  };

  useEffect(() => {
    fetchingTasks(limit);
  }, [limit, operation]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.currentTarget;
    const containerHeight = container.clientHeight;
    const contentHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;

    if (scrollTop + containerHeight >= contentHeight - 20) {
      setLimit((prevLimit) => prevLimit + 5);
    }
  };

  const deletedIds = deletedTasks.map((task) => task.id);

  const getFavoriteTasks = async () => {
    const favoriteTaskData =
      tasks?.filter((task) => {
        return task.favorite === true;
      }) || [];
    setFavoriteTasks(
      favoriteTaskData.filter(
        (task) => !deletedIds.includes(task.id),
      ) as unknown as ParserTask[],
    );
  };

  const getDeletedTasks = () => {
    dispatch(fetchDeletedTasks());
  };

  useEffect(() => {
    if (tasks.length > 0) {
      getFavoriteTasks();
      const arr = [...tasks].filter((task) => !deletedIds.includes(task.id));
      setReverseTasks(arr);
    }
  }, [tasks, deletedTasks]);

  useEffect(() => {
    getFavoriteTasks();
    getDeletedTasks();
  }, [currentCategory]);

  if (tasks === null) return <div>Не удалось получить данные</div>;

  const filteredTasks =
    currentCategory === ToolTabs[0].value
      ? reverseTasks
      : currentCategory === ToolTabs[1].value
      ? favoriteTasks
      : deletedTasks;

  const filteredItems = filteredTasks
    .filter((task) => task.title.toLowerCase().includes(search.toLowerCase()))
    .map((task) => (
      <li key={task.id}>
        <TaskItem
          task={task}
          isDeleted={deletedIds.includes(task.id)}
          operation={operation}
        />
      </li>
    ));

  return (
    <section className={styles.tasks} style={style}>
      <h2 className={styles.title}>История задач</h2>
      <div
        className={styles.tasksWrapper}
        onMouseEnter={() => (document.body.style.overflow = 'hidden')}
        onMouseLeave={() => (document.body.style.overflow = 'auto')}
      >
        <div className={styles.toolsTabs}>
          <Tabs
            tabs={ToolTabs}
            currentValue={currentCategory}
            setCurrentValue={(value: string) => setCurrentCategory(value)}
          />
          {isLoading && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <div className={styles.helpingBlock}>
            <form>
              <input
                className={styles.search}
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
            </form>
            <button className={styles.refresh} onClick={() => refreshTasks()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 14 14"
                fill="none"
                className={styles.refreshIcon}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.16677 0.244602C6.5434 -0.129194 8.00136 -0.0734838 9.34545 0.404273C10.6895 0.88203 11.8556 1.75903 12.6875 2.91784V1.53091C12.6875 1.35685 12.7566 1.18993 12.8797 1.06685C13.0028 0.943773 13.1697 0.87463 13.3437 0.87463C13.5178 0.87463 13.6847 0.943773 13.8078 1.06685C13.9309 1.18993 14 1.35685 14 1.53091V5.24982H10.2812C10.1072 5.24982 9.94024 5.18068 9.81716 5.0576C9.69409 4.93453 9.62495 4.7676 9.62495 4.59354C9.62495 4.41949 9.69409 4.25256 9.81716 4.12948C9.94024 4.00641 10.1072 3.93726 10.2812 3.93726H11.7923C11.1069 2.86489 10.0817 2.0534 8.88055 1.63262C7.67944 1.21183 6.37191 1.20605 5.16714 1.61622C3.96236 2.02639 2.92996 2.82879 2.2351 3.89507C1.54024 4.96134 1.22306 6.22987 1.33432 7.49772C1.44557 8.76556 1.97883 9.95946 2.84878 10.8884C3.71873 11.8174 4.8751 12.4277 6.13289 12.6217C7.39069 12.8158 8.67722 12.5824 9.78669 11.9588C10.8961 11.3353 11.7644 10.3576 12.2526 9.18224C12.2843 9.10076 12.3319 9.02642 12.3928 8.96361C12.4536 8.90079 12.5263 8.85077 12.6068 8.81646C12.6872 8.78216 12.7736 8.76427 12.8611 8.76384C12.9485 8.76342 13.0351 8.78047 13.1159 8.814C13.1966 8.84752 13.2698 8.89684 13.3313 8.95906C13.3927 9.02129 13.4411 9.09515 13.4735 9.17633C13.506 9.25751 13.5219 9.34435 13.5204 9.43177C13.5188 9.51919 13.4998 9.60542 13.4645 9.68539C13.0084 10.7832 12.2804 11.7467 11.3489 12.4852C10.4174 13.2237 9.31324 13.7128 8.14042 13.9065C6.96761 14.1001 5.7648 13.9919 4.64538 13.5921C3.52595 13.1922 2.52679 12.5138 1.74208 11.6209C0.957364 10.728 0.412945 9.65001 0.16014 8.48848C-0.0926644 7.32694 -0.0455266 6.12015 0.29711 4.98188C0.639747 3.8436 1.2666 2.81133 2.11857 1.98236C2.97054 1.1534 4.01957 0.555929 5.16677 0.244602Z"
                  fill="currentColor"
                />
              </svg>
              <p className={styles.refreshText}>Обновить</p>
            </button>
          </div>
        </div>
        <div className={styles.toolsItems} onScroll={handleScroll}>
          {tasks.length > 0 ? (
            <ul className={styles.toolsList}>{filteredItems}</ul>
          ) : (
            <p className={styles.tasksNoData}>{`Данные появятся после ${
              location.pathname.includes(Routes.Parsers)
                ? 'первого парсинга'
                : 'первой рассылки'
            }`}</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default CompletedTasks;
