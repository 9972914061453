import React from 'react';
import ToTop from '../../components/ui/toTop/ToTop';
import styles from './start-page.module.sass';
import Header from '../../components/Start/Header/Header';
import StartInfo from '../../components/Start/StartInfo/StartInfo';
import AboutUs from '../../components/Start/AboutUs/AboutUs';
import KeyBenefis from '../../components/Start/KeyBenefis/KeyBenefis';
import Examples from '../../components/Start/Examples/Examples';
import Review from '../../components/Start/Review/Review';
import Registration from '../../components/Start/Registration/Registration';
import Footer from '../../components/Start/Footer/Footer';
import Advertising from '../../components/Start/Advertising/Advertising';
import Partners from '../../components/Start/Partners/Partners';

const StartPage = () => {
  return (
    <div>
      <div className={styles.wrapper_mobile}>
        <Header />
      </div>
      <div className={styles.wrapper}>
        <Advertising />
      </div>
      <div className={styles.wrapper_mobile}>
        <StartInfo />
      </div>
      <div className={styles.wrapper}>
        <AboutUs />
      </div>
      <div className={styles.wrapper_mobile}>
        <KeyBenefis />
      </div>
      <div className={styles.wrapper}>
        <Examples />
        <Review />
      </div>
      <div className={styles.registartion__wrap}>
        <Registration />
        <Partners />
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
      <ToTop />
    </div>
  );
};
export default StartPage;
