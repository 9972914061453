import { useEffect, useState } from 'react';
import ModalWindow from '../ui/modal-window/ModalWindow';
import styles from './tariff-info.module.sass';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../router/routes';
// import Toggle from '../ui/toggle/toggle';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { TariffResponse } from '../../types/tariff';
import { api } from '../../services/api';
// import { PARSER_ENDINGS, getWordEnding } from '../../utils/getWordEnding';
// import { editUser } from '../../store/user-slice/userSlice';
import { ParsersTitles } from '../../consts/tariffs';
import Btn_transparent from '../ui/btn_transparent/Btn_transparent';

const TariffInfo = () => {
  const dispatch = useAppDispatch();
  const currentPath = useLocation().pathname;
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.UserData.user);
  const userSubscribe = user?.subscribe;

  const [tariffDetailIsOpened, setTariffDetailIsOpened] = useState(false);
  const [currentTariff, setCurrentTariff] = useState<TariffResponse | null>(
    null,
  );
  const [error, setError] = useState('');

  useEffect(() => {
    if (userSubscribe) {
      api
        .get(`/tariff/${userSubscribe.tariff_id}`)
        .then(({ data }) => setCurrentTariff(data))
        .catch((err) => console.error(err));
    }
  }, [userSubscribe]);

  if (!userSubscribe)
    return (
      <p className={styles.tariffsDirection}>
        {currentPath === Routes.Tariffs ? (
          'Для начала работы выберите тариф'
        ) : (
          <Link className={styles.tariffsDirection__link} to={Routes.Tariffs}>
            Для начала работы выберите тариф
          </Link>
        )}
      </p>
    );

  if (!currentTariff) return null;

  // const autoRenewalHandler = () => {
  //   api
  //     .post('/user/subscribe/toggle')
  //     .then(({ data }) => {
  //       dispatch(editUser({ ...user, subscribe: data }));
  //       setError('');
  //     })
  //     .catch(() => setError('Произошла ошибка'));
  // };

  return (
    <div>
      {/* <p className={styles.info}>
        Вам доступно сегодня:{' '}
        <span className={styles.infoAccent}>
          {userSubscribe.tariff_options.parsers_per_day}
        </span>{' '}
        {getWordEnding(
          userSubscribe.tariff_options.parsers_per_day,
          PARSER_ENDINGS,
        )}
      </p> */}
      <button
        className={styles.infoButton}
        onClick={() => setTariffDetailIsOpened(true)}
      >
        О тарифе
      </button>
      <ModalWindow
        isActive={tariffDetailIsOpened}
        setActive={setTariffDetailIsOpened}
      >
        <div className={styles.tariffDetails}>
          <h3 className={styles.tariffTitle}>Текущее состояние тарифа</h3>
          <div className={styles.tariffCharacteristics}>
            <ul className={styles.tariffColumn}>
              <li className={styles.tariffField}>Название тарифа:</li>
              <li className={styles.tariffField}>Действует до:</li>
              <li className={styles.tariffField}>Предусмотрено в день:</li>
              <li className={styles.tariffField}>Использовано сегодня:</li>
              <li className={styles.tariffField}>Доступно сегодня:</li>
              <li className={styles.tariffField}>Лимит сообщений в день:</li>
              <li className={styles.tariffField}>Способы сбора:</li>
            </ul>
            <ul className={styles.tariffColumn}>
              <li className={styles.tariffValue}>{currentTariff.name}</li>
              <li className={styles.tariffValue}>
                {new Date(userSubscribe.end_date).toLocaleDateString('ru-Ru')}
              </li>
              <li className={styles.tariffValue}>
                {currentTariff.options.parsers_per_day}
              </li>
              <li className={styles.tariffValue}>
                {currentTariff.options.parsers_per_day -
                  userSubscribe.tariff_options.parsers_per_day}
              </li>
              <li className={styles.tariffValue}>
                {userSubscribe.tariff_options.parsers_per_day}
              </li>
              <li className={styles.tariffValue}>
                {currentTariff.options.count_of_messages}
              </li>
              <li className={styles.tariffValue}>
                {currentTariff.options.activity && (
                  <>
                    - {ParsersTitles.activemembers[1]}
                    <br />
                  </>
                )}
                {currentTariff.options.geo && (
                  <>
                    - {ParsersTitles.geomembers[1]}
                    <br />
                  </>
                )}
                {currentTariff.options.members && (
                  <>- {ParsersTitles.members[1]}</>
                )}
              </li>
            </ul>
          </div>
          {/*Автопродление тарифа*/}
          {/* <div className={styles.autoRenewal}>
            Автоматическое продление тарифа
            <Toggle
              checked={userSubscribe.auto_debit}
              toggleHandler={autoRenewalHandler}
            />
          </div> */}
          {error && <p className={styles.error}>{error}</p>}
          <Btn_transparent
            variant="big"
            type="submit"
            onClick={() => {
              currentPath === Routes.Tariffs
                ? setTariffDetailIsOpened(false)
                : navigate(Routes.Tariffs);
            }}
          >
            Выбрать и оплатить тариф
          </Btn_transparent>
        </div>
      </ModalWindow>
    </div>
  );
};
export default TariffInfo;
