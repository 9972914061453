import React, { useEffect, useState } from 'react';
import { api } from '../../../services/api';
import styles from './wallet.module.sass';

interface DataItem {
  amount: number;
  date: string;
  action: string;
}

export default function HistoryOperation() {
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    api
      .get(`/payment/me`)
      .then((response) =>
        setData(
          response.data.filter(
            (item: { status: boolean }) => item.status === true,
          ),
        ),
      )
      .catch((error) => console.error(error));
  }, []);
  return (
    <div className={styles.historyOperation}>
      <span className={styles.historyOperation__header}>
        История операций по кошельку
      </span>
      <div
        className={`${styles.operation} ${
          data.length > 0 ? '' : styles.noData
        }`}
      >
        {data.length > 0 ? (
          <>
            <ul className={styles.operationUl}>
              {data.map((item, index: number) => (
                <li key={index} className={styles.operationLi}>
                  <div className={styles.operationDate}>
                    {new Date(item.date).toLocaleDateString('ru-Ru', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </div>
                  <div
                    className={
                      item.action === 'debit'
                        ? styles.green_text
                        : styles.red_text
                    }
                  >
                    {item.action === 'debit' ? '+' : '-'}
                    {item.amount.toFixed(1)} <span>&#8381;</span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p className={styles.otherText}>
            Здесь будут данные о движении средств на Вашем счету
          </p>
        )}
      </div>
    </div>
  );
}
