import { combineReducers } from '@reduxjs/toolkit';
import { NameSpace } from './const';
import { userSlice } from './user-slice/userSlice';
import { tariffSlice } from './tariff-slice/tariffSlice';
import { taskSlice } from './task-slice/taskSlice';

export const rootReducer = combineReducers({
  [NameSpace.UserData]: userSlice.reducer,
  [NameSpace.Tariff]: tariffSlice.reducer,
  [NameSpace.Task]: taskSlice.reducer,
});
