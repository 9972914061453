import { useCallback, useEffect, useState } from 'react';

import { Role, User } from '../../../types/user';
import { api } from '../../../services/api';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { patchUser } from '../../../store/user-slice/apiActions';

// import PassData from '../../../components/Profile/PassData/PassData';
// import TimezonePicker from '../../../components/TimezonePicker/TimezonePicker';
// import MainData from '../../../components/Profile/MainData/MainData';
import Toggle from '../../../components/ui/toggle/toggle';
import Dropdown from '../../../components/ui/dropdown/Dropdown';
import Profile from '../../Profile/Profile';

import styles from './admin-user-card.module.sass';
import TextInput from '../../ui/input/TextInput';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';

interface AdminUserCardProps {
  user: User;
  setUser: (user: User) => void;
}

const AdminUserCard = ({ user, setUser }: AdminUserCardProps) => {
  const dispatch = useAppDispatch();
  const adminId = useAppSelector((state) => state.UserData.user?.id);
  const [staffList, setStaffList] = useState<Role[]>([]);
  const [balance, setBalance] = useState('');
  const [currentBalance, setCurrentBalance] = useState(
    Math.floor(user.balance),
  );
  const [discount, setDiscount] = useState('');
  const [currentDiscount, setCurrentDiscount] = useState(user.discount);

  const handleBalanceChanhe = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setBalance(newValue);
  };

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setDiscount(newValue);
  };

  const handleSubmitDiscount = () => {
    api
      .patch(`/user/${user.id}/discount`, {
        discount: discount,
      })
      .then(() => {
        setCurrentDiscount(discount);
        setDiscount('');
      });
  };

  const handleSubmitBalance = () => {
    api
      .patch(`/user/${user.id}/balance`, {
        balance: balance,
      })
      .then(() => {
        setCurrentBalance(parseFloat(balance) + currentBalance);
        setBalance('');
      });
  };

  useEffect(() => {
    api
      .get('/role/')
      .then((r) => {
        setStaffList(r.data);
      })
      .catch((e) => console.error(e));
  }, []);

  const handleSubmit = useCallback((field: string, value: string | Blob) => {
    const formData = new FormData();
    formData.append(field, value);
    if (adminId === user.id) {
      dispatch(patchUser(formData));
    } else {
      api
        .patch(`/user/${user.id}`, formData)
        .then((r) => setUser(r.data))
        .catch((e) => console.error(e));
    }
  }, []);

  return (
    <main className={styles.profileWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.mainData}>
          <Profile
            onSubmit={(formData) => {
              if (adminId === user.id) {
                return dispatch(patchUser(formData)).unwrap();
              } else {
                return api
                  .patch(`/user/${user.id}`, formData)
                  .then((response) => {
                    setUser(response.data);
                    return response.data;
                  });
              }
            }}
            variant="admin"
            user={user}
          />
          <div className={styles.columnWrapper}>
            <div className={styles.rowWrapper}>
              <div className={styles.balanceWrapper}>
                <div className={styles.currentBlanceWrapper}>
                  <h3 className={`${styles.header} ${styles.balanceHeader}`}>
                    Текущий баланс
                  </h3>
                  <p className={styles.currentBlance}>{currentBalance} ₽</p>
                </div>
                <div className={styles.addBalance}>
                  <h3 className={`${styles.header} ${styles.balanceHeader}`}>
                    Добавить денег пользователю
                  </h3>
                  <div className={styles.balance}>
                    <input
                      className={styles.inputBalance}
                      type="text"
                      placeholder="Введите сумму для пополнения"
                      value={balance}
                      onChange={handleBalanceChanhe}
                    ></input>
                    <span>₽</span>
                  </div>
                  <Btn_transparent
                    variant="standart"
                    onClick={handleSubmitBalance}
                  >
                    Пополнить
                  </Btn_transparent>
                </div>
              </div>
              <div className={styles.roleWrapper}>
                <div className={styles.role}>
                  <h3 className={styles.header}>Роль</h3>
                  <div className={styles.dropdown}>
                    <Dropdown
                      disabled={user.id === adminId}
                      options={staffList.map((staff) => staff.pretty_name)}
                      selectedOption={user.role.pretty_name}
                      onChange={(option) => {
                        if (user.id === adminId) return;
                        const newRole = staffList.find(
                          (staff) => staff.pretty_name === option,
                        )?.name;
                        if (!newRole) return;
                        handleSubmit('role', newRole);
                      }}
                    />
                  </div>
                </div>
                <div className={styles.currentTariff}>
                  <div>
                    <h3 className={styles.header}>Тариф</h3>
                    <TextInput
                      temporaryDisabled
                      defaultValue={
                        user.subscribe ? user.subscribe.tariff_id : 'Без тарифа'
                      }
                    />
                  </div>
                  <Toggle
                    className={`${styles.header} ${styles.toggleLabel}`}
                    checked={user.is_banned}
                    disabled={user.id === adminId}
                    toggleHandler={(e) => {
                      if (user.id === adminId) return;
                      handleSubmit('is_banned', String(e.target.checked));
                    }}
                    title="Блокировка пользователя"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.diccountWrapper}>
              <div className={styles.currentDiscount}>
                <h3 className={`${styles.header} ${styles.balanceHeader}`}>
                  Текущая скидка
                </h3>
                <p className={styles.currentBlance}>{currentDiscount}%</p>
              </div>
              <div className={styles.addBalance}>
                <h3 className={`${styles.header} ${styles.balanceHeader}`}>
                  Установить скидку
                </h3>
                <div className={styles.balance}>
                  <input
                    className={styles.inputBalance}
                    type="text"
                    placeholder="Введите процент скидки"
                    value={discount}
                    onChange={handleDiscountChange}
                  ></input>
                  <span>%</span>
                </div>
                <Btn_transparent
                  variant="standart"
                  onClick={handleSubmitDiscount}
                >
                  Установить
                </Btn_transparent>
              </div>
            </div>
            <div className={styles.diccountWrapper}>
              <span>Пустое поле на будущее</span>
            </div>
          </div>
        </div>
        {/* <div className={styles.passData}>
          <PassData disabled={adminId !== user.id} />
        </div> */}
      </div>
      {/* <div className={styles.wrapper}></div> */}
    </main>
  );
};

export default AdminUserCard;
