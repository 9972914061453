import React, { useEffect, useState } from 'react';
import { ParserTask } from '../../../types/parserTask';
import { api } from '../../../services/api';
import Loader from '../loader/loader';
import style from './areachart.module.sass';
import { Roles } from '../../../consts/consts';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import { setMailedToday } from '../../../store/task-slice/taskSlice';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ScriptableContext,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import getMailingDaily from '../../../helpers/Mailing/getMailingDaily';
import useMailingDaily from '../../../hooks/useMailingDaily';

// interface TasksProps {
//   tasks: ParserTask[];
// }

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: 'white',
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: true,
        color: '#8586A5',
        lineWidth: 0.25,
      },
      ticks: {
        color: 'white',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Отправленные сообщения',
      color: 'white',
      font: {
        size: 16,
      },
      padding: {
        bottom: 40,
        top: 10,
      },
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
  },
};

export default function AreaChartMailing() {
  const [dataTasks, setDataTasks] = useState<ParserTask[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [sortedDates, formattedSortedDates] = useMailingDaily(
    dataTasks as ParserTask[],
  );
  const dispatch = useAppDispatch();

  const role = useAppSelector((state) => state.UserData.user?.role.name);

  useEffect(() => {
    setIsLoading(true);
    if (role === Roles.Admin || role === Roles.SuperUser) {
      api
        .get('/telegram/tasks/')
        .then((response) => setDataTasks(response.data))
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    } else {
      api
        .get('/telegram/tasks/me')
        .then((response) => setDataTasks(response.data))
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    dispatch(setMailedToday(sortedDates[sortedDates.length - 1]));
  }, [sortedDates]);

  const data = {
    labels: formattedSortedDates as string[],
    datasets: [
      {
        fill: true,
        data: sortedDates,
        borderColor: '#B63940',
        pointRadius: 2,
        borderWidth: 2,
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 100, 0, 400);
          gradient.addColorStop(0, 'rgba(182, 57, 64, 1)');
          gradient.addColorStop(1, 'rgba(182, 57, 64, 0)');
          return gradient;
        },
      },
    ],
  };
  return (
    <div className={style.graphWrapper}>
      {isLoading ? (
        <div className={style.loader}>
          <Loader />
        </div>
      ) : dataTasks?.length === 0 ? (
        <p className={style.textNoData}>
          Здесь будет график с данными по количеству напарсенных пользователей
          за последние 7 дней
        </p>
      ) : (
        <Line options={options} data={data} width={'100%'} height={'100%'} />
      )}
    </div>
  );
}
