import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as NotificationIcon } from '../../../assets/images/icons/notification.svg';
import { ReactComponent as DocumentsIcon } from '../../../assets/images/icons/vector.svg';
import { ReactComponent as WalletIcon } from '../../../assets/images/icons/wallet_header.svg';
import { ReactComponent as LogoMobile } from '../../../assets/images/icons/Logo_mobile.svg';
import { Routes } from '../../../router/routes';
import { MenuItem } from '../menu-items';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import Toggle from '../../ui/toggle/toggle';
import { Modes, Roles } from '../../../consts/consts';
import { changeMode } from '../../../store/user-slice/userSlice';
import styles from './index.module.sass';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../consts/consts';
import ReplenishmentWindow from '../../ReplenishmentWindow/ReplenishmentWindow';
import { useEffect, useState } from 'react';
import { api } from '../../../services/api';

interface PropTypes {
  menuItems: MenuItem[];
  currentPage: MenuItem;
}

interface DataItem {
  amount: number;
  date: string;
  action: string;
}

const Header = ({ menuItems, currentPage }: PropTypes) => {
  const [isReplenishmentOpen, setIsReplenishmentOpen] = useState(false);
  const [data, setData] = useState<DataItem[]>([]);

  const user = useSelector((state: any) => state.UserData.user);

  const userEmail = useAppSelector((state) => state.UserData.user?.email);
  const userFirstname = useAppSelector(
    (state) => state.UserData.user?.firstname,
  );
  const userLastname = useAppSelector((state) => state.UserData.user?.lastname);

  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.UserData.user?.role.name);
  const mode = useAppSelector((state) => state.UserData.mode);
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get(`/payment/me`)
      .then((response) =>
        setData(
          response.data.filter(
            (item: { status: boolean }) => item.status === true,
          ),
        ),
      )
      .catch((error) => console.error(error));
  }, []);

  const userBalance: number | undefined = useAppSelector(
    (state) => state.UserData.user?.balance,
  );

  const roundedUserBalance: number | undefined =
    userBalance !== undefined ? Math.floor(userBalance) : undefined;

  const toggleModeHandler = () => {
    if (mode === Modes.Admin) {
      dispatch(changeMode(Modes.User));
      navigate(Routes.Dashboard);
    } else if (mode === Modes.User) {
      dispatch(changeMode(Modes.Admin));
      navigate(Routes.AdminUsers);
    }
  };

  const renderUserAvatar = () => {
    if (user && user.avatar_url) {
      const avatarUrl = `${BASE_URL}/${user.avatar_url}`;
      return <img src={avatarUrl} className={styles.avatarImg} />;
    }
    return null;
  };

  return (
    <header className={styles.header}>
      <ReplenishmentWindow
        isActive={isReplenishmentOpen}
        setActive={setIsReplenishmentOpen}
      />
      <div className={styles.headerLocation}>
        <p className={styles.headerLocationText}>{currentPage.text}</p>
      </div>
      <div className={styles.header__blocks}>
        <div className={styles.header__links}>
          <div className={styles.block__left}>
            {role === Roles.Admin || role === Roles.SuperUser ? (
              <Toggle
                className={styles.adminToggle}
                checked={mode === Modes.Admin}
                toggleHandler={() => toggleModeHandler()}
                title="Админ"
              />
            ) : (
              <div className={styles.header__logo}>
                <LogoMobile />
              </div>
            )}
            <div
              className={styles.header__wallet}
              onClick={() => setIsReplenishmentOpen(true)}
            >
              <WalletIcon />
              <span className={styles.balance__value}>
                {roundedUserBalance} <span>&#8381;</span>
              </span>
              <button
                className={styles.header__wallet_btn}
                type="submit"
                onClick={() => setIsReplenishmentOpen(true)}
              >
                Пополнить
              </button>
            </div>
            {Number(user.discount) > 0 && (
              <div className={styles.discount}>
                <span>cкидка</span>
                <span>{user.discount}%</span>
              </div>
            )}
          </div>
          <div className={styles.block__right}>
            <div className={styles.notification__icon}>
              <Link to="/documents">
                <div className={styles.documentsLink}>
                  <DocumentsIcon />
                </div>
              </Link>
              <div className={styles.header__icon}>
                <NotificationIcon />
              </div>
            </div>
            <Link to="/profile">
              <div className={styles.profile}>
                <div className={styles.userData}>
                  <p>
                    {userFirstname} {userLastname}
                  </p>
                  <p className={styles.email}>{userEmail}</p>
                </div>
                <div className={styles.userAvatar}>{renderUserAvatar()}</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
