import { useMemo, useState, useEffect } from 'react';
import {
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  getIn,
  type FormikErrors,
} from 'formik';
import { useOutletContext } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch } from '../../../hooks/redux';
import { fetchTasks } from '../../../store/task-slice/apiActions';
import { Roles } from '../../../consts/consts';
import { useAppSelector } from '../../../hooks/redux';
import { activitiesValidation } from './activities-validation-schema';
import { api } from '../../../services/api';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';
import ModalCentered from '../../ui/modal-window/ModalCentered';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import 'bootstrap/dist/css/bootstrap.min.css';

// import AudienceSelectionWindow from '../AudienceSelectionWindow/AudienceSelectionWindow';

import IconButton from '../../ui/iconButton/IconButton';
import TextInput from '../../ui/input/TextInput';
// import Button from '../../ui/button/Button';
import Checkbox from '../../ui/checkbox/Checkbox';
import InfoTooltip from '../../ui/infoTooltip/infoTooltip';
// import ModalWindow from '../../ui/modal-window/ModalWindow';
import MailingYoutubePlayer from '../../../pages/Mailing/MailingYoutubePlayer/MailingYoutubePlayer';

import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/icons/minus.svg';

import sharedStyles from '../index.module.sass';

type FormValues = {
  groups: { id: string; value: string }[];
  amountTo: number | null;
  amountFrom: number;
  startDate: string | null;
  endDate: string | null;
  name: string;
  activities: string[];
  id_bot: number | null;
};

const Activities = () => {
  const isDisabled = useOutletContext<boolean>();
  const ACTIVITIES = useMemo(() => ['Комментарии', 'Репосты'], []);
  const MAX_FIELDS = 5;
  const [isFetching, setIsFetching] = useState(false);
  const [lastDay, setLastDay] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState({ name: '', parsing: '', daily: '' });
  // const [isSelectionActive, setIsSelectionActive] = useState(false);

  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.UserData.user?.role.name);
  const currentTariff = useAppSelector((state) => state.Tariff.currentTariff);

  const refreshTasks = () => {
    const limit = 10;
    const offset = 0;
    dispatch(fetchTasks({ limit, offset, operation: 'parsing' }));
  };

  useEffect(() => {
    refreshTasks();
  }, []);

  function getDateAgo(daysAgo: number) {
    const currentDate = new Date();
    const targetDate = new Date(currentDate);
    targetDate.setDate(currentDate.getDate() - daysAgo);
    const yyyy = targetDate.getFullYear();
    const mm = String(targetDate.getMonth() + 1).padStart(2, '0');
    const dd = String(targetDate.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }

  const handleSetLastDay = () => {
    setLastDay(getDateAgo(1));
  };
  const handleSetLastThreeDay = () => {
    setLastDay(getDateAgo(3));
  };
  const handleSetLastWeek = () => {
    setLastDay(getDateAgo(7));
  };
  const handleSetLastMonth = () => {
    setLastDay(getDateAgo(30));
  };

  const initialValues: FormValues = {
    groups: [{ id: uuidv4(), value: '' }],
    amountTo: null,
    amountFrom: 1,
    startDate: getDateAgo(7),
    endDate: getDateAgo(0),
    name: '',
    activities: ['Комментарии', 'Репосты'],
    id_bot: null,
  };

  const validateStepOne = (
    values: FormValues,
    errors: FormikErrors<FormValues>,
  ) => {
    return values.groups[0].value.length > 0 && !errors.groups;
  };

  const validateStepTwo = (values: FormValues) => {
    return values.amountFrom >= 1 && values.amountFrom <= values.groups.length;
  };

  const validateStepThree = (
    values: FormValues,
    errors: FormikErrors<FormValues>,
  ) => {
    return values.activities.length > 0 && !errors.activities;
  };

  const validateStepFour = (errors: FormikErrors<FormValues>) => {
    return errors.endDate || errors.startDate ? false : true;
  };

  const validateStepFive = (
    values: FormValues,
    errors: FormikErrors<FormValues>,
  ) => {
    return values.name && !errors.name;
  };

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsFetching(true);
    api
      .post('/telegram/parser/activemembers', {
        task_name: values.name.replaceAll(' ', ''),
        parsed_chats: values.groups.map((group) => group.value),
        from_date: lastDay || values.startDate,
        to_date: values.endDate,
        activity_count: values.amountFrom,
        activity: {
          comments: values.activities.includes('Комментарии'),
          reposts: values.activities.includes('Репосты'),
        },
        id_bot: values.id_bot,
        export_file_ext: 'xlsx',
        //TODO
        rerun: false,
      })
      .then(() => {
        actions.resetForm();
        setModalShow(true);
        setError({ name: '', parsing: '', daily: '' });
        refreshTasks();
      })
      .catch((e) => {
        console.error(e);
        if (
          e.response.data.detail === 'Задание с таким именем уже существует'
        ) {
          setError({ ...error, name: e.response.data.detail });
        } else if (
          e.response.data.detail === 'Превышен лимит одновременных парсеров'
        ) {
          setError({ ...error, parsing: e.response.data.detail });
        } else if (
          e.response.data.detail === 'Превышен лимит дневных парсеров'
        ) {
          setError({ ...error, daily: e.response.data.detail });
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <section>
      <ModalCentered
        show={modalShow}
        headerText="Парсинг запущен"
        bodyText='Результаты парсинга отобразятся в таблице "История задач"'
        btnText="Закрыть"
        onHide={() => setModalShow(false)}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={activitiesValidation}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form className={sharedStyles.form}>
            <a
              className={sharedStyles.instruction__link}
              href={require('../../../assets/pdfs/parsing-activities.pdf')}
              target="_blank"
            >
              Инструкция - Как собрать аудиторию в ТГ при помощи парсера
              "Активность"
            </a>
            <MailingYoutubePlayer videoID="ZILbjFQwKUs?si=lN8k2XqOyeG-mYyC" />
            <div
              className={`${sharedStyles.fieldValidationBorder} ${
                validateStepOne(values, errors) ? sharedStyles.success : ''
              } ${errors.groups ? sharedStyles.errors : ''}`}
            >
              <div className={sharedStyles.fieldWrapper}>
                <FieldArray
                  name="groups"
                  render={({ form, push, remove }) => (
                    <>
                      <InfoTooltip text="Для осуществления поиска необходимо указать ссылку на группу или канал в которой производить парсинг аудитории. Групп или каналов можно указать несколько (от 1 до 5). Каждая новая ссылка должна быть на новой строке (в новом инпуте)." />
                      <div className={sharedStyles.groupsHeader}>
                        <h3 className={sharedStyles.header}>
                          Каналы и группы для поиска
                        </h3>
                      </div>
                      <div className={sharedStyles.groupsInputsWrapper}>
                        <span style={{ fontSize: '12px', color: '#8586A5' }}>
                          <strong style={{ color: '#B63940' }}>
                            Обязательно!!!
                          </strong>{' '}
                          Канал/группа должна быть открыта и комментарии в ней
                          так же должны быть открыты! В закрытой группе с
                          закрытыми комментариями парсинг соберет 0 аудитории
                          <br /> Пример: https://t.me/название_группы
                        </span>
                        {values.groups.map((group, index) => (
                          <div
                            className={sharedStyles.groupsInput}
                            key={group.id}
                          >
                            <TextInput
                              name={`groups.${index}.value`}
                              type="text"
                              placeholder="Вставьте ссылку на канал, группу"
                              onBlur={handleBlur}
                              value={values.groups[index].value}
                              onChange={handleChange}
                              errorMessage={(() => {
                                if (typeof errors.groups !== 'object')
                                  return '';
                                if (typeof errors.groups[index] !== 'object')
                                  return '';
                                if (getIn(form.touched, `groups.${index}`)) {
                                  return (
                                    errors.groups[index] as { value: string }
                                  ).value;
                                }
                              })()}
                            />
                            {index > 0 ? (
                              <IconButton
                                className={sharedStyles.minusIcon}
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <MinusIcon />
                              </IconButton>
                            ) : null}
                          </div>
                        ))}
                        <IconButton
                          disabled={values.groups.length >= MAX_FIELDS}
                          onClick={() => push({ id: uuidv4(), value: '' })}
                        >
                          <div className={sharedStyles.createFieldBtn}>
                            <PlusIcon />
                            {`Добавить поле (${values.groups.length}/${MAX_FIELDS})`}
                          </div>
                        </IconButton>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <div
              className={`${sharedStyles.fieldValidationBorder} ${
                validateStepOne(values, errors) && validateStepTwo(values)
                  ? sharedStyles.success
                  : ''
              } ${errors.amountFrom ? sharedStyles.errors : ''}`}
            >
              <div className={sharedStyles.fieldWrapper}>
                <h3 className={sharedStyles.header}>Количество активностей</h3>
                <InfoTooltip text="Обозначьте, в каком количестве групп должны состоять пользователи, по умолчанию цифра 1. Если необходимо, можете заменить цифру. Максимальная цифра 5, т.к. максимальное количество групп - 5." />
                <div className={sharedStyles.inputGroup}>
                  <div className={sharedStyles.fieldQty}>
                    <button
                      onClick={() =>
                        setFieldValue('amountFrom', values.amountFrom - 1)
                      }
                      style={{
                        cursor: 'pointer',
                        fontSize: '16px',
                      }}
                      className={
                        values.amountFrom <= 0 ? sharedStyles.hidden : ''
                      }
                    >
                      &ndash;
                    </button>
                    <input
                      className={sharedStyles.inputQty}
                      value={values.amountFrom || 0}
                      onChange={(e) => {
                        setFieldValue(
                          'amountFrom',
                          Number(e.target.value) <= values.groups.length
                            ? Number(e.target.value)
                            : values.groups.length,
                        );
                      }}
                      name="amountFrom"
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setFieldValue('amountFrom', values.amountFrom + 1)
                      }
                      className={`${sharedStyles.incDecButton} ${
                        values.amountFrom >= values.groups.length
                          ? sharedStyles.disabled
                          : ''
                      }`}
                      disabled={values.amountFrom >= values.groups.length}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${sharedStyles.fieldValidationBorder} ${
                validateStepOne(values, errors) &&
                validateStepTwo(values) &&
                validateStepThree(values, errors)
                  ? sharedStyles.success
                  : ''
              } ${errors.activities ? sharedStyles.errors : ''}`}
            >
              <div className={sharedStyles.fieldWrapper}>
                <h3 className={sharedStyles.header}>
                  Какие активности учитывать
                </h3>
                <InfoTooltip text="Необходимо указать, какие активности учитывать - комментарии или репосты, или и то и другое одновременно." />
                <div className={sharedStyles.activities}>
                  {ACTIVITIES.map((activity) => (
                    <Checkbox
                      name="activities"
                      key={activity}
                      title={activity}
                      value={activity}
                      checkboxHandler={handleChange}
                      checked={values.activities.includes(activity)}
                    />
                  ))}
                </div>
                <div className={sharedStyles.errorMessage}>
                  {errors.activities && touched.activities
                    ? errors.activities
                    : null}
                </div>
              </div>
            </div>
            <div
              className={`${sharedStyles.fieldValidationBorder} ${
                validateStepOne(values, errors) &&
                validateStepTwo(values) &&
                validateStepThree(values, errors) &&
                validateStepFour(errors)
                  ? sharedStyles.success
                  : ''
              } ${
                errors.endDate || errors.startDate ? sharedStyles.errors : ''
              }`}
            >
              <div className={sharedStyles.fieldWrapper}>
                <h3 className={sharedStyles.header}>Период</h3>
                <InfoTooltip text="Обозначьте период за который искать данные активности с - по. Если период не выбран, парсинг будет осуществлен за все время." />
                <div className={sharedStyles.periodWrapper}>
                  <div className={sharedStyles.inputGroup}>
                    <input
                      className={sharedStyles.inputDate}
                      name="startDate"
                      type="date"
                      value={lastDay || values.startDate || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <input
                      className={`custom-calendar-input ${sharedStyles.inputDate}`}
                      name="endDate"
                      type="date"
                      value={values.endDate || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <ButtonGroup size="sm" className={sharedStyles.buttonGroup}>
                    <Button
                      variant="outline-secondary"
                      className={sharedStyles.customBtnSecondary}
                      onClick={handleSetLastDay}
                      style={{ padding: '1px 1px', fontSize: '12px' }}
                    >
                      день
                    </Button>
                    <Button
                      variant="outline-secondary"
                      className={sharedStyles.customBtnSecondary}
                      onClick={handleSetLastThreeDay}
                      style={{
                        padding: '1px 1px',
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      3 дня
                    </Button>
                    <Button
                      variant="outline-secondary"
                      className={sharedStyles.customBtnSecondary}
                      onClick={handleSetLastWeek}
                      style={{ padding: '1px 1px', fontSize: '12px' }}
                    >
                      неделя
                    </Button>
                    <Button
                      variant="outline-secondary"
                      className={sharedStyles.customBtnSecondary}
                      onClick={handleSetLastMonth}
                      style={{ padding: '1px 1px', fontSize: '12px' }}
                    >
                      месяц
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
            {/*загрузка файла csv для отслеживания активностей конкретных пользователей в группах */}
            {/* <div>
              <h3 className={sharedStyles.header}>
                Пользователи, активности которых учитывать
              </h3>
              <ModalWindow
                isActive={isSelectionActive}
                setActive={setIsSelectionActive}
              >
                <AudienceSelectionWindow />
              </ModalWindow>
              <Button
                // disabled
                style={{ maxWidth: '610px' }}
                variant="additional"
                onClick={() => setIsSelectionActive(true)}
              >
                Выбрать аудиторию
              </Button>
              <div className={sharedStyles.selectedTasks}></div>
            </div> */}
            <div
              className={`${sharedStyles.fieldValidationBorder} ${
                validateStepOne(values, errors) &&
                validateStepTwo(values) &&
                validateStepThree(values, errors) &&
                validateStepFour(errors) &&
                validateStepFive(values, errors)
                  ? sharedStyles.success
                  : ''
              } ${errors.name ? sharedStyles.errors : ''}`}
            >
              <div className={sharedStyles.fieldWrapper}>
                <h3 className={sharedStyles.header}>Название задачи</h3>
                <InfoTooltip text="Дайте название своему парсингу (задаче). Это название будет видно только Вам в таблице с историями по парсингу." />
                <TextInput
                  style={{ maxWidth: '610px' }}
                  name="name"
                  type="text"
                  placeholder="Придумайте название задачи"
                  hintMessage="Название будет видно только Вам"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    error.name ||
                    (errors.name && touched.name ? errors.name : '')
                  }
                />
                {role === Roles.Admin || role === Roles.SuperUser ? (
                  <TextInput
                    style={{ maxWidth: '610px' }}
                    name="id_bot"
                    type="text"
                    placeholder="Введите id бота"
                    value={values.id_bot || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : null}
              </div>
            </div>
            {error.parsing && (
              <p
                className={sharedStyles.errorMessage}
                style={{ margin: '0 auto 16px', textAlign: 'center' }}
              >
                {`${error.parsing}. По Вашему тарифу их количество - ${currentTariff.options.simultaneous_parsing}. Подождите окончания одной из задач.`}
              </p>
            )}
            {error.daily && (
              <p
                className={sharedStyles.errorMessage}
                style={{ margin: '0 auto 16px', textAlign: 'center' }}
              >
                {`${error.daily}. По Вашему тарифу их количество - ${currentTariff.options.parsers_per_day}.`}
              </p>
            )}
            <Btn_transparent
              style={{ maxWidth: '610px' }}
              variant={'standart'}
              type="submit"
              disabled={isFetching || isDisabled}
            >
              {isDisabled
                ? 'Недоступно для вашего тарифа'
                : 'Начать сбор аудитории'}
            </Btn_transparent>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default Activities;
