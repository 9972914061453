import { FC, useState } from 'react';
import ModalWindow from '../../../ui/modal-window/ModalWindow';
import { Form, Formik, FormikState } from 'formik';
import { Tariff, TariffForm } from '../../../../types/tariff';
import styles from '../admin-tariffs.module.sass';
import TextInput from '../../../ui/input/TextInput';
import Checkbox from '../../../ui/checkbox/Checkbox';
import Btn_transparent from '../../../ui/btn_transparent/Btn_transparent';
import { tariffSchema } from './validation-schema';
import Loader from '../../../ui/loader/loader';
import { useAppDispatch } from '../../../../hooks/redux';
import { addTariff } from '../../../../store/tariff-slice/apiActions';

interface NewTariffFormProps {
  isActive: boolean;
  setIsActive: (modalIsActive: boolean) => void;
}

const NEW_TARIFF_BLANC: TariffForm = {
  name: '',
  description: '',
  limitation_days: '',
  price: '',
  active: false,
  archive: false,
  options: {
    parsers_per_day: '',
    simultaneous_parsing: '',
    geo: false,
    members: false,
    activity: false,
    count_of_messages: '',
    invitings_per_day: '',
  },
};

const NewTariffForm: FC<NewTariffFormProps> = ({ isActive, setIsActive }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const submitHandler = (
    tariff: TariffForm,
    resetForm: (nextState?: Partial<FormikState<Tariff>> | undefined) => void,
  ) => {
    setIsSubmitting(true);
    dispatch(addTariff(tariff as Tariff))
      .unwrap()
      .then(() => {
        setIsActive(false);
        setErrorMessage('');
        resetForm();
      })
      .catch((err) => {
        setErrorMessage(err.detail);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const resetHandler = (resetForm: () => void) => {
    resetForm();
    setIsActive(false);
  };

  return (
    <Formik
      initialValues={NEW_TARIFF_BLANC}
      validationSchema={tariffSchema}
      onSubmit={(values, { resetForm }) => submitHandler(values, resetForm)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        dirty,
        resetForm,
      }) => (
        <ModalWindow
          isActive={isActive}
          setActive={() => resetHandler(resetForm)}
          style={{ marginTop: '2vh' }}
          clickByOut={false}
        >
          <Form className={styles.tariffForm}>
            <h2 className={styles.tariffForm_title}>Новый тариф</h2>
            <TextInput
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={errors.name && touched.name ? errors.name : ''}
              placeholder="Название тарифа"
              placeholderStyle={{ backgroundColor: '#2B3243' }}
            />
            <TextInput
              name="limitation_days"
              value={values.limitation_days}
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                errors.limitation_days && touched.limitation_days
                  ? errors.limitation_days
                  : ''
              }
              placeholder="Срок действия"
              placeholderStyle={{ backgroundColor: '#2B3243' }}
            />
            <TextInput
              name="price"
              value={values.price}
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={errors.price && touched.price ? errors.price : ''}
              placeholder="Стоимость"
              placeholderStyle={{ backgroundColor: '#2B3243' }}
            />
            <TextInput
              name="options.count_of_messages"
              value={values.options.count_of_messages}
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                errors.options?.count_of_messages &&
                touched.options?.count_of_messages
                  ? errors.options?.count_of_messages
                  : ''
              }
              placeholder="Ограничение сообщений"
              placeholderStyle={{ backgroundColor: '#2B3243' }}
            />
            <TextInput
              name="options.invitings_per_day"
              value={values.options.invitings_per_day}
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                errors.options?.count_of_messages &&
                touched.options?.count_of_messages
                  ? errors.options?.count_of_messages
                  : ''
              }
              placeholder="Ограничение инвайтов в день"
              placeholderStyle={{ backgroundColor: '#2B3243' }}
            />
            <div>
              <h3 className={styles.tariffForm_subTitle}>Предусматривает</h3>
              <div className={styles.tariffForm_parsers}>
                <TextInput
                  name="options.simultaneous_parsing"
                  value={values.options.simultaneous_parsing}
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    errors.options?.simultaneous_parsing &&
                    touched.options?.simultaneous_parsing
                      ? errors.options?.simultaneous_parsing
                      : ''
                  }
                  placeholder="Одновременный парсинг"
                  placeholderStyle={{
                    backgroundColor: '#2B3243',
                    fontSize: '12px',
                  }}
                />
                <TextInput
                  name="options.parsers_per_day"
                  value={values.options.parsers_per_day}
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    errors.options?.parsers_per_day &&
                    touched.options?.parsers_per_day
                      ? errors.options?.parsers_per_day
                      : ''
                  }
                  placeholder="Парсингов в день"
                  placeholderStyle={{
                    backgroundColor: '#2B3243',
                    fontSize: '12px',
                  }}
                />
              </div>
            </div>
            <div>
              <h3 className={styles.tariffForm_methodsTitle}>
                Методы сбора аудитории
              </h3>
              <Checkbox
                className={styles.tariffForm_checkbox}
                name="options.members"
                checked={values.options.members}
                title="Участники групп/каналов"
                checkboxHandler={handleChange}
              />
              <Checkbox
                className={styles.tariffForm_checkbox}
                name="options.activity"
                checked={values.options.activity}
                title="Активность участников групп/каналов"
                checkboxHandler={handleChange}
              />
              <Checkbox
                className={styles.tariffForm_checkbox}
                name="options.geo"
                checked={values.options.geo}
                title="Геолокация"
                checkboxHandler={handleChange}
              />
            </div>
            {errorMessage && (
              <p className={styles.tariffForm_error}>{errorMessage}</p>
            )}
            <Btn_transparent
              disabled={!(isValid && dirty) || isSubmitting}
              type="submit"
              variant="standart"
            >
              {isSubmitting ? (
                <div className={styles.loaderContainer}>
                  <Loader width={24} height={24} />
                </div>
              ) : (
                <>Создать тариф</>
              )}
            </Btn_transparent>
          </Form>
        </ModalWindow>
      )}
    </Formik>
  );
};
export default NewTariffForm;
