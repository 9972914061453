import styles from './wallet.module.sass';
import { useAppSelector } from '../../../hooks/redux';
import MyTariff from './MyTariff';
import HistoryOperation from './HistoryOperation';
import TariffLimits from './TariffLimits';

export default function Wallet() {
  const parsingGeo: boolean | undefined = useAppSelector(
    (state) => state.UserData.user?.subscribe?.tariff_options.geo,
  );

  const parsingMembers: boolean | undefined = useAppSelector(
    (state) => state.UserData.user?.subscribe?.tariff_options.members,
  );

  const parsingActivity: boolean | undefined = useAppSelector(
    (state) => state.UserData.user?.subscribe?.tariff_options.activity,
  );

  return (
    <div className={styles.wrapper}>
      <MyTariff />
      <HistoryOperation />
      <div className={styles.tariffInformation}>
        <TariffLimits />
        <div className={styles.tariff__optionParsing}>
          <span className={styles.tariff__optionParsing_text}>
            Способы сбора аудитории
          </span>
          <div className={styles.optionBlock}>
            {parsingGeo && (
              <span className={styles.optionBlock__geo}>Геолокация</span>
            )}
            {parsingMembers && (
              <span className={styles.optionBlock__members}>
                Участники групп / каналов
              </span>
            )}
            {parsingActivity && (
              <span className={styles.optionBlock__activity}>
                Активность участников групп / каналов
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
