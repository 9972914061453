import styles from './contract-page.module.sass';

const ContractPage = () => {
  return (
    <div className={styles.contract}>
      <h2 className={styles.contract__header}>
        ПУБЛИЧНАЯ ОФЕРТА <br /> о заключении договора об оказании услуг
      </h2>

      <ul className={styles.contract__list_one}>
        <li>
          <h3>1. Общие положения</h3>
          <p>
            В настоящей Публичной оферте содержатся условия заключения Договора
            об оказании услуг (далее по тексту - «Договор об оказании услуг»
            и/или «Договор»). Настоящей офертой признается предложение,
            адресованное одному или нескольким конкретным лицам, которое
            достаточно определенно и выражает намерение лица, сделавшего
            предложение, считать себя заключившим Договор с адресатом, которым
            будет принято предложение.
          </p>
          <p>
            Совершение указанных в настоящей Оферте действий является
            подтверждением согласия обеих Сторон заключить Договор об оказании
            услуг на условиях, в порядке и объеме, изложенных в настоящей
            Оферте.
          </p>
          <p>
            Нижеизложенный текст Публичной оферты является официальным публичным
            предложением Исполнителя, адресованный заинтересованному кругу лиц
            заключить Договор об оказании услуг в соответствии с положениями
            пункта 2 статьи 437 Гражданского кодекса РФ.
          </p>
          <p>
            Договор об оказании услуг считается заключенным и приобретает силу с
            момента совершения Сторонами действий, предусмотренных в настоящей
            Оферте, и означающих безоговорочное, а также полное принятие всех
            условий настоящей Оферты без каких-либо изъятий или ограничений на
            условиях присоединения.
          </p>
          <h4>Термины и определения</h4>
          <p>
            Договор – текст настоящей Оферты с Приложениями, являющимися
            неотъемлемой частью настоящей Оферты, акцептованный Заказчиком путем
            совершения конклюдентных действий, предусмотренных настоящей
            Офертой.
          </p>
          <p>
            Конклюдентные действия — это поведение, которое выражает согласие с
            предложением контрагента заключить, изменить или расторгнуть
            договор. Действия состоят в полном или частичном выполнении условий,
            которые предложил контрагент.
          </p>
          <p>
            Сайт Исполнителя в сети «Интернет» – совокупность программ для
            электронных вычислительных машин и иной информации, содержащейся в
            информационной системе, доступ к которой обеспечивается посредством
            сети «Интернет» по доменному имени и сетевому адресу:
            https://tgparsing.ru
          </p>
          <p>Стороны Договора (Стороны) – Исполнитель и Заказчик.</p>
          <p>
            Услуга – услуга, оказываемая Исполнителем Заказчику в порядке и на
            условиях, установленных настоящей Офертой.
          </p>
        </li>
        <li>
          <h3>2. Предмет Договора</h3>

          <p>
            2.1. Исполнитель обязуется оказать Заказчику Услуги, а Заказчик
            обязуется оплатить их в размере, порядке и сроки, установленные
            настоящим Договором.
          </p>
          <p>
            2.2. Наименование, количество, порядок и иные условия оказания Услуг
            определяются на основании сведений Исполнителя при оформлении заявки
            Заказчиком, либо устанавливаются на сайте Исполнителя в сети
            «Интернет» https://tgparsing.ru.
          </p>
          <p>
            2.3. Исполнитель оказывает Услуги по настоящему Договору лично, либо
            с привлечением третьих лиц, при этом за действия третьих лиц
            Исполнитель отвечает перед Заказчиком как за свои собственные.
          </p>
          <p>
            2.4. Договор заключается путем акцепта настоящей Оферты через
            совершение конклюдентных действий, выраженных в:
          </p>
          <p>
            действиях, связанных с регистрацией учетной записи на Сайте
            Исполнителя в сети «Интернет» при наличии необходимости регистрации
            учетной записи;
          </p>
          <p>
            оформлении и направлении Заказчиком заявки в адрес Исполнителя для
            оказания Услуг;
          </p>
          <p>действиях, связанных с оплатой Услуг Заказчиком;</p>
          <p>
            действиях, связанных с оказанием Услуг Исполнителем.твиях, связанных
            с оплатой Услуг Заказчиком;
          </p>
          <p>
            Данный перечень не исчерпывающий, могут быть и другие действия,
            которые ясно выражают намерение лица принять предложение
            контрагента.
          </p>
        </li>
        <li>
          <h3>3. Права и обязанности сторон</h3>

          <p>3.1. Права и обязанности Исполнителя:</p>
          <p>
            3.1.1. Исполнитель обязуется оказать Услуги в соответствии с
            положениями настоящего Договора, в сроки и объеме, указанные в
            настоящем Договоре и (или) в порядке, указанном на Сайте
            Исполнителя.
          </p>
          <p>
            3.1.2. Исполнитель обязуется предоставлять Заказчику доступ к
            разделам Сайта, необходимым для получения информации, согласно
            пункту 2.1. Договора.
          </p>
          <p>
            3.1.3. Исполнитель несет ответственность за хранение и обработку
            персональных данных Заказчика, обеспечивает сохранение
            конфиденциальности этих данных и использует их исключительно для
            качественного оказания Услуг Заказчику.
          </p>
          <p>
            3.1.4. Исполнитель оставляет за собой право изменять сроки (период)
            оказания Услуг и условия настоящей Оферты в одностороннем порядке
            без пред
          </p>
          <p>
            При этом новые /измененные условия, указываемые на Сайте , действуют
            только в отношении вновь заключаемых Договоров.
          </p>
          <p>3.2. Права и обязанности Заказчика:</p>
          <p>
            3.2.1. Заказчик обязан предоставлять достоверную информацию о себе
            при получении соответствующих Услуг.
          </p>
          <p>
            3.2.2. Заказчик обязуется не воспроизводить, не повторять, не
            копировать, не продавать, а также не использовать в каких бы то ни
            было целях информацию и материалы, ставшие ему доступными в связи с
            оказанием Услуг, за исключением личного использования
            непосредственно самим Заказчиком без предоставления в какой-либо
            форме доступа каким-либо третьим лицам.
          </p>
          <p>
            3.2.3. Заказчик обязуется принять Услуги, оказанные Исполнителем.
          </p>
          <p>
            3.2.4. Заказчик гарантирует, что все условия Договора ему понятны;
            Заказчик принимает условия без оговорок, а также в полном объеме.
          </p>
        </li>
        <li>
          <h3>4. Цена и порядок расчетов</h3>

          <p>
            4.1. Стоимость услуг Исполнителя, оказываемых Заказчиком и порядок
            их оплаты, определяются на основании сведений Исполнителя при
            оформлении заявки Заказчиком либо устанавливаются на Сайте
            Исполнителя в сети «Интернет»: https://tgparsing.ru.
          </p>
          <p>
            4.2. Все расчеты по Договору производятся в безналичном порядке.
          </p>
        </li>
        <li>
          <h3>5. Конфиденциальность и безопасность</h3>

          <p>
            5.1. При реализации настоящего Договора Стороны обеспечивают
            конфиденциальность и безопасность персональных данных в соответствии
            с актуальной редакцией ФЗ от 27.07.2006 г. №152-ФЗ «О персональных
            данных» и ФЗ от 27.07.2006 г. № 149-ФЗ «Об информации,
            информационных технологиях и о защите информации».
          </p>
          <p>
            5.2. Стороны обязуются сохранять конфиденциальность информации,
            полученной в ходе исполнения настоящего Договора, и принять все
            возможные меры, чтобы предохранить полученную информацию от
            разглашения.
          </p>
          <p>
            5.3. Под конфиденциальной информацией понимается любая информация,
            передаваемая Исполнителем и Заказчиком в процессе реализации
            Договора и подлежащая защите, исключения указаны ниже.
          </p>
          <p>
            5.4. Такая информация может содержаться в предоставляемых
            Исполнителю локальных нормативных актах, договорах, письмах,
            отчетах, аналитических материалах, результатах исследований, схемах,
            графиках, спецификациях и других документах, оформленных как на
            бумажных, так и на электронных носителях.
          </p>
        </li>
        <li>
          <h3>Форс-мажор</h3>

          <p>
            6.1. Стороны освобождаются от ответственности за неисполнение или
            ненадлежащее исполнение обязательств по Договору, если надлежащее
            исполнение оказалось невозможным вследствие непреодолимой силы, то
            есть чрезвычайных и непредотвратимых при данных условиях
            обстоятельств, под которыми понимаются: запретные действия властей,
            эпидемии, блокада, эмбарго, землетрясения, наводнения, пожары или
            другие стихийные бедствия.
          </p>

          <p>
            6.2. Документ, выданный уполномоченным государственным органом,
            является достаточным подтверждением наличия и продолжительности
            действия непреодолимой силы.
          </p>
          <p>
            6.4. Если обстоятельства непреодолимой силы продолжают действовать
            более 60 (Шестидесяти) рабочих дней, то каждая Сторона вправе
            отказаться от настоящего Договора в одностороннем порядке.
          </p>
        </li>
        <li>
          <h3>7. Ответственность Сторон</h3>

          <p>
            7.1. В случае неисполнения и/или ненадлежащего исполнения своих
            обязательств по Договору, Стороны несут ответственность в
            соответствии с условиями настоящей Оферты.
          </p>
          <p>
            7.2. Исполнитель не несет ответственности за неисполнение и/или
            ненадлежащее исполнение обязательств по Договору, если такое
            неисполнение и/или ненадлежащее исполнение произошло по вине
            Заказчика.
          </p>
          <p>
            7.3. Сторона, не исполнившая или ненадлежащим образом исполнившая
            обязательства по Договору, обязана возместить другой Стороне
            причиненные такими нарушениями убытки.
          </p>
        </li>
        <li>
          <h3>8. Срок действия настоящей Оферты</h3>

          <p>
            8.1. Оферта вступает в силу с момента размещения на Сайте
            Исполнителя и действует до момента её отзыва Исполнителем.
          </p>
          <p>
            8.2. Исполнитель оставляет за собой право внести изменения в условия
            Оферты и/или отозвать Оферту в любой момент по своему усмотрению.
            Сведения об изменении или отзыве Оферты доводятся до Заказчика по
            выбору Исполнителя посредством размещения на сайте Исполнителя в
            сети «Интернет», в Личном кабинете Заказчика, либо путем направления
            соответствующего уведомления на электронный или почтовый адрес,
            указанный Заказчиком при заключении Договора или в ходе его
            исполнения.
          </p>
          <p>
            8.3. Договор вступает в силу с момента Акцепта условий Оферты
            Заказчиком и действует до полного исполнения Сторонами обязательств
            по Договору.
          </p>
          <p>
            8.4. Изменения, внесенные Исполнителем в Договор и опубликованные на
            сайте в форме актуализированной Оферты, считаются принятыми
            Заказчиком в полном объеме.
          </p>
        </li>
        <li>
          <h3>9. Дополнительные условия</h3>

          <p>
            9.1. Договор, его заключение и исполнение регулируется действующим
            законодательством Российской Федерации. Все вопросы, не
            урегулированные настоящей Офертой или урегулированные не полностью,
            регулируются в соответствии с материальным правом Российской
            Федерации.
          </p>
          <p>
            9.2. В случае возникновения спора, который может возникнуть между
            Сторонами в ходе исполнения ими своих обязательств по Договору,
            заключенному на условиях настоящей Оферты, Стороны обязаны
            урегулировать спор мирным путем до начала судебного разбирательства.
          </p>
          <p>
            Судебное разбирательство осуществляется в соответствии с
            законодательством Российской Федерации.
          </p>
          <p>
            Споры или разногласия, по которым Стороны не достигли
            договоренности, подлежат разрешению в соответствии с
            законодательством РФ. Досудебный порядок урегулирования спора
            является обязательным.
          </p>
          <p>
            9.3. В качестве языка Договора, заключаемого на условиях настоящей
            Оферты, а также языка, используемого при любом взаимодействии Сторон
            (включая ведение переписки, предоставление требований / уведомлений
            / разъяснений, предоставление документов и т. д.), Стороны
            определили русский язык.
          </p>
          <p>
            9.4. Все документы, подлежащие предоставлению в соответствии с
            условиями настоящей Оферты, должны быть составлены на русском языке
            либо иметь перевод на русский язык, удостоверенный в установленном
            порядке.
          </p>
          <p>
            9.5. Бездействие одной из Сторон в случае нарушения условий
            настоящей Оферты не лишает права заинтересованной Стороны
            осуществлять защиту своих интересов позднее, а также не означает
            отказа от своих прав в случае совершения одной из Сторон подобных
            либо сходных нарушений в будущем.
          </p>
          <p>
            9.6. Если на Сайте Исполнителя в сети «Интернет» есть ссылки на
            другие вебсайты и материалы третьих лиц, такие ссылки размещены
            исключительно в целях информирования, и Исполнитель не имеет
            контроля в отношении содержания таких сайтов или материалов.
            Исполнитель не несет ответственность за любые убытки или ущерб,
            которые могут возникнуть в результате использования таких ссылок.
          </p>
        </li>
        <li>
          <h3>10. Реквизиты Исполнителя</h3>
          <p>Исполнитель:</p>
          <p className={styles.contract__essentials}>
            Полное наименование: Петрова Ольга Геннадиевна ИНН: 774300164970
            ОГРН/ОГРНИП: 322774600613065
          </p>
        </li>
      </ul>
    </div>
  );
};

export default ContractPage;
