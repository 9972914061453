import React from 'react';
import styles from './styles.module.sass';
import AdminInviting from '../../../components/Admin/AdminInviting/AdminInviting';

export default function InvitingAdminPage() {
  return (
    <div className={styles.wrapper}>
      <AdminInviting />
    </div>
  );
}
