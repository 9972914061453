import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface ModalCenteredProps {
  headerText: string;
  bodyText: string;
  btnText: string;
  onHide: () => void;
  show: boolean;
}

export default function ModalCentered(props: ModalCenteredProps) {
  const { headerText, bodyText, btnText } = props;

  return (
    <Modal {...props}>
      <ModalHeader
        style={{
          backgroundColor: '#2B3243',
          border: 'none',
          borderRadius: '5px 5px 0 0',
        }}
      >
        <ModalTitle>{headerText}</ModalTitle>
      </ModalHeader>
      <ModalBody style={{ backgroundColor: '#2B3243' }}>{bodyText}</ModalBody>
      <ModalFooter
        style={{
          backgroundColor: '#2B3243',
          border: 'none',
          borderRadius: '0 0 5px 5px',
        }}
      >
        <Button onClick={props.onHide} variant="primary">
          {btnText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
