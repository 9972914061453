import { useEffect } from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import { ParserTask } from '../../../types/parserTask';
import styles from './parsers-layout.module.sass';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchTasks } from '../../../store/task-slice/apiActions';
import CompletedTasks from '../../CompletedTasks/CompletedTasks';
import ProgressItem from '../../ui/ProgressItem/ProgressItem';
import InfoBanner from '../../ui/infoBanner/infoBanner';

const PARSERS_ITEMS = [
  {
    text: 'Участники',
    link: Routes.ParsersParticipants,
    name: 'members',
  },
  {
    text: 'Активность',
    link: Routes.ParsersActivities,
    name: 'activity',
  },
  {
    text: 'Геолокация',
    link: Routes.ParsersGeolocation,
    name: 'geo',
  },
] as const;

const ParsersLayout = () => {
  const location = useLocation();
  const subscribe = useAppSelector((state) => state.UserData.user?.subscribe);
  const dispatch = useAppDispatch();
  const tasks = useAppSelector((state) => state.Task.tasks);
  const isLoadingTasks = useAppSelector((state) => state.Task.isLoading);

  const refreshTasks = () => {
    const limit = 10;
    const offset = 0;
    dispatch(fetchTasks({ limit, offset, operation: 'parsing' }));
  };

  useEffect(() => {
    refreshTasks();
  }, []);

  const currentPageIndex = PARSERS_ITEMS.findIndex(
    (item) => item.link === location.pathname,
  );

  if (location.pathname === Routes.Parsers)
    return <Navigate to={Routes.ParsersParticipants} />;

  return (
    <section className={styles.pageWrapper}>
      <div className={styles.tabs}>
        <ul className={`nav nav-tabs ${styles.navTabs}`}>
          {PARSERS_ITEMS.map((item, index) => (
            <li className="nav-item" key={item.text}>
              <Link
                className={`nav-link ${styles.link} ${
                  index === currentPageIndex
                    ? `active ${styles.linkActive}`
                    : ''
                }`}
                aria-current="page"
                to={item.link}
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.info}>
        <ProgressItem content="parsing" style={{ width: '39%' }} />
        <InfoBanner
          text="Внимание! Парсинг участников каналов и активностей чатов собирается со скоростью 1-2 пользователя в 1 сек. Поэтому парсинг с большим количеством пользователей может занять продолжительное время."
          context="tech"
          style={{ width: '54%', paddingRight: '54px', paddingLeft: '40px' }}
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.parsersWrapper}>
          {/* <h3 className={styles.header}>Поиск аудитории</h3> */}
          {!subscribe?.tariff_options[PARSERS_ITEMS[currentPageIndex].name] && (
            <div className={styles.headerWrapper}>
              <p className={styles.notAllowedParser}>
                (Недоступно для вашего тарифа)
              </p>
            </div>
          )}
          <Outlet
            context={
              !subscribe?.tariff_options[PARSERS_ITEMS[currentPageIndex].name]
            }
          />
        </div>
        <CompletedTasks
          tasks={tasks}
          isLoading={isLoadingTasks}
          refreshTasks={refreshTasks}
          operation="parsing"
        />
      </div>
    </section>
  );
};
export default ParsersLayout;
