import React, { useEffect, useState } from 'react';
import styles from './tarif.module.sass';
import { TariffResponse } from '../../../types/tariff';
import { ParsersTitles } from '../../../consts/tariffs';
import { DAY_ENDINGS, getWordEnding } from '../../../utils/getWordEnding';
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check-mark-icon.svg';
import { FC } from 'react';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';
import { api } from '../../../services/api';
import { CURRENT_DATE_STRING } from '../../../consts/consts';

interface TariffItemProps {
  tariff: TariffResponse;
  buttonHandler: () => void;
  isSubmitting?: boolean;
  isCurrentTariff: boolean;
}

interface MessagePrice {
  message_price: number;
  photo_price: number;
  invite_price: number;
}

const Tarif: FC<TariffItemProps> = ({
  tariff,
  buttonHandler,
  isSubmitting = false,
  isCurrentTariff = false,
}) => {
  const [messagePrice, setMessagePrice] = useState<MessagePrice>();
  const [userData, setUserData] = useState<string>();
  const [workTariff, setWorkTariff] = useState(true);

  useEffect(() => {
    api.get('/user/me').then((response) => {
      const res = response.data.subscribe?.end_date;
      const endDateString = res?.toString();
      setUserData(endDateString);
    });
  }, []);

  useEffect(() => {
    if (userData) {
      setWorkTariff(userData >= CURRENT_DATE_STRING);
    }
  }, [userData]);

  useEffect(() => {
    getMessagePrice();
  }, []);
  const getMessagePrice = () => {
    api
      .get('/tariff/message_price')
      .then((response) => setMessagePrice(response.data));
  };
  return (
    <div
      className={`${styles.tarifs} ${
        isCurrentTariff && workTariff ? styles.greenBorder : ''
      }`}
    >
      <div className={styles.block__wrapper}>
        <span className={styles.header}>{tariff.name}</span>
      </div>
      <div className={styles.block__wrapper}>
        <span className={styles.subTitle}>Включает:</span>
        <ul className={styles.list}>
          <li>Одновременный парсинг: {tariff.options.simultaneous_parsing}</li>
          <li>Количество парсингов в день: {tariff.options.parsers_per_day}</li>
        </ul>
      </div>
      <div className={`${styles.block__wrapper} ${styles.block__hardSize}`}>
        <span className={styles.subTitle}>Способы сбора аудитории:</span>
        <ul className={styles.list}>
          {tariff.options.members && <li>{ParsersTitles.members[0]}</li>}
          {tariff.options.activity && <li>{ParsersTitles.activemembers[0]}</li>}
          {tariff.options.geo && <li>{ParsersTitles.geomembers[0]}</li>}
        </ul>
      </div>
      <div className={styles.block__wrapper}>
        <span className={styles.subTitle}>Таргетинг</span>
        <ul className={styles.list}>
          <li>
            Количество сообщений в день: {tariff.options.count_of_messages}
          </li>
          <li>
            Стоимость сообщения: {messagePrice?.message_price}{' '}
            <span>&#8381;</span>
          </li>
          <li>
            Стоимость изображения: {messagePrice?.photo_price}{' '}
            <span>&#8381;</span>
          </li>
        </ul>
      </div>
      <div className={styles.block__wrapper}>
        <span className={styles.subTitle}>Инвайтинг</span>
        <ul className={styles.list}>
          <li>
            Количество инвайтов в день: {tariff.options.invitings_per_day}
          </li>
          <li>
            Стоимость 1го инвайта: {messagePrice?.invite_price}{' '}
            <span>&#8381;</span>
          </li>
        </ul>
      </div>
      <div className={styles.block__wrapper}>
        <span className={styles.subTitle}>Срок действия:</span>
        <ul className={styles.list}>
          <li>
            {tariff.limitation_days}{' '}
            {getWordEnding(tariff.limitation_days, DAY_ENDINGS)}
          </li>
        </ul>
      </div>
      <div className={styles.block__wrapper}>
        <span className={styles.subTitle}>Стоимость:</span>
        <ul className={styles.list}>
          <li>{tariff.price} рублей</li>
        </ul>
      </div>
      <div className={styles.block__wrapper}>
        <div className={styles.buttonWrapper}>
          {isCurrentTariff && workTariff ? (
            <div className={styles.checkIcon_wrapper}>
              <CheckIcon width={32} height={27} />
            </div>
          ) : (
            <Btn_transparent
              variant="fat"
              onClick={buttonHandler}
              disabled={isSubmitting}
            >
              Оплатить тариф
            </Btn_transparent>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tarif;
