import React, { useRef, useState, MouseEvent, TouchEvent } from 'react';
import styles from './review.module.sass';

export default function Review() {
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const wrapperRef = useRef(null);

  const handleMouseDown = (e: MouseEvent) => {
    setIsDragging(true);
    setDragStartX(e.clientX - offsetX);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      const newOffsetX = e.clientX - dragStartX;
      setOffsetX(newOffsetX);
    }
  };

  const handleTouchStart = (e: TouchEvent) => {
    setIsDragging(true);
    setDragStartX(e.touches[0].clientX - offsetX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (isDragging) {
      const newOffsetX = e.touches[0].clientX - dragStartX;
      setOffsetX(newOffsetX);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <div className={styles.review__wrapper}>
      <div className={styles.review__header}>
        <span>Что говорят наши клиенты</span>
      </div>
      <div
        className={styles.review__containeer}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        ref={wrapperRef}
        style={{ transform: `translateX(${offsetX}px)` }}
      >
        <div className={styles.review__block}>
          <span className={styles.review__block_header}>Арсений П.</span>
          <span className={styles.review__block_position}>
            Врач-психиатр, частная практика
          </span>
          <span className={styles.review__block_text}>
            Моих клиентов, интересующихся услугами психиатра, очень много в
            Телеграм. Я впервые смог их найти в тематических каналах, а потом
            отфильтровать по ГЕО местных. Это был самый быстрый способ поиска
            клиентов и рекламы на них в интернете, а по деньгам удалось сильно
            сэкономить — в ВК и Яндексе получалось сильно дороже.
          </span>
        </div>
        <div className={styles.review__block}>
          <span className={styles.review__block_header}>Наталья Б.</span>
          <span className={styles.review__block_position}>
            Владелец бизнеса
          </span>
          <span className={styles.review__block_text}>
            Поначалу показалось все очень сложным, но после посещения курса
            работе в сервисе смогла самостоятельно найти в парсере своих
            клиентов и настроить на них рекламу. Мы продаём стройматериалы и у
            нас поднялись продажи после рекламы в ТГ. По расходам — это
            оказалась самая выгодная реклама.
          </span>
        </div>
        <div className={styles.review__block}>
          <span className={styles.review__block_header}>Владимир Б.</span>
          <span className={styles.review__block_position}>
            Владелец пиццерии
          </span>
          <span className={styles.review__block_text}>
            У меня своя пиццерия и я давно хотел запустить рекламу в Телеграм
            местных жителей, но не знал как. Теперь могу собрать всех местных по
            ГЕО точкам и отправить им свою рекламу в пару кликов. Народа в
            пиццерии прибавилось.
          </span>
        </div>
        <div className={styles.review__block}>
          <span className={styles.review__block_header}>Ирина В.</span>
          <span className={styles.review__block_position}>Мастер маникюра</span>
          <span className={styles.review__block_text}>
            С технической поддержкой быстро разобралась где мне искать моих
            клиентов, и я сделала свою первую рекламу в telegram. Теперь жду
            клиентов
          </span>
        </div>
        <div className={styles.review__block}>
          <span className={styles.review__block_header}>Антон П.</span>
          <span className={styles.review__block_position}>
            Организатор праздников
          </span>
          <span className={styles.review__block_text}>
            Очень удобно отправлять поздравления всем моим друзьям в телеге. За
            пару минут отправил поздравление с Новым годом на 510 своей
            телефонной книжки. Молчу сколько времени уходило на это раньше.
          </span>
        </div>
      </div>
    </div>
  );
}
