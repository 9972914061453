import { FC, useState, useEffect, useMemo } from 'react';
import { Bot } from '../../../types/bot';
import Table from '../../ui/table/Table';
import TableBody from '../../ui/table/tableBody/TableBody';
import TableCell from '../../ui/table/tableCell/TableCell';
import TableContainer from '../../ui/table/tableContainer/TableContainer';
import TableHead from '../../ui/table/tableHead/TableHead';
import TableRow from '../../ui/table/tableRow/TableRow';
import styles from './admin-bots.module.sass';
import IconButton from '../../ui/iconButton/IconButton';
import { ReactComponent as BasketIcon } from '../../../assets/images/icons/trash.svg';
import { api } from '../../../services/api';
import TableFilter from '../../ui/table/tableFilter/TableFilter';
import AdminBotCard from '../AdminBotCard/AdminBotCard';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import { ReactComponent as RepeatIcon } from '../../../assets/images/icons/repeat.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/images/icons/settings.svg';
import Tooltip from '../../ui/tooltip/tooltip';
import Loader from '../../ui/loader/loader';

interface AdminBotsProps {
  bots: Bot[];
}

interface LoadingStates {
  [key: number]: string | boolean;
}

const AdminBots: FC<AdminBotsProps> = ({ bots }) => {
  const [filterIsActive, setFilterActive] = useState(false);
  const [updatedBots, setUpdatedBots] = useState(bots);
  const [selectedBot, setSelectedBot] = useState<Bot | null>(null);
  const [selectedBlockStatus, setSelectedBlockStatus] = useState('');
  const [selectedWorkType, setSelectedWorkType] = useState('');
  const [selectedWorkStatus, setSelectedWorkStatus] = useState('');
  const [filteredRowCount, setFilteredRowCount] = useState(0);
  const [loader, setLoader] = useState<LoadingStates>({});
  const [refreshStatus, setRefreshStatus] = useState<LoadingStates>({});

  const navigate = useNavigate();

  const loaderMemo = useMemo(() => loader, [loader]);

  const updateBotParameter = (
    botId: number,
    parameter: string,
    value: string,
  ) => {
    api
      .patch(`telegram/tgaccount/${botId}`, {
        [parameter]: value,
      })
      .then(() => {
        const botIndex = updatedBots.findIndex((bot) => bot.id === botId);
        const updatedBot = { ...updatedBots[botIndex], [parameter]: value };
        updatedBots[botIndex] = updatedBot;
        setUpdatedBots([...updatedBots]);
      });
  };

  const deleteBotHandler = (botId: number) => {
    api
      .delete(`telegram/tgaccount/${botId}`)
      .catch((error) => console.error(error));
  };

  const refreshBotTg = (botId: number) => {
    setLoader((prevLoadingStates) => ({
      ...prevLoadingStates,
      [botId]: true,
    }));
    api
      .get(`/telegram/tgaccount/${botId}?update_tg=true`)
      .then((response) => {
        setLoader((prevLoadingStates) => ({
          ...prevLoadingStates,
          [botId]: false,
        }));

        setRefreshStatus((prevStatus) => ({
          ...prevStatus,
          [botId]: response.status === 200 ? 'Ok' : 'NG',
        }));
      })
      .catch(() => {
        setLoader((prevLoadingStates) => ({
          ...prevLoadingStates,
          [botId]: false,
        }));
        setRefreshStatus((prevStatus) => ({
          ...prevStatus,
          [botId]: 'NG',
        }));
      });
  };

  useEffect(() => {
    const filteredBots = updatedBots.filter(
      (bot) =>
        (selectedBlockStatus
          ? bot.block_status === selectedBlockStatus
          : true) && (selectedWorkType ? bot.type === selectedWorkType : true),
    );
    setFilteredRowCount(filteredBots.length);
  }, [selectedBlockStatus, selectedWorkType, updatedBots]);

  return (
    <TableContainer style={{ height: '70vh' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head" className={styles.headCell}>
              ID
            </TableCell>
            <TableCell variant="head" className={styles.headCell}>
              avatar
            </TableCell>
            <TableCell variant="head" className={styles.headCell}>
              Имя Фамилия
            </TableCell>
            <TableCell variant="head" className={styles.headCell}>
              Телефон
            </TableCell>
            <TableCell variant="head" className={styles.headCell}>
              <TableFilter
                title="work status"
                isActive={filterIsActive}
                setActive={setFilterActive}
              >
                <select
                  className={styles.selectBot}
                  value={selectedWorkStatus}
                  onChange={(event) =>
                    setSelectedWorkStatus(event.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="work">work</option>
                  <option value="free">free</option>
                </select>
              </TableFilter>
            </TableCell>
            <TableCell variant="head" className={styles.headCell}>
              <TableFilter
                title="work type"
                isActive={filterIsActive}
                setActive={setFilterActive}
              >
                <select
                  className={styles.selectBot}
                  value={selectedWorkType}
                  onChange={(event) => setSelectedWorkType(event.target.value)}
                >
                  <option value="">All</option>
                  <option value="parsing">Парсинг</option>
                  <option value="mailing">Рассылка</option>
                </select>
              </TableFilter>
            </TableCell>
            <TableCell variant="head" className={styles.headCell}>
              <TableFilter
                title="block status"
                isActive={filterIsActive}
                setActive={setFilterActive}
              >
                <select
                  value={selectedBlockStatus}
                  className={styles.selectBot}
                  onChange={(event) =>
                    setSelectedBlockStatus(event.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="unblock">Unblock</option>
                  <option value="block">Block</option>
                </select>
              </TableFilter>
            </TableCell>
            <TableCell variant="head" className={styles.headCell}>
              Кол-во: {filteredRowCount}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {updatedBots
            .filter(
              (bot) =>
                (selectedBlockStatus
                  ? bot.block_status === selectedBlockStatus
                  : true) &&
                (selectedWorkType ? bot.type === selectedWorkType : true) &&
                (selectedWorkStatus
                  ? bot.work_status === selectedWorkStatus
                  : true),
            )
            .map((bot) => (
              <TableRow key={bot.id}>
                <TableCell>{bot.id}</TableCell>
                <TableCell>
                  <img src={bot.avatar} className={styles.botAvatar}></img>
                </TableCell>
                <TableCell>
                  {bot.first_name} {bot.last_name}
                </TableCell>
                <TableCell>{bot.phone_number}</TableCell>
                <TableCell>
                  <select
                    className={styles.selectBotType}
                    value={bot.work_status}
                    onChange={(event) =>
                      updateBotParameter(
                        bot.id,
                        'work_status',
                        event.target.value,
                      )
                    }
                  >
                    <option value="work">work</option>
                    <option value="free">free</option>
                  </select>
                </TableCell>
                <TableCell>
                  <select
                    className={styles.selectBotType}
                    value={bot.type}
                    onChange={(event) =>
                      updateBotParameter(bot.id, 'type', event.target.value)
                    }
                  >
                    <option value="parsing">парсинг</option>
                    <option value="mailing">рассылка</option>
                  </select>
                </TableCell>
                <TableCell>
                  <select
                    className={styles.selectBotType}
                    value={bot.block_status}
                    onChange={(event) =>
                      updateBotParameter(
                        bot.id,
                        'block_status',
                        event.target.value,
                      )
                    }
                  >
                    <option value="block">block</option>
                    <option value="unblock">unblock</option>
                  </select>
                </TableCell>
                <TableCell>
                  <div className={styles.cellSetting}>
                    <IconButton onClick={() => refreshBotTg(bot.id)}>
                      <>
                        <Tooltip text="Обновить данные из ТГ" direction="left">
                          {loaderMemo[bot.id] ? (
                            <Loader width={24} height={24} />
                          ) : (
                            <RepeatIcon />
                          )}
                        </Tooltip>
                        <span className="visually-hidden">
                          Обновить данные из ТГ
                        </span>
                      </>
                    </IconButton>
                    <IconButton
                      onClick={() => navigate(`${Routes.AdminBots}/${bot.id}`)}
                    >
                      <>
                        <Tooltip text="Настройки бота" direction="left">
                          <SettingsIcon />
                        </Tooltip>
                        <span className="visually-hidden">Настройки</span>
                      </>
                    </IconButton>
                    <IconButton onClick={() => deleteBotHandler(bot.id)}>
                      <>
                        <Tooltip text="Удалить бота" direction="left">
                          <BasketIcon />
                        </Tooltip>
                        <span className="visually-hidden">Удалить бота</span>
                      </>
                    </IconButton>
                    {refreshStatus[bot.id] && (
                      <span
                        style={{
                          color:
                            refreshStatus[bot.id] === 'Ok' ? 'green' : 'red',
                          fontWeight: 'bold',
                        }}
                      >
                        {refreshStatus[bot.id]}
                      </span>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {selectedBot && (
        <div>
          <AdminBotCard bot={selectedBot} />
          <button onClick={() => setSelectedBot(null)}>Закрыть</button>
        </div>
      )}
    </TableContainer>
  );
};

export default AdminBots;
