import React, { useEffect, useState } from 'react';
import styles from './areachart.module.sass';
import { api } from '../../../services/api';
import Loader from '../../../components/ui/loader/loader';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

interface BotStatProps {
  qty_all_bots: number;
  qty_bots_parsing: number;
  qty_bots_mailing: number;
  qty_bots_hotting: number;
  qty_bots_block: number;
  qty_messages_in_day_all: number;
  qty_messages_in_day_remainder: number;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Боты',
      color: 'white',
    },
  },
  scales: {
    x: {
      ticks: {
        color: 'white',
      },
    },
    y: {
      ticks: {
        color: 'white',
      },
    },
  },
};

export const labels = [
  'Всего',
  'На рассылке',
  'На парсинге',
  'На прогреве',
  'В блоке',
];

export default function BarChartMessageBots() {
  const [dataBotsStat, setDataBotsStat] = useState<BotStatProps>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .get('/telegram/tgaccount/info')
      .then((response) => {
        setDataBotsStat(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, []);

  const data = {
    labels,
    datasets: [
      {
        label: 'Всего',
        data: [
          dataBotsStat?.qty_all_bots,
          dataBotsStat?.qty_bots_mailing,
          dataBotsStat?.qty_bots_parsing,
          dataBotsStat?.qty_bots_hotting,
          dataBotsStat?.qty_bots_block,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(255, 159, 64, 0.5)',
          'rgba(255, 205, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(54, 162, 235, 0.5)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      ) : (
        <div className={styles.barChart}>
          <Bar options={options} data={data} width={'100%'} height={'35%'} />
        </div>
      )}
    </div>
  );
}
