import React from 'react';
import { CSSProperties } from 'react';
import styles from './Btn_transparent.module.sass';

const VARIANTS = ['blue', 'grey', 'standart', 'fat', 'big'] as const;

interface BtnTransparentProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string | JSX.Element;
  variant: (typeof VARIANTS)[number];
  style?: CSSProperties;
}

const Btn_transparent = ({
  children,
  type = 'button',
  onClick,
  variant,
  disabled = false,
  style = {},
  className = '',
  ...rest
}: BtnTransparentProps) => {
  return (
    <button
      type={type}
      style={style}
      disabled={disabled}
      className={`${styles.btn_transparent} ${styles[variant]} ${className}`}
      onClick={onClick}
      {...rest}
    >
      <span className={styles.innerText}>{children}</span>
    </button>
  );
};

export default Btn_transparent;
