import { api } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/ui/loader/loader';
import styles from '../../components/ui/loader/loader.module.sass';

export default function ConfirmPage() {
  const navigate = useNavigate();

  const getCode = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramValue = searchParams.get('code');

    return paramValue;
  };

  const code = getCode();

  const postCode = () => {
    api
      .post('/user/confirm', null, { params: { code } })
      .then((response) => {
        if (response.status === 200) {
          navigate('/login');
        }
      })
      .catch((error) => console.error(error));
  };
  postCode();

  return (
    <div className={styles.loader__wrapper}>
      <Loader />
    </div>
  );
}
