import styles from './documents-page.module.sass';

const DocumentsPage = () => {
  return (
    <main className={styles.wrapper}>
      <a
        className={styles.documents__link}
        href={require('../../assets/pdfs/mailing-instruction.pdf')}
        target="_blank"
      >
        &#8901; Инструкция - "Как сделать свою первую рассылку"
      </a>
      <a
        className={styles.documents__link}
        href={require('../../assets/pdfs/parsing-participants.pdf')}
        target="_blank"
      >
        &#8901; Инструкция - "Как сделать свой первый парсинг по участникам"
      </a>
      <a
        className={styles.documents__link}
        href={require('../../assets/pdfs/parsing-activities.pdf')}
        target="_blank"
      >
        &#8901; Инструкция - "Как сделать свой первый парсинг по активностям"
      </a>
      <a
        className={styles.documents__link}
        href={require('../../assets/pdfs/parsing-geo.pdf')}
        target="_blank"
      >
        &#8901; Инструкция - "Как сделать свой первый парсинг по геолокации"
      </a>
      <a
        className={styles.documents__link}
        href={require('../../assets/pdfs/personal-data-policy.pdf')}
        target="_blank"
      >
        &#8901; Политика конфиденциальности
      </a>
      <a
        className={styles.documents__link}
        href={require('../../assets/pdfs/public-offer.pdf')}
        target="_blank"
      >
        &#8901; Договор публичной оферты
      </a>
    </main>
  );
};

export default DocumentsPage;
