import { useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import styles from './mailing-layout.module.sass';
import { useAppDispatch } from '../../../hooks/redux';
import { fetchTasks } from '../../../store/task-slice/apiActions';

const MAILING_ITEMS = [
  {
    text: 'Таргетинг',
    link: Routes.Mailing,
    name: 'mailing',
  },
  {
    text: 'Таблица',
    link: Routes.MailingTable,
    name: 'table',
  },
] as const;

const MailingLayout = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();

  const refreshTasks = () => {
    const limit = 10;
    const offset = 0;
    dispatch(fetchTasks({ limit, offset, operation: 'mailing' }));
  };

  useEffect(() => {
    refreshTasks();
  }, []);

  const currentPageIndex = MAILING_ITEMS.findIndex(
    (item) => item.link === location.pathname,
  );

  return (
    <section className={styles.pageWrapper}>
      <div className={styles.tabs}>
        <ul className={`nav nav-tabs ${styles.navTabs}`}>
          {MAILING_ITEMS.map((item, index) => (
            <li className="nav-item" key={item.text}>
              <Link
                className={`nav-link ${styles.link} ${
                  index === currentPageIndex
                    ? `active ${styles.linkActive}`
                    : ''
                }`}
                aria-current="page"
                to={item.link}
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.wrapper}>
        <Outlet context={MAILING_ITEMS[currentPageIndex].name} />
      </div>
    </section>
  );
};
export default MailingLayout;
