import styles from './bots-admin-page.module.sass';
import BarChartMessageBots from '../../../components/ui/chart/BarChartMessageBots';
import BarChartBotsStat from '../../../components/ui/chart/BarChartBotsStat';
import BarChartBotsWork from '../../../components/ui/chart/BarChartBotsWork';

export default function BotsStat() {
  return (
    <div>
      <div className={styles.botsstat__wrapper}>
        <div className={styles.table__wrapper}>
          <BarChartBotsStat />
        </div>
        <div className={styles.table__wrapper}>
          <BarChartMessageBots />
        </div>
      </div>
      <div className={styles.botsstat__wrapper}>
        <div className={styles.table__wrapper}>
          <BarChartBotsWork />
        </div>
      </div>
    </div>
  );
}
