import React from 'react';
import styles from './partners.module.sass';
import Admin_severodvinsk from '../../../assets/images/logo_portners/admin_severodvinsk.png';
import Admin_shelhovsk from '../../../assets/images/logo_portners/admin_shelhovsk.png';
import Amsi_1 from '../../../assets/images/logo_portners/amsi_1.png';
import Dialog_1 from '../../../assets/images/logo_portners/dialog_1.png';
import Express_media_1 from '../../../assets/images/logo_portners/express_media_1.png';
import Fed_media_hold from '../../../assets/images/logo_portners/fed_media_hold.png';
import Gov_moscow from '../../../assets/images/logo_portners/gov_moscow.png';
import Gov_murm from '../../../assets/images/logo_portners/gov_murm.png';
import Hanty_mansiysk from '../../../assets/images/logo_portners/hanty_mansiysk.png';
import Mark_age from '../../../assets/images/logo_portners/mark_age.png';
import Mshp_1 from '../../../assets/images/logo_portners/mshp_1.png';
import Murman_congress_1 from '../../../assets/images/logo_portners/murman_congress_1.png';
import Murman_congress_2 from '../../../assets/images/logo_portners/murman_congress_2.png';
import Nso_1 from '../../../assets/images/logo_portners/nso_1.png';
import Optimedia from '../../../assets/images/logo_portners/optimedia 2 1.png';
import Rapk_1 from '../../../assets/images/logo_portners/rapk_1.png';
import Reg_ag_news from '../../../assets/images/logo_portners/reg_ag_news.png';
import Rg_1 from '../../../assets/images/logo_portners/rg_1.png';
import Ria_voronez from '../../../assets/images/logo_portners/ria_voronez.png';
import Russs_invest from '../../../assets/images/logo_portners/russs_invest.png';
import Severstal from '../../../assets/images/logo_portners/severstal.png';
import Sputnik_1 from '../../../assets/images/logo_portners/sputnik_1.png';
import Sr_Kirovsk from '../../../assets/images/logo_portners/sr_Kirovsk.png';
import Videoconsalt_1 from '../../../assets/images/logo_portners/videoconsalt_1.png';
import Yagok_1 from '../../../assets/images/logo_portners/yagok_1.png';

export default function Partners() {
  const partnersLogos = [
    Gov_moscow,
    Gov_murm,
    Hanty_mansiysk,
    Murman_congress_2,
    Admin_severodvinsk,
    Admin_shelhovsk,
    Severstal,
    Murman_congress_1,
    Reg_ag_news,
    Ria_voronez,
    Express_media_1,
    Videoconsalt_1,
    Russs_invest,
    Fed_media_hold,
    Optimedia,
    Rg_1,
    Yagok_1,
    Amsi_1,
    Sr_Kirovsk,
    Rapk_1,
    Dialog_1,
    Nso_1,
    Mark_age,
    Mshp_1,
    Sputnik_1,
  ];

  return (
    <div className={styles.partners__wrapper}>
      <div className={styles.partners__header}>
        <span>Наши партнеры</span>
      </div>
      <div className={styles.partners_content}>
        {partnersLogos.map((logo, index) => (
          <div key={index} className={styles.partners__logo}>
            <img src={logo} alt="logo" className={styles.img} />
          </div>
        ))}
      </div>
    </div>
  );
}
