import styles from './mailing-player.module.sass';

const MailingYoutubePlayer = ({ videoID }: any) => {
  return (
    <div className="maililngPlayer">
      <iframe
        width="100%"
        height="315"
        src={`https://www.youtube.com/embed/${videoID}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default MailingYoutubePlayer;
