import { useNavigate } from 'react-router-dom';
import styles from './recovery-alert.module.sass';
import Button from '../ui/button/Button';
import Logo from '../ui/logo/Logo';

const RecoveryAlert = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.recoveryAlertWrapper}>
      <Logo />
      <div className={styles.recoveryForm}>
        <h2 className={styles.title}>Сброс пароля</h2>
        <p>
          На Вашу почту отправлена ссылка для сброса пароля, перейдите по ней
          для создания нового пароля.
        </p>
        <Button
          type="submit"
          variant={'accent'}
          onClick={() => navigate('/login')}
        >
          Подтвердить
        </Button>
        <button className={styles.returnLink} onClick={() => navigate(-1)}>
          Назад
        </button>
      </div>
    </div>
  );
};
export default RecoveryAlert;
