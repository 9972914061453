import React, { useEffect, useState } from 'react';
import { ParserTask } from '../../../types/parserTask';
import { api } from '../../../services/api';
import Loader from '../loader/loader';
import style from './areachart.module.sass';
import { Roles } from '../../../consts/consts';
import { useAppSelector } from '../../../hooks/redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ScriptableContext,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// interface TasksProps {
//   tasks: ParserTask[];
// }

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: 'white',
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: true,
        color: '#8586A5',
        lineWidth: 0.25,
      },
      ticks: {
        color: 'white',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Напарсенные пользователи',
      color: 'white',
      font: {
        size: 16,
      },
      padding: {
        bottom: 40,
        top: 10,
      },
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
  },
};

export default function AreaChartParsers() {
  const [dataTasks, setDataTasks] = useState<ParserTask[]>();
  const [isLoading, setIsLoading] = useState(false);

  const role = useAppSelector((state) => state.UserData.user?.role.name);

  useEffect(() => {
    setIsLoading(true);
    if (role === Roles.Admin || role === Roles.SuperUser) {
      api
        .get('/telegram/tasks/')
        .then((response) => setDataTasks(response.data))
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    } else {
      api
        .get('/telegram/tasks/me')
        .then((response) => setDataTasks(response.data))
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 6);

  const allDates = [];
  const tempDate = new Date(oneWeekAgo);
  while (tempDate <= currentDate) {
    allDates.push(new Date(tempDate));
    tempDate.setDate(tempDate.getDate() + 1);
  }

  const sumsByDate: Record<string, number> = {};

  if (dataTasks) {
    dataTasks
      .filter((item: ParserTask) => item.operation === 'parsing')
      .forEach((item: ParserTask) => {
        const date = item.created_at.split('T')[0];
        const dataCount = item.data_count;
        if (sumsByDate[date]) {
          sumsByDate[date] += dataCount;
        } else {
          sumsByDate[date] = dataCount;
        }
      });
  }

  const sortedDates = allDates.map((date) => {
    const formattedDate = date.toISOString().split('T')[0];
    return sumsByDate[formattedDate] || 0;
  });

  const formattedSortedDates = allDates.map((date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${day}-${month}`;
  });

  const data = {
    labels: formattedSortedDates,
    datasets: [
      {
        fill: true,
        data: sortedDates,
        pointRadius: 2,
        borderWidth: 2,
        borderColor: '#8586A5',
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 100, 0, 400);
          gradient.addColorStop(0, 'rgba(133, 134, 165, 1)');
          gradient.addColorStop(1, 'rgba(133, 134, 165, 0)');
          return gradient;
        },
      },
    ],
  };

  return (
    <div className={style.graphWrapper}>
      {isLoading ? (
        <div className={style.loader}>
          <Loader />
        </div>
      ) : dataTasks?.length === 0 ? (
        <p className={style.textNoData}>
          Здесь будет график с данными по количеству отправленных сообщений за
          последние 7 дней
        </p>
      ) : (
        <Line options={options} data={data} width={'100%'} height={'100%'} />
      )}
    </div>
  );
}
