import { ReactNode, useState } from 'react';

import styles from './tooltip.module.sass';

type TooltipProps = {
  text: string;
  direction?: 'left' | 'right';
  style?: object;
  children?: ReactNode;
};

const Tooltip = ({ style, text, direction, children }: TooltipProps) => {
  const [active, setActive] = useState(false);

  const showTip = () => {
    setActive(true);
  };

  const hideTip = () => {
    setActive(false);
  };
  return (
    <div
      className={styles.tooltip}
      style={style}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div className={styles.tooltipWrapper}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 23"
            fill="none"
            className={`${styles.tooltipArrow} ${
              direction === 'left'
                ? styles.leftTooltipArrow
                : styles.rightTooltipArrow
            }`}
          >
            <path d="M1.88617 14.4772C0.0366885 13.0767 0.0366852 10.2971 1.88617 8.89663L10.8067 2.14179C13.1119 0.396187 16.4195 2.04048 16.4195 4.93208L16.4195 18.4418C16.4195 21.3334 13.1119 22.9777 10.8067 21.2321L1.88617 14.4772Z" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 23"
            fill="none"
            className={`${styles.tooltipArrowCover} ${
              direction === 'left'
                ? styles.leftArrowCover
                : styles.rightArrowCover
            }`}
          >
            <path d="M1.88617 14.4772C0.0366885 13.0767 0.0366852 10.2971 1.88617 8.89663L10.8067 2.14179C13.1119 0.396187 16.4195 2.04048 16.4195 4.93208L16.4195 18.4418C16.4195 21.3334 13.1119 22.9777 10.8067 21.2321L1.88617 14.4772Z" />
          </svg>
          <div
            className={`${styles.tooltipTip} ${
              direction === 'left'
                ? styles.leftTooltipTip
                : styles.rightTooltipTip
            } ${
              text.length < 40 ? styles.smallTooltipTip : styles.bigTooltipTip
            }`}
          >
            {text}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
