import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../services/api';
import { ParserTask } from '../../types/parserTask';
import { TasksRoutes } from './apiRoutes';

type FetchTasksParams = {
  limit: number;
  offset: number;
  operation?: string;
};

export const fetchTasks = createAsyncThunk(
  'tasks/fetchTasks',
  async ({ offset, limit, operation }: FetchTasksParams) => {
    let url = `${TasksRoutes.Tasks}?offset=${offset}&limit=${limit}`;
    if (operation) {
      url += `&operation=${operation}`;
    }
    const response = await api.get(url);
    const filteredTasks = response.data.filter(
      (task: ParserTask) =>
        task.work_status === 'failed' ||
        task.work_status === 'success' ||
        task.work_status === 'in_waiting' ||
        task.work_status === 'in_processing',
    );
    return filteredTasks;
  },
);

export const fetchDeletedTasks = createAsyncThunk(
  'tasks/fetchDeletedTasks',
  async () => {
    const response = await api.get('/telegram/tasks/me/trash');
    const deletedTasks = response.data;
    return deletedTasks;
  },
);
