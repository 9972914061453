import React, { useState, useEffect } from 'react';
import styles from './costmailingform.module.sass';
import Btn_transparent from '../../../ui/btn_transparent/Btn_transparent';
import { api } from '../../../../services/api';
import ModalCentered from '../../../ui/modal-window/ModalCentered';

export default function CostMailingForm() {
  const [costMailing, setCostMailing] = useState('');
  const [costImg, setCostImg] = useState('');
  const [costInviting, setCostInviting] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [inputValid, setInputValid] = useState(true);

  const handleInputMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = event.target.value;
    if (/^[0-9.]*$/.test(newValue)) {
      setCostMailing(newValue);
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  };

  const handleInputImgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (/^[0-9.]*$/.test(newValue)) {
      setCostImg(newValue);
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  };

  const handleInputInvitingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = event.target.value;
    if (/^[0-9.]*$/.test(newValue)) {
      setCostInviting(newValue);
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  };

  const fetchData = () => {
    api.get('/tariff/message_price').then((response) => {
      const messagePrice = response.data.message_price || '';
      const imgPrice = response.data.photo_price || '';
      const invitingPrice = response.data.invite_price || '';
      setCostMailing(messagePrice);
      setCostImg(imgPrice);
      setCostInviting(invitingPrice);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = () => {
    api
      .post('/tariff/message_price', {
        message_price: costMailing,
        photo_price: costImg,
        invite_price: costInviting,
      })
      .then(() => {
        fetchData();
        setModalShow(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className={styles.wrapper}>
      <ModalCentered
        show={modalShow}
        headerText="Стоимость рассылки изменена успешно"
        bodyText={`Стоимость отправки 1го сообщения составляет ${costMailing} руб. Стоимость отправки 1й картинки составляет ${costImg} руб.`}
        btnText="Закрыть"
        onHide={() => setModalShow(false)}
      />
      <h2 className={styles.title}>
        Настройка стоимости сообщений для рассылки:
      </h2>
      <div className={styles.costWrapper}>
        <div className={styles.inputWrap}>
          <form className={styles.inputWrapper}>
            <div className={styles.costMessage}>
              <span>сообщение:</span>
              <input
                className={styles.costMailing}
                type="text"
                value={costMailing}
                onChange={handleInputMessageChange}
              ></input>
              <span>руб</span>
            </div>
            <div className={styles.costImg}>
              <span>картинка:</span>
              <input
                className={styles.costMailing}
                type="text"
                value={costImg}
                onChange={handleInputImgChange}
              ></input>
              <span>руб</span>
            </div>
            <div className={styles.costImg}>
              <span>инвайтинг:</span>
              <input
                className={styles.costMailing}
                type="text"
                value={costInviting}
                onChange={handleInputInvitingChange}
              ></input>
              <span>руб</span>
            </div>
          </form>
          {!inputValid && (
            <div className={styles.errorText}>только цифры и точка</div>
          )}
        </div>
        <div className={styles.btnWrapper}>
          <Btn_transparent variant="standart" onClick={handleSubmit}>
            Сохранить
          </Btn_transparent>
        </div>
      </div>
    </div>
  );
}
