import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import MailingForm from './MailingForm/MailingForm';
import styles from './index.module.sass';
import { Mailing } from '../../types/mailing-form';
import { api } from '../../services/api';
import MailingMessage from '../../assets/images/mailing-messag.svg';
import MailingTime from '../../assets/images/mailing-time.svg';
import MailingCost from '../../assets/images/mailingCost.svg';
import InfoTooltip from '../../components/ui/infoTooltip/infoTooltip';
import Loader from '../../components/ui/loader/loader';
import ProgressItem from '../../components/ui/ProgressItem/ProgressItem';
import InfoBanner from '../../components/ui/infoBanner/infoBanner';
import MailingYoutubePlayer from './MailingYoutubePlayer/MailingYoutubePlayer';

const MAILING_BLANK: Mailing = {
  task_name: '',
  users_filename: '',
  text: '',
  photo: '',
  users: [''],
  group: '',
  scheduled_time: dayjs().toISOString(),
};

const MailingPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(MAILING_BLANK);
  const [mailingCount, setMailingCount] = useState('');
  const [mailingTime, setMailingTime] = useState('');
  const [mailingCost, setMailingCost] = useState('');
  const [messagePrice, setMessagePrice] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [isExcessBalance, setExcessBalance] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [imgURL, setImgURL] = useState('');

  const [errors, setErrors] = useState({ sheduled_time: '' });

  const [value, setValue] = useState('');

  function changeRadioValue(e: any) {
    setValue(e.target.value);
  }

  const fileReader = new FileReader();
  fileReader.onloadend = () => {
    if (fileReader.result !== null) {
      setImgURL(fileReader.result as string);
    }
  };

  const handleOnChange = (evt: any) => {
    evt.preventDefault();
    const { target } = evt;
    if (target.type === 'file' && target.id === 'image-button') {
      const file = evt.target.files[0];
      fileReader.readAsDataURL(file);
      setImgURL(file);
    }
  };

  formData.photo = imgURL;

  console.log('я форма: ', formData);

  const sendTelegramMailing = (evt: any) => {
    setIsSubmitting(true);
    evt.preventDefault();
    api
      .post('/telegram/parser/tgmailing', formData)
      .then((response) => {
        if (response.status === 200) {
          // handleShow();
          navigate('/mailing/table');
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 403) {
          setErrorText(error.response.data.detail);
          setIsSubmitting(false);
          // setExcessBalance(true);
          // setTimeout(() => {
          //   setExcessBalance(false);
          // }, 8000);
        }
      });
  };
  // .catch((error) => console.error(error));
  // const sendWhatsAppMailing = () => {
  //   api
  //     .post('/whatsapp/mailing/', formData)
  //     .then((response) => console.log(response))
  //     .catch((error) => console.error(error));
  // };

  // function sendMailing(e: any) {
  //   e.preventDefault();
  //   switch (value) {
  //     case 'telegram':
  //       sendTelegramMailing();
  //       console.log('this: telegram mailing');
  //       break;
  //     case 'whatsapp':
  //       sendWhatsAppMailing();
  //       console.log('this: whatsapp mailing');
  //       break;
  //   }
  // }
  const MAILING_COST = {
    messenger: 'TG',
    users_filename: formData.users_filename,
    photo: formData.photo,
    users: formData.users,
  };

  const sendInfoForCost = (evt: any) => {
    evt.preventDefault();
    api
      .post('/whatsapp/mailing/mailing_information', MAILING_COST)
      .then((response) => {
        setMailingCount(response.data.count_messages);
        setMailingTime(response.data.waiting_time_in_minutes);
        setMailingCost(response.data.cost);
        setIsCalculated(true);
      })
      .catch((error) => console.error(error));
  };

  const getMessagePrice = () => {
    api.get('/tariff/message_price').then((response) => {
      setMessagePrice(response.data.message_price || '');
    });
  };
  useEffect(() => getMessagePrice(), []);

  const validateStepOne = () => {
    return MAILING_COST.photo.length > 0;
  };

  const validateStepTwo = () => {
    return !errors.sheduled_time && formData.scheduled_time.length > 0;
  };

  return (
    <div className={styles.mailing}>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header
          style={{
            backgroundColor: '#2B3243',
            border: 'none',
            borderRadius: '5px 5px 0 0',
          }}
        >
          <Modal.Title>Рассылка запущена</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#2B3243' }}>
          Результаты таргетинга отобразятся в таблице "История задач"
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: '#2B3243',
            border: 'none',
            borderRadius: '0 0 5px 5px',
          }}
        >
          <Button variant="primary" onClick={handleClose}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal> */}
      <InfoBanner
        text="Уважаемые пользователи, из-за массовых блокировок ботов со стороны Telegram таргетированные
        рассылки могут проходить с задержкой. Мы работаем над устранением этих проблем. Надеемся на понимание."
        context="tech"
        style={{ width: '100%', paddingRight: '32px', paddingLeft: '32px' }}
      />
      <div className={styles.info}>
        <ProgressItem content="mailing" />
        <div className={styles.wrapperInsctructions}>
          <h4 className={styles.wrapperInsctructionsTitle}>Дополнительно</h4>
          <a
            href={require('../../assets/pdfs/mailing-instruction.pdf')}
            target="_blank"
            className={styles.wrapperInsctructionsLink}
          >
            Инструкция - Как сделать свой первый таргетинг!
          </a>
        </div>
      </div>
      <MailingYoutubePlayer videoID="B_TuTpLvrTo?si=K_yTaq_V7LpX0FfA" />
      <form
        className={styles.mailingForm}
        onSubmit={sendTelegramMailing}
        onChange={handleOnChange}
      >
        <div
          className={`${styles.mailingChannelWrapper} ${styles.fieldValidationBorder} ${styles.success}`}
        >
          <div className={styles.formRadioGroup}>
            <div className={styles.mailingChannelWrapperStep}>
              <div className={styles.wrapperMailingRadio}>
                <InfoTooltip text="Канал отправки Telegram." />
                <h3 className={styles.radioLabel}>
                  Канал отправки
                  <span className={styles.channelRedStar}></span>
                </h3>
                <div className={styles.formRadioGroupBtn}>
                  <input
                    type="radio"
                    id="telegramChannel"
                    name="mailing-radio"
                    value="telegram"
                    checked
                    onChange={changeRadioValue}
                  />
                  <label htmlFor="telegramChannel">Telegram</label>
                  {/* <input
                    id="whatsappChannel"
                    type="radio"
                    name="mailing-radio"
                    value="whatsapp"
                    checked={value === 'whatsapp' ? true : false}
                    onChange={changeRadioValue}
                  />
                  <label htmlFor="whatsappChannel">WhatsApp</label> */}
                </div>
                <span className={styles.mailingSaleWA}>
                  Стоимость одного сообщения - {messagePrice} руб.
                </span>
              </div>
            </div>
          </div>
        </div>
        <MailingForm formData={formData} setFormData={setFormData} />
        <div
          className={`${styles.mailingChannelWrapper} ${
            styles.fieldValidationBorder
          } ${validateStepOne() ? styles.success : ''}`}
        >
          <div className={styles.formGroup}>
            <div className={styles.mailingChannelWrapperStep}>
              <InfoTooltip text="Загрузите изображение в формате .jpg или .png. Размер картинки не должен превышать 10Mb" />
              <div className={styles.wrapperMailingName}>
                <h3 className={styles.labelImage}>Изображение</h3>
              </div>
              <input
                id="image-button"
                className={styles.inputImage}
                type="file"
                accept=".jpg, .jpeg, .png"
              />
              <label htmlFor="image-button" className={styles.imageButton}>
                Загрузите изображение
              </label>
              <div className={styles.mailingImageMT}>
                <span className={styles.mailingSaleWA}>
                  +60 копеек за одно сообщение
                </span>
              </div>
            </div>
          </div>
          <div className={styles.imageWrapper}>
            {imgURL && <img src={imgURL} className={styles.image} />}
          </div>
        </div>
        <div
          className={`${styles.mailingChannelWrapper} ${
            styles.fieldValidationBorder
          } ${styles.lastNoBorder} ${validateStepTwo() ? styles.success : ''} ${
            errors.sheduled_time ? styles.errors : ''
          }`}
        >
          <div className={styles.formGroup}>
            <div className={styles.mailingChannelWrapperStep}>
              <InfoTooltip text="По умолчанию таргетинг отправится сразу, если не будет указано другое время." />
              <div className={styles.wrapperMailingName}>
                <h3 className={styles.labelImage}>Дата и время таргетинга</h3>
              </div>
              <DateTimePicker
                value={dayjs(formData.scheduled_time)}
                minDate={dayjs()}
                onError={(error) =>
                  setErrors({ ...errors, sheduled_time: error || '' })
                }
                onChange={(newValue: any) => {
                  if (newValue && newValue.isValid()) {
                    setFormData({
                      ...formData,
                      scheduled_time: newValue.toISOString(),
                    });
                  }
                }}
                format="DD MM YYYY HH:mm"
                className={styles.dateInput}
                slotProps={{
                  textField: {
                    sx: {
                      border: '1px solid #8586A5',
                      '& .MuiInputBase-input': {
                        color: '#8586A5',
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '15px 14px',
                      },
                      '& .MuiOutlinedInput-root:hover': {
                        outline: '2px solid #8586A5',
                      },
                      '& .clearButton': {
                        color: '#8586A5',
                      },
                    },
                  },
                  inputAdornment: {
                    sx: {
                      '& .MuiButtonBase-root': {
                        color: '#8586A5',
                      },
                    },
                  },
                  field: {
                    clearable: true,
                    onClear: () =>
                      setFormData({
                        ...formData,
                        scheduled_time: dayjs().toISOString(),
                      }),
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.mailingChannelWrapper}>
          <div className={styles.formGroup}>
            <div className={styles.mailingChannelWrapperStep}>
              <div>
                <div className={styles.wrapperMailingName}>
                  <h3 className={styles.labelImage}>Итоги по таргетингу</h3>
                </div>
                <div>
                  <div className={styles.mailingResultsWrapper}>
                    <div>
                      <div className={styles.mailingResultsElement}>
                        <img src={MailingMessage} />
                        <span className={styles.mailingResultsInfo}>
                          Сообщений
                        </span>
                        <div className={styles.mailingResultsInfoValue}>
                          {mailingCount ? mailingCount : 0}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.mailingResultsElement}>
                        <img src={MailingTime} />
                        <span className={styles.mailingResultsInfo}>
                          Время отправки
                        </span>
                        <div className={styles.mailingResultsInfoValue}>
                          {mailingTime ? mailingTime : 0} &nbsp;мин
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.mailingResultsElement}>
                        <img src={MailingCost} />
                        <span className={styles.mailingResultsInfo}>
                          Стоимость
                        </span>
                        <div className={styles.mailingResultsInfoValue}>
                          {mailingCost ? mailingCost : 0}
                          <span>&nbsp;Р</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.excessBalanceError}>
          <span>{errorText}</span>
        </div>
        <div className={styles.mailingBtnContainer}>
          <button
            className={styles.button}
            onClick={sendInfoForCost}
            disabled={isSubmitting}
          >
            Рассчитать
          </button>
          <button
            className={styles.button}
            type="submit"
            disabled={!isCalculated}
          >
            {isSubmitting ? (
              <div className={styles.loaderContainer}>
                <Loader width={24} height={24} />
              </div>
            ) : (
              <>Запустить</>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MailingPage;
