import { useNavigate } from 'react-router-dom';
import ModalWindow from '../../ui/modal-window/ModalWindow';
import { ReactComponent as CheckMarkIcon } from '../../../assets/images/icons/check-mark-icon.svg';
import { Routes } from '../../../router/routes';
import styles from '../registration-form.module.sass';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';

type RegistrationMessageProps = {
  isActive: boolean;
  setActive: (modalIsActive: boolean) => void;
};

const RegistrationMessage = ({
  isActive,
  setActive,
}: RegistrationMessageProps) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    setActive(false);
    navigate(Routes.Login);
  };
  return (
    <ModalWindow isActive={isActive} setActive={onClickHandler}>
      <div className={styles.successMessage}>
        <div>
          На Ваш адрес электронной почты отправлена ссылка для подтверждения
          аккаунта.
          <br />
          Для успешного завершения регистрации перейдите по ней.
        </div>
        <CheckMarkIcon width="60" height="60" />
        <Btn_transparent
          className={styles.btnSucess}
          variant="standart"
          onClick={onClickHandler}
        >
          Продолжить
        </Btn_transparent>
      </div>
    </ModalWindow>
  );
};
export default RegistrationMessage;
