import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  getIn,
  type FormikErrors,
} from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchTasks } from '../../../store/task-slice/apiActions';

import { participantsValidation } from './participants-validation-schema';
import { api } from '../../../services/api';
import ModalCentered from '../../ui/modal-window/ModalCentered';

import TextInput from '../../ui/input/TextInput';
// import Button from '../../ui/button/Button';
import IconButton from '../../ui/iconButton/IconButton';
import InfoTooltip from '../../ui/infoTooltip/infoTooltip';
import MailingYoutubePlayer from '../../../pages/Mailing/MailingYoutubePlayer/MailingYoutubePlayer';

import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/icons/minus.svg';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';

import sharedStyles from '../index.module.sass';

type FormValues = {
  groups: { id: string; value: string }[];
  amountFrom: number;
  name: string;
};

const Participants = () => {
  const MAX_FIELDS = 5;
  const isDisabled = useOutletContext<boolean>();
  const [isFetching, setIsFetching] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState({ name: '', parsing: '', daily: '' });
  const currentTariff = useAppSelector((state) => state.Tariff.currentTariff);

  const dispatch = useAppDispatch();

  const refreshTasks = () => {
    const limit = 10;
    const offset = 0;
    dispatch(fetchTasks({ limit, offset, operation: 'parsing' }));
  };

  useEffect(() => {
    refreshTasks();
  }, []);

  const initialValues: FormValues = {
    groups: [{ id: uuidv4(), value: '' }],
    amountFrom: 1,
    name: '',
  };

  const validateStepOne = (
    values: FormValues,
    errors: FormikErrors<FormValues>,
  ) => {
    return values.groups[0].value.length > 0 && !errors.groups;
  };

  const validateStepTwo = (values: FormValues) => {
    return values.amountFrom >= 1 && values.amountFrom <= values.groups.length;
  };

  const validateStepThree = (
    values: FormValues,
    errors: FormikErrors<FormValues>,
  ) => {
    return values.name.length > 0 && !errors.name;
  };

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsFetching(true);
    api
      .post('/telegram/parser/members', {
        task_name: values.name.replaceAll(' ', ''),
        parsed_chats: values.groups.map((group) => group.value),
        groups_count: values.amountFrom,
        export_file_ext: 'xlsx',
        //TODO
        rerun: false,
      })
      .then(() => {
        actions.resetForm();
        setModalShow(true);
        setError({ name: '', parsing: '', daily: '' });
        refreshTasks();
      })
      .catch((e) => {
        console.error(e);
        if (
          e.response.data.detail === 'Задание с таким именем уже существует'
        ) {
          setError({ ...error, name: e.response.data.detail });
        } else if (
          e.response.data.detail === 'Превышен лимит одновременных парсеров'
        ) {
          setError({ ...error, parsing: e.response.data.detail });
        } else if (
          e.response.data.detail === 'Превышен лимит дневных парсеров'
        ) {
          setError({ ...error, daily: e.response.data.detail });
        }
      })
      .finally(() => setIsFetching(false));
  };

  return (
    <section>
      <ModalCentered
        show={modalShow}
        headerText="Парсинг запущен"
        bodyText='Результаты парсинга отобразятся в таблице "История задач"'
        btnText="Закрыть"
        onHide={() => setModalShow(false)}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={participantsValidation}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form className={sharedStyles.form} method="post" action="">
            <a
              className={sharedStyles.instruction__link}
              href={require('../../../assets/pdfs/parsing-participants.pdf')}
              target="_blank"
            >
              Инструкция - Как собрать аудиторию в ТГ при помощи парсера
              "Участники"
            </a>
            <MailingYoutubePlayer videoID="ByKfI6fj_dA?si=_joMUUwC3Ey8oCDX" />
            <div
              className={`${sharedStyles.fieldValidationBorder} ${
                validateStepOne(values, errors) ? sharedStyles.success : ''
              } ${errors.groups ? sharedStyles.errors : ''}`}
            >
              <div className={sharedStyles.fieldWrapper}>
                <FieldArray
                  name="groups"
                  render={({ form, push, remove }) => (
                    <>
                      <InfoTooltip
                        text="Для осуществления поиска необходимо указать ссылку на группу или
            канал в которой производить парсинг аудитории. Групп или каналов
            можно указать несколько (от 1 до 5). Каждая новая ссылка должна
            быть на новой строке (в новом инпуте)."
                      />
                      <div className={sharedStyles.groupsHeader}>
                        <h3 className={sharedStyles.header}>
                          Каналы и группы для поиска
                        </h3>
                      </div>
                      <div className={sharedStyles.groupsInputsWrapper}>
                        <span style={{ fontSize: '12px', color: '#8586A5' }}>
                          <strong style={{ color: '#B63940' }}>
                            Обязательно!!!
                          </strong>{' '}
                          Канал/группа должна быть открыта и пользователи в ней
                          так же должны быть открыты! В закрытой группе с
                          закрытыми пользователями парсинг соберет 0 аудитории
                          <br />
                          Пример: https://t.me/название_группы
                        </span>
                        {values.groups.map((group, index) => (
                          <div
                            className={sharedStyles.groupsInput}
                            key={group.id}
                          >
                            <TextInput
                              name={`groups.${index}.value`}
                              type="text"
                              value={values.groups[index].value}
                              placeholder="Вставьте ссылку на канал, группу"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              errorMessage={(() => {
                                if (typeof errors.groups !== 'object')
                                  return '';
                                if (typeof errors.groups[index] !== 'object')
                                  return '';
                                if (getIn(form.touched, `groups.${index}`)) {
                                  return (
                                    errors.groups[index] as { value: string }
                                  ).value;
                                }
                              })()}
                            />
                            {index > 0 ? (
                              <IconButton
                                className={sharedStyles.minusIcon}
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <MinusIcon />
                              </IconButton>
                            ) : null}
                          </div>
                        ))}
                        <IconButton
                          disabled={values.groups.length >= MAX_FIELDS}
                          onClick={() => push({ id: uuidv4(), value: '' })}
                        >
                          <div className={sharedStyles.createFieldBtn}>
                            <PlusIcon />
                            {`Добавить поле (${values.groups.length}/${MAX_FIELDS})`}
                          </div>
                        </IconButton>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <div
              className={`${sharedStyles.fieldValidationBorder} ${
                validateStepOne(values, errors) && validateStepTwo(values)
                  ? sharedStyles.success
                  : ''
              } ${errors.amountFrom ? sharedStyles.errors : ''}`}
            >
              <div className={sharedStyles.fieldWrapper}>
                <h3 className={sharedStyles.header}>Количество</h3>
                <InfoTooltip text="Обозначьте, в каком количестве групп должны состоять пользователи, по умолчанию цифра 1. Если необходимо, можете заменить цифру. Максимальная цифра 5, т.к. максимальное количество групп - 5." />
                <div className={sharedStyles.fieldQty}>
                  <button
                    onClick={() =>
                      setFieldValue('amountFrom', values.amountFrom - 1)
                    }
                    style={{
                      cursor: 'pointer',
                      fontSize: '16px',
                    }}
                    className={
                      values.amountFrom <= 0 ? sharedStyles.hidden : ''
                    }
                  >
                    &ndash;
                  </button>
                  <input
                    className={sharedStyles.inputQty}
                    value={values.amountFrom || 0}
                    onChange={(e) => {
                      setFieldValue(
                        'amountFrom',
                        Number(e.target.value) <= values.groups.length
                          ? Number(e.target.value)
                          : values.groups.length,
                      );
                    }}
                    name="amountFrom"
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setFieldValue('amountFrom', values.amountFrom + 1)
                    }
                    className={`${sharedStyles.incDecButton} ${
                      values.amountFrom >= values.groups.length
                        ? sharedStyles.disabled
                        : ''
                    }`}
                    disabled={values.amountFrom >= values.groups.length}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`${sharedStyles.fieldValidationBorder} ${
                validateStepOne(values, errors) &&
                validateStepTwo(values) &&
                validateStepThree(values, errors)
                  ? sharedStyles.success
                  : ''
              } ${errors.name || error.name ? sharedStyles.errors : ''}`}
            >
              <div className={sharedStyles.fieldWrapper}>
                <h3 className={sharedStyles.header}>Название задачи</h3>
                <InfoTooltip text="Дайте название своему парсингу (задаче). Это название будет видно только Вам в таблице с историями по парсингу." />
                <TextInput
                  style={{ maxWidth: '610px' }}
                  name="name"
                  type="text"
                  placeholder="Придумайте название задачи"
                  hintMessage="Название будет видно только Вам"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    error.name ||
                    (errors.name && touched.name ? errors.name : '')
                  }
                />
              </div>
            </div>
            {error.parsing && (
              <p
                className={sharedStyles.errorMessage}
                style={{ margin: '0 auto 16px', textAlign: 'center' }}
              >
                {`${error.parsing}. По Вашему тарифу их количество - ${currentTariff.options.simultaneous_parsing}. Подождите окончания одной из задач.`}
              </p>
            )}
            {error.daily && (
              <p
                className={sharedStyles.errorMessage}
                style={{ margin: '0 auto 16px', textAlign: 'center' }}
              >
                {`${error.daily}. По Вашему тарифу их количество - ${currentTariff.options.parsers_per_day}.`}
              </p>
            )}
            <Btn_transparent
              style={{ width: '100%', maxWidth: 'none' }}
              variant={'standart'}
              type="submit"
              disabled={isFetching || isDisabled}
            >
              {isDisabled
                ? 'Недоступно для вашего тарифа'
                : 'Начать сбор аудитории'}
            </Btn_transparent>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default Participants;
