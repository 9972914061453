import React, { useState } from 'react';
import style from './style.module.sass';
import { ReactComponent as ArrowSmall } from '../../../assets/images/icons/vector-arrow_small.svg';
import arrowImage from '../../../assets/images/icons/vector-arrow.svg';
import arrowImage500 from '../../../assets/images/icons/arrowImage500.svg';
import arrowImage1920 from '../../../assets/images/icons/vector-arrow.svg';
import rectangleImage from '../../../assets/images/icons/rectangle.svg';

export default function AboutUs() {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredBot, setIsHoveredBot] = useState(false);

  const handleMouseEnter = () => {
    setTimeout(() => {
      setIsHovered(true);
    }, 200);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsHovered(false);
    }, 200);
  };

  return (
    <div
      className={`${style.aboutUs__wrapper} ${style.aboutUs__imgWrapper}`}
      id="about_us"
    >
      <div
        className={`${style.aboutUs__block} ${style.special} ${
          isHovered ? style.hovered : ''
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <a href="https://t.me/kanale12efk" target="_blank">
          <div className={`${style.aboutUs__img}`}>
            <picture>
              <source
                media="(max-width: 500px)"
                srcSet={isHovered ? rectangleImage : arrowImage500}
              />
              <source
                media="(max-width: 1730px)"
                srcSet={isHovered ? rectangleImage : arrowImage}
              />
              <img
                src={isHovered ? rectangleImage : arrowImage1920}
                alt="стрелочка"
              />
            </picture>
          </div>
          <div className={`${style.aboutUs__text_content} ${style.special}`}>
            <span className={style.aboutUS__header}>
              20 лет опыта в интернет маркетинге
            </span>
            <span className={style.aboutUS__text}>
              Мы знаем как сделать вашу рекламу эффективной, подписывайтесь на
              наш телеграм-канал
            </span>
          </div>
        </a>
      </div>
      <div className={style.aboutUs__block}>
        <div className={style.aboutUs__text_content}>
          <span className={style.aboutUS__header}>
            Простой и удобный сервис
          </span>
          <ul className={style.aboutUs__ul}>
            <li>
              В сервисе легко можно найти самых «горячих» клиентов,
              интересующихся вашими продуктами
            </li>
            <li>
              Уникальные парсеры позволят собрать аудитории по ГЕО точкам, а
              также по интересам
            </li>
            <li>Запустить рекламу в Телеграм можно за 10 минут</li>
          </ul>
        </div>
      </div>
      <div className={style.aboutUs__block}>
        <div className={style.aboutUs__text_content}>
          <span className={style.aboutUS__header}>
            Обучение маркетингу в социальных сетях
          </span>
          <div className={style.aboutUs__wrap_text}>
            <div
              className={style.aboutUs__text_link__wrapper}
              onMouseEnter={() => setIsHoveredBot(true)}
              onMouseLeave={() => setIsHoveredBot(false)}
            >
              <span className={style.aboutUS__text}>Подпишитесь на нашего</span>
              &nbsp;&nbsp;
              <a
                href="https://t.me/tgparsin_bot"
                target="_blank"
                className={style.aboutUs__text_link}
              >
                {isHoveredBot ? (
                  <>
                    <ArrowSmall />
                    &nbsp;&nbsp;
                    <span className={style.aboutUS__text}>чат-бота</span>
                  </>
                ) : (
                  <>
                    <span className={style.aboutUS__text}>чат-бота</span>
                    &nbsp;&nbsp;
                    <ArrowSmall />
                  </>
                )}
              </a>
            </div>
            <span className={style.aboutUS__text}>и начните обучение</span>
          </div>
        </div>
      </div>
      <div className={style.aboutUs__block}>
        <div className={style.aboutUs__text_content}>
          <span className={style.aboutUS__header}>
            Обучайтесь и участвуйте в розыгрыше
          </span>
          <span className={style.aboutUS__text}>Призовой фонд 75 000 ₽</span>
          <a href="https://smm-technolog.ru/courses/" target="__blank">
            <button className={style.aboutUs__btn}>Участвую</button>
          </a>
        </div>
      </div>
    </div>
  );
}
