import React, { useState, ChangeEvent, useEffect } from 'react';
import styles from './styles.module.sass';
import { ParserTask } from '../../types/parserTask';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchTasks } from '../../store/task-slice/apiActions';
import { api } from '../../services/api';
import ModalCentered from '../ui/modal-window/ModalCentered';

interface FormData {
  task_name: string;
  groups: string[];
  users_filename: string;
  users: string[];
}

const initialFormData: FormData = {
  task_name: '',
  groups: [''],
  users_filename: '',
  users: [''],
};

export default function InvitingForm() {
  const [count, setCount] = useState(1);
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [parserTask, setParserTask] = useState(false);
  const [handleContacts, setHandleContacts] = useState(false);
  const [error, setError] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const limit = 10;
    const offset = 0;
    dispatch(fetchTasks({ limit, offset, operation: 'parsing' }));
  }, []);

  const sendInviting = (evt: any) => {
    evt.preventDefault();
    api
      .post('/telegram/inviting', formData)
      .then((response) => {
        if (response.status === 200) {
          console.log('Успешно');
          setFormData(initialFormData);
          setModalShow(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 403) {
          setError(error.response.data.detail);
        }
      });
  };

  const tasks: ParserTask[] | null = useAppSelector(
    (state) => state.Task.tasks,
  );
  const parsingTasks: ParserTask[] | null = tasks.filter(
    (item) =>
      item.operation === 'parsing' &&
      item.work_status === 'success' &&
      item.data_count !== 0,
  );

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = event.target.value;
    const updatedChats = [...formData.groups];
    updatedChats[index] = value;
    setFormData({
      ...formData,
      groups: updatedChats,
    });
    console.log(formData);
  };

  const handleFileUpload = async (event: any) => {
    const file = await event.target.files[0];

    function sendFile() {
      const formDataFile = new FormData();
      formDataFile.append('file', file);
      api
        .post('/telegram/parser/tgmailing/upload_file', formDataFile)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (file) {
      const fileText = await event.target.files[0].name;
      setFormData({
        ...formData,
        users_filename: fileText,
      });
      sendFile();
    }
  };

  const handleFileButtonClick = () => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const increase = () => {
    if (count < 5) {
      setCount(count + 1);
    }
  };

  const decrease = () => {
    if (count > 1) {
      setCount(count - 1);
      const updatedChats = [...formData.groups];
      updatedChats.pop();
      setFormData({
        ...formData,
        groups: updatedChats,
      });
    }
  };

  const openParserTask = () => {
    setParserTask(!parserTask);
  };

  const openHandleContacts = () => {
    setHandleContacts(!handleContacts);
  };

  return (
    <div className={styles.wrapper}>
      <ModalCentered
        show={modalShow}
        headerText="Инвайтинг запущен"
        bodyText='Результаты инвайтинга отобразятся в таблице "История задач" на дашборде'
        btnText="Закрыть"
        onHide={() => setModalShow(false)}
      />
      <form onSubmit={sendInviting} className={styles.wrapper}>
        <div
          className={`${styles.nameTask__wrapper} ${
            formData.task_name ? styles.greenBorder : ''
          }`}
        >
          <span className={styles.header}>Название задачи</span>
          <input
            className={styles.input}
            value={formData.task_name || ''}
            onChange={(e) =>
              setFormData({ ...formData, task_name: e.target.value })
            }
          />
          <span className={styles.text}>
            Название задачи видно только вам в истории задач
          </span>
        </div>
        <div
          className={`${styles.group__wrapper} ${
            formData.groups[0] ? styles.greenBorder : ''
          }`}
        >
          <div className={styles.group__qty}>
            <span className={styles.header}>
              Укажите количество групп (от 1 - до 5), куда хотите пригласить
              пользователей
            </span>
            <div className={styles.group__count}>
              <span className={styles.count_sign} onClick={decrease}>
                -
              </span>
              <span>{count}</span>
              <span className={styles.count_sign} onClick={increase}>
                +
              </span>
            </div>
          </div>
          <div className={styles.group}>
            <span className={styles.header}>
              Ссылка на группу, куда хотите пригласить пользователей
            </span>
            {[...Array(count)].map((_, index) => (
              <input
                key={index}
                className={styles.input__group}
                placeholder="https://t.me/my_group"
                value={formData.groups[index] || ''}
                onChange={(e) => handleInputChange(e, index)}
              />
            ))}
            <span className={styles.text}>
              Важно! Ссылка должна быть на открытую группу. <br />
              Лимит: в одну группу добавляется до 100 аккаунтов в день
            </span>
          </div>
        </div>
        <div
          className={`${styles.contacts__wrapper} ${
            formData.users_filename || formData.users[0]
              ? styles.greenBorder
              : ''
          }`}
        >
          <span className={styles.header}>Контакты для инвайтинга</span>
          <div className={styles.btn__group}>
            <input
              type="button"
              className={styles.btn__left}
              onClick={openParserTask}
              value="Загрузить из таблицы парсинга"
            />
            {parserTask && (
              <div className={styles.parserTask}>
                <ul className={styles.parsing__list}>
                  {parsingTasks.map((task, i) => (
                    <li
                      key={i}
                      className={styles.parsing__item}
                      style={{ position: 'relative' }}
                      onClick={() => {
                        setFormData({
                          ...formData,
                          users_filename: task.file_name,
                        });
                        setParserTask(false);
                      }}
                    >
                      <p>{task.settings.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <input
              type="button"
              className={styles.btn__center}
              onClick={handleFileButtonClick}
              value="Загрузить файл с компьютера"
            />
            <input
              id="fileInput"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
            <input
              type="button"
              className={styles.btn__right}
              onClick={openHandleContacts}
              value="Указать контакты вручную"
            />
          </div>
          {handleContacts && (
            <textarea
              className={styles.handleContact}
              onChange={(e) => {
                const usersText = e.target.value;
                const usersArray = usersText
                  .split('\n')
                  .map((line) => line.replace(/\s/g, ''))
                  .filter(Boolean);
                setFormData({
                  ...formData,
                  users: usersArray,
                });
              }}
            />
          )}
          {formData.users_filename && (
            <span className={styles.contact__bottomText}>
              Файл успешно подгружен
            </span>
          )}
        </div>
        <button className={styles.btn__calculation} type="submit">
          Запустить
        </button>
        <div className={styles.errors}>
          <span>{error}</span>
        </div>
      </form>
      {/* <div className={styles.calculated__wrapper}>
        <span className={styles.calculated__header}>Итого по инвайтингу</span>
        <div className={styles.calculated__body}></div>
      </div> */}
    </div>
  );
}
