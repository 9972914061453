import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import styles from './startinfo.module.sass';
import { ReactComponent as Clock } from '../../../assets/images/icons/clock2.svg';
import { ReactComponent as Graph } from '../../../assets/images/icons/graph.svg';
import { ReactComponent as People } from '../../../assets/images/icons/vector-people.svg';
import { ReactComponent as TG } from '../../../assets/images/icons/vector-tg.svg';
import IPhone from '../../../assets/images/IPhone13Pro.png';
import IPhone_1920 from '../../../assets/images/IPhone13Pro_1920.png';
import IPhone_900 from '../../../assets/images/iPhone13Pro_900.png';

export default function StartInfo() {
  const tickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ticker = tickerRef.current;

    if (!ticker) {
      return;
    }

    let currentTickerIndex = 0;

    const handleAnimationEnd = () => {
      currentTickerIndex = (currentTickerIndex + 1) % ticker.children.length;
      updateTickerVisibility();
    };

    const updateTickerVisibility = () => {
      for (let i = 0; i < ticker.children.length; i++) {
        const tickerItem = ticker.children[i] as HTMLDivElement;
        tickerItem.style.display = i === currentTickerIndex ? 'flex' : 'none';
      }
    };

    ticker.addEventListener('animationiteration', handleAnimationEnd);

    updateTickerVisibility();

    return () => {
      ticker.removeEventListener('animationiteration', handleAnimationEnd);
    };
  }, []);

  return (
    <div className={styles.startInfo__wrapper}>
      <div className={styles.startInfo__innerWrapper}>
        <div className={styles.startInfo__header}>
          <span>
            Запускайте рекламу <br /> в Telegram из личного <br />
            кабинета{' '}
          </span>
          <span style={{ color: '#384053' }}>Tgparsing</span>
        </div>
        <div ref={tickerRef} className={styles.startInfo_ticker}>
          <div className={styles.ticker}>
            <div className={styles.ticker_svg}>
              <Clock />
            </div>
            <div className={styles.ticker_text}>
              <span>Реклама в Телеграм за 10 минут</span>
            </div>
          </div>
          <div className={styles.ticker}>
            <div className={styles.ticker_svg}>
              <Graph />
            </div>
            <div className={styles.ticker_text}>
              <span>Парсинг аудиторий и таргетированная реклама</span>
            </div>
          </div>
          <div className={styles.ticker}>
            <div className={styles.ticker_svg}>
              <People />
            </div>
            <div className={styles.ticker_text}>
              <span>
                Соберите аудиторию по ГЕО точкам или по активностям и отправь им
                рекламное сообщение
              </span>
            </div>
          </div>
          <div className={styles.ticker}>
            <div className={styles.ticker_svg}>
              <TG />
            </div>
            <div className={styles.ticker_text}>
              <span>Отправка сообщений в Телеграм от 2 ₽</span>
            </div>
          </div>
        </div>
        <Link to={Routes.Login}>
          <button className={styles.startinfo__btn}>Запустить рекламу</button>
        </Link>
      </div>
      <div className={styles.startInfo__iPhone_img}>
        <picture>
          <source media="(max-width: 900px)" srcSet={IPhone_900} />
          <source media="(max-width: 1730px)" srcSet={IPhone} />
          <img src={IPhone_1920} alt="картинка iPhone13Pro" />
        </picture>
      </div>
    </div>
  );
}
