import { ParserTask } from '../types/parserTask';

export default function useMailingDaily(dataTasks: ParserTask[]) {
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 6);

  const allDates = [];
  const tempDate = new Date(oneWeekAgo);
  while (tempDate <= currentDate) {
    allDates.push(new Date(tempDate));
    tempDate.setDate(tempDate.getDate() + 1);
  }

  const sumsByDate: Record<string, number> = {};

  if (dataTasks) {
    dataTasks
      .filter((item: ParserTask) => item.operation === 'mailing')
      .forEach((item: ParserTask) => {
        const date = item.created_at.split('T')[0];
        const dataCount = item.data_count;
        if (sumsByDate[date]) {
          sumsByDate[date] += dataCount;
        } else {
          sumsByDate[date] = dataCount;
        }
      });
  }

  const sortedDates = allDates.map((date) => {
    const formattedDate = date.toISOString().split('T')[0];
    return sumsByDate[formattedDate] || 0;
  });

  const formattedSortedDates = allDates.map((date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${day}-${month}`;
  });

  return [sortedDates, formattedSortedDates];
}
