import React, { useRef, useState, MouseEvent, TouchEvent } from 'react';
import style from './keybenefis.module.sass';
import KeyBenefis__icon from '../../../assets/images/KeyBenefis__icon_1440.png';
import KeyBenefis__icon_1920 from '../../../assets/images/KeyBenefis__icon_1920.png';
import KeyBenefis__icon_500 from '../../../assets/images/KeyBenefis__icon_500.png';
import KeyBenefis__icon_900 from '../../../assets/images/KeyBenefis__icon_900.png';

export default function KeyBenefis() {
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const wrapperRef = useRef(null);

  const handleMouseDown = (e: MouseEvent) => {
    setIsDragging(true);
    setDragStartX(e.clientX - offsetX);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      const newOffsetX = e.clientX - dragStartX;
      setOffsetX(newOffsetX);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e: TouchEvent) => {
    setIsDragging(true);
    setDragStartX(e.touches[0].clientX - offsetX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (isDragging) {
      const newOffsetX = e.touches[0].clientX - dragStartX;
      setOffsetX(newOffsetX);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <div className={style.keyBenefis__wrapper} id="KeyBenefis">
      <div className={style.keyBenefis__header}>
        <span>
          Новые возможности поиска целевой аудитории
          <br /> и таргетинга в{' '}
        </span>
        <a
          href="https://t.me/tgparsin_bot"
          target="_blank"
          className={style.aboutUs__text_link}
        >
          <span style={{ color: '#0176C9' }}>Telegram</span>
        </a>
      </div>
      <div
        className={style.keyBenefis__info}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        ref={wrapperRef}
        style={{ transform: `translateX(${offsetX}px)` }}
      >
        <div className={style.keyBenefis__info_block}>
          <span>Удобный интерфейс: простые настройки сложных таргетов</span>
        </div>
        <div className={style.keyBenefis__info_block}>
          <span>Вход в таргетинг в Телеграм от 50 рублей</span>
        </div>
        <div className={style.keyBenefis__info_block}>
          <span>
            Уникальное сочетание парсеров с таргетингом в одном сервисе
          </span>
        </div>
        <div className={style.keyBenefis__info_block}>
          <span>Автоматический возврат средств за недоставленную рекламу</span>
        </div>
        <div className={style.keyBenefis__info_block}>
          <span>
            Брендирование рекламы — важный элемент для серьёзных рекламодателей
          </span>
        </div>
        <div className={style.keyBenefis__info_block}>
          <span>Сбор местной аудитории по ГЕО точкам за пару минут</span>
        </div>
      </div>
      <div className={style.keybenefis__graph}>
        <picture>
          <source
            media="(max-width: 500px)"
            srcSet={KeyBenefis__icon_500}
            width="320px"
          />
          <source
            media="(max-width: 900px)"
            srcSet={KeyBenefis__icon_900}
            width="728px"
          />
          <source
            media="(max-width: 1730px)"
            srcSet={KeyBenefis__icon}
            width="1140px"
          />
          <img
            src={KeyBenefis__icon_1920}
            alt="Ключевые преимущества"
            width="100%"
          />
        </picture>
      </div>
    </div>
  );
}
