import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';
import ModalWindow from '../../ui/modal-window/ModalWindow';
import Logo from '../../ui/logo/Logo';
import NavLink from '../../ui/navLink/NavLink';
import { MenuCategory, MenuItem } from '../menu-items';
import { useAppDispatch } from '../../../hooks/redux';
import { logout } from '../../../store/user-slice/apiActions';
import { logoutChannel } from '../../../services/logout-channel';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import { ReactComponent as IconLogout } from '../../../assets/images/icons/logout.svg';
// import { ReactComponent as IconPartner } from '../../../assets/images/icons/watch.svg';
import styles from './index.module.sass';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';
import { ReactComponent as MenuIcon } from '../../../assets/images/icons/vector_menuIcon.svg';

interface PropTypes {
  menuItems: MenuItem[];
  currentPage: MenuItem;
}

const Navbar = ({ menuItems, currentPage }: PropTypes) => {
  const currentLinkPosition =
    currentPage.menuCategory === MenuCategory.Navbar
      ? currentPage.position
      : -1;
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuIcon, setIsMenuIcon] = useState(false);
  const [isMenu, setIsMenu] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const rederectToHomePage = async () => {
    setIsOpen(false);
    await dispatch(logout());
    logoutChannel.postMessage('logout');
    navigate(Routes.Home);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 500) {
      setIsMenuIcon(true);
      setIsMenu(false);
    }
  }, [windowWidth]);

  const openMenu = () => {
    setIsMenu(!isMenu);
    setIsMenuIcon(true);
  };

  const closeMenu = () => {
    if (windowWidth <= 500) {
      setIsMenuIcon(true);
      setIsMenu(false);
    }
  };

  // const handleLinkClick = () => {
  //   window.location.href = 'https://partner-system.ru/main';
  // };

  return (
    <>
      {isMenuIcon && (
        <div className={styles.header__menu_icon}>
          <MenuIcon onClick={openMenu} />
        </div>
      )}
      {isMenu && (
        <div className={styles.wrapper}>
          <div className={styles.nav}>
            <div className={styles.header__logoWrapper}>
              <Logo />
            </div>
            <aside className={styles.nav__links}>
              <div className={styles.nav__list} onClick={closeMenu}>
                {menuItems.map((item, index) => (
                  <NavLink
                    link={item.link}
                    text={item.text}
                    icon={item.icon}
                    isActive={index === currentLinkPosition}
                    isDisabled={item.disabled}
                    key={item.text}
                  />
                ))}
              </div>
              <div className={styles.buttonOutWrapper}>
                {/*<form action="https://partner-system.ru/main">*/}
                {/*  <div className={styles.formContainer}>*/}
                {/*    <span className={styles.iconPartner} onClick={handleLinkClick}>*/}
                {/*      <IconPartner />*/}
                {/*    </span>*/}
                {/*    <button className={styles.partnerSystem}>*/}
                {/*      Партнерский кабинет*/}
                {/*    </button>*/}
                {/*  </div>*/}
                {/*</form>*/}
                <div
                  className={styles.buttonOut}
                  onClick={() => setIsOpen(true)}
                >
                  <span className={styles.iconOut}>
                    <IconLogout />
                  </span>
                  <button className={styles.profilePage_btn}>Выйти</button>
                </div>
              </div>
            </aside>
            {createPortal(
              <ModalWindow
                isActive={isOpen}
                setActive={setIsOpen}
                style={{
                  paddingTop: '34px',
                  paddingBottom: '34px',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  width: '480px',
                }}
              >
                <h2 style={{ marginBottom: '52px', textAlign: 'center' }}>
                  Вы уверены, что хотите выйти?
                </h2>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                  }}
                >
                  <Btn_transparent
                    variant="blue"
                    onClick={() => setIsOpen(false)}
                  >
                    Нет
                  </Btn_transparent>
                  <Btn_transparent
                    variant="standart"
                    onClick={rederectToHomePage}
                  >
                    Да
                  </Btn_transparent>
                </div>
              </ModalWindow>,
              document.body,
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
