import Logo from '../../components/ui/logo/Logo';
import styles from './fail-page.module.sass';
import FailPng from '../../assets/images/fail.png';
import { Link } from 'react-router-dom';

const FailPage = () => {
  return (
    <div className={styles.FailPageWrapper}>
      <div>
        <Logo />
      </div>
      <div className={styles.FailPageInfo}>
        <div>
          <div className={styles.FailPageTitle}>ОШИБКА</div>
          <div className={styles.FailPageParagraph}>Что-то пошло не так...</div>
          <div className={styles.FailPageMessage}>
            Сервис временно не работает. Команда <br /> спасателей все чинит.
            Скоро все будет!
          </div>
          <Link to="/">
            <div className={styles.FailPageBtn}>На главную</div>
          </Link>
        </div>
        <div className={styles.FailPagePng}>
          <img src={FailPng} height="auto" width="100%" />
        </div>
      </div>
    </div>
  );
};

export default FailPage;
