import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks/redux';
import { CURRENT_DATE_STRING } from '../../../consts/consts';
import { api } from '../../../services/api';
import { Link } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import styles from './wallet.module.sass';
import { ReactComponent as ChangeTariff } from '../../../assets/images/icons/change.svg';

export default function MyTariff() {
  const [tariffName, setTariffName] = useState();
  const [tariffCost, setTariffCost] = useState();
  const [isTariff, setIsTariff] = useState(false);

  const usertariff = useAppSelector((state) => state.UserData);
  const userEndDateTariff = usertariff.user?.subscribe?.end_date;

  let formattedDate = '';
  if (userEndDateTariff) {
    const date = new Date(userEndDateTariff);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    formattedDate = `${day < 10 ? '0' : ''}${day}.${
      month < 10 ? '0' : ''
    }${month}.${year}`;
  }

  useEffect(() => {
    if (
      userEndDateTariff !== undefined &&
      userEndDateTariff > CURRENT_DATE_STRING
    ) {
      setIsTariff(true);
      api
        .get(`/tariff/${usertariff.user?.subscribe?.tariff_id}`)
        .then((response) => {
          setTariffName(response.data.name);
          setTariffCost(response.data.price);
        })
        .catch((error) => console.error(error));
    }
  }, [formattedDate]);

  return (
    <div className={styles.tariff}>
      {isTariff ? (
        <div className={styles.tariff__header}>
          <span>Мой тариф</span>
          <Link to={Routes.Tariffs}>
            <ChangeTariff />
          </Link>
        </div>
      ) : (
        <span className={styles.isNoTariff__text}>Мой тариф</span>
      )}
      {isTariff ? (
        <div className={styles.isTariff}>
          <div className={styles.tariffName}>
            <span>{tariffName}</span>
          </div>
          <div className={styles.tariffCost}>
            <span>{tariffCost} руб</span>
          </div>
          <div className={styles.tariffEndDate}>
            <span>Окончание действия тарифа {formattedDate}</span>
          </div>
        </div>
      ) : (
        <div className={styles.isNoTariff}>
          <Link to={Routes.Tariffs}>
            <span className={styles.isNoTariff__header}>Выберете тариф</span>
          </Link>
          <span className={styles.isNoTariff__text}>
            Выберете тариф, что бы начать работу
          </span>
        </div>
      )}
    </div>
  );
}
