import { useState } from 'react';
import styles from './registration.module.sass';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { registrationSchema } from './validation-schema';
import Loader from '../../ui/loader/loader';
import { RegistrationData } from '../../../types/auth';
import { useAppDispatch } from '../../../hooks/redux';
import { register } from '../../../store/user-slice/apiActions';
import TextInputWhite from '../../ui/input/TextInputWhite';
import { ReactComponent as HideIcon } from '../../../assets/images/icons/closed-eye.svg';
import { ReactComponent as ShowIcon } from '../../../assets/images/icons/opened-eye.svg';
import Сonfidentiality from '../../confidentiality/Сonfidentiality';
import IconButton from '../../ui/iconButton/IconButton';
import RegistrationMessage from './SuccessMessage/RegistrationMessage';

export default function Registration() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordIsShown, setPasswordIsShown] = useState(false);
  const [checkPasswordIsShown, setCheckPasswordIsShown] = useState(false);
  const [successMessageIsVisible, setSuccessMessageIsVisible] = useState(false);

  const submitHandler = async (values: RegistrationData) => {
    setIsSubmitting(true);
    const sendedData = { ...values };
    delete sendedData['passwordCheck'];

    dispatch(
      register({
        ...sendedData,
        timezone: -new Date().getTimezoneOffset() / 60,
      }),
    )
      .unwrap()
      .then(() => {
        setErrorMessage('');
        setSuccessMessageIsVisible(true);
      })
      .catch((error) => {
        if (
          error.response.status < 500 &&
          error.response.status > 399 &&
          error.response.status !== 400
        ) {
          navigate('/fail');
        } else {
          setErrorMessage(error.response.data.detail);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className={styles.registration__wrapper}>
      <div className={styles.registration__header}>
        <span>Зарегистрируйтесь и найдите свою аудиторию</span>
      </div>
      <div className={styles.registration__content}>
        <Formik
          initialValues={{
            email: '',
            password: '',
            passwordCheck: '',
          }}
          validationSchema={registrationSchema}
          onSubmit={submitHandler}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValid,
            dirty,
          }) => (
            <Form className={styles.registrationForm}>
              <label>
                <TextInputWhite
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Введите ваш email"
                  errorMessage={
                    errors.email && touched.email ? errors.email : ''
                  }
                />
              </label>
              <label>
                <TextInputWhite
                  name="password"
                  type={passwordIsShown ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Придумайте пароль"
                  errorMessage={
                    errors.password && touched.password ? errors.password : ''
                  }
                  endIcon={
                    <IconButton
                      onClick={() => setPasswordIsShown(!passwordIsShown)}
                      isError={
                        (dirty && errors.password && touched.password) || false
                      }
                    >
                      {passwordIsShown ? <ShowIcon /> : <HideIcon />}
                    </IconButton>
                  }
                />
              </label>
              <label>
                <TextInputWhite
                  name="passwordCheck"
                  type={checkPasswordIsShown ? 'text' : 'password'}
                  value={values.passwordCheck}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Повторите пароль"
                  errorMessage={
                    errors.passwordCheck && touched.passwordCheck
                      ? errors.passwordCheck
                      : ''
                  }
                  endIcon={
                    <IconButton
                      onClick={() =>
                        setCheckPasswordIsShown(!checkPasswordIsShown)
                      }
                      isError={
                        (dirty &&
                          errors.passwordCheck &&
                          touched.passwordCheck) ||
                        false
                      }
                    >
                      {checkPasswordIsShown ? <ShowIcon /> : <HideIcon />}
                    </IconButton>
                  }
                />
              </label>
              <button
                className={styles.registration__button}
                type="submit"
                disabled={!(isValid && dirty) || isSubmitting}
              >
                {isSubmitting ? (
                  <div className={styles.loaderContainer}>
                    <Loader width={24} height={24} />
                  </div>
                ) : (
                  <>Зарегистрироваться</>
                )}
              </button>
              {errorMessage && <p className={styles.error}>{errorMessage}</p>}
              <Сonfidentiality />
              {successMessageIsVisible && (
                <RegistrationMessage
                  isActive={successMessageIsVisible}
                  setActive={setSuccessMessageIsVisible}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
