import { createBrowserRouter } from 'react-router-dom';
import 'dayjs/locale/ru';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Routes } from './routes';
import Layout from '../components/layout/Layout';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import DashboardPage from '../pages/DashboardPage/DashboardPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import RegistrationPage from '../pages/RegistrationPage/RegistrationPage';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import DocumentsPage from '../pages/DocumentsPage/DocumentsPage';
import AuthLayout from '../components/AuthLayout/AuthLayout';
import StartPage from '../pages/StartPage/StartPage';
import RecoverPasswordPage from '../pages/RecoveryPasswordPage/RecoverPasswordPage';
import ParsersLayout from '../components/parsers/ParsersLayout/ParsersLayout';
import Activities from '../components/parsers/activities/Activities';
import Participants from '../components/parsers/participants/Participants';
import Geolocation from '../components/parsers/geolocation/Geolocation';
import TariffsPage from '../pages/TariffsPage/TariffsPage';
// import StartLayout from '../components/StartLayout/StartLayout';
import TariffAdminPage from '../pages/Admin/TariffPage/TariffAdminPage';
import UsersAdminPage from '../pages/Admin/UsersPage/UsersAdminPage';
import BotsAdminPage from '../pages/Admin/BotsPage/BotsAdminPage';
import AdminBotsAll from '../pages/Admin/BotsPage/BotsAdminPageAll';
import AdminBotsStat from '../pages/Admin/BotsPage/BotsStat';
import BotPage from '../pages/Admin/BotPage/BotPage';
import FinanceAdminPage from '../pages/Admin/FinancePage/FinanceAdminPage';
import UserPage from '../pages/Admin/UserPage/UserPage';
import AdminProfilePage from '../pages/Admin/AdminProfilePage/AdminProfilePage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import MailingPage from '../pages/Mailing/MailingPage';
import MailingLayout from '../components/mailing/MailingLayout/MailingLayout';
import MailingTable from '../components/mailing/MailingTable/MailingTable';
import InvitingPage from '../pages/InvitingPage/InvitingPage';
import AdminInvitingPage from '../pages/Admin/InvitingPage/InvitingAdminPage';
import ContractPage from '../pages/ContractPage/ContractPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage/PrivacyPolicyPage';
import ConfirmPage from '../pages/ConfirmPage/ConfirmPage';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import RecoveryAlert from '../components/RecoveryAlert/RecoveryAlert';
import ResetPasswordSuccessPage from '../pages/ResetPasswordPage/ResetPasswordSuccesPage/ResetPasswordSuccessPage';
import FailPage from '../pages/FailPage/FailPage';
// import UnavailableServicePage from '../pages/UnavailableServicePage/UnavailableServicePage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8586A5',
    },
  },
});

export const router = createBrowserRouter([
  {
    element: (
      <ProtectedRoute>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
            <Layout />
          </LocalizationProvider>
        </ThemeProvider>
      </ProtectedRoute>
    ),
    children: [
      {
        path: Routes.Dashboard,
        element: <DashboardPage />,
        index: true,
      },
      {
        path: Routes.Parsers,
        element: <ParsersLayout />,
        children: [
          {
            path: Routes.ParsersActivities,
            element: <Activities />,
          },
          {
            path: Routes.ParsersParticipants,
            element: <Participants />,
          },
          {
            path: Routes.ParsersGeolocation,
            element: <Geolocation />,
          },
        ],
      },
      {
        // path: Routes.UnavailableServicePage,
        // element: <UnavailableServicePage />,
        path: Routes.Mailing,
        element: <MailingLayout />,
        children: [
          {
            path: Routes.Mailing,
            element: <MailingPage />,
          },
          {
            path: Routes.MailingTable,
            element: <MailingTable />,
          },
        ],
      },
      {
        path: Routes.Inviting,
        element: <InvitingPage />,
      },
      {
        path: Routes.Tariffs,
        element: <TariffsPage />,
      },
      {
        path: Routes.Profile,
        element: <ProfilePage />,
      },
      {
        path: Routes.Documents,
        element: <DocumentsPage />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute authRequired={false}>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: Routes.Login,
        element: <LoginPage />,
      },
      {
        path: Routes.Registration,
        element: <RegistrationPage />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute authRequired={false}>
        <AuthLayout isNavShown={false} />
      </ProtectedRoute>
    ),
    children: [
      {
        path: Routes.Recovery,
        element: <RecoverPasswordPage />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
  {
    path: '/contract',
    element: <ContractPage />,
  },
  {
    path: '/privacy',
    element: <PrivacyPolicyPage />,
  },
  {
    // element: (
    //   <ProtectedRoute authRequired={false}>
    //     <StartLayout />
    //   </ProtectedRoute>
    // ),
    path: Routes.Home,
    children: [
      {
        index: true,
        element: <StartPage />,
      },
      {
        path: Routes.HomeTariffs,
        element: <TariffsPage />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: Routes.AdminUsers,
        element: <UsersAdminPage />,
        index: true,
      },
      {
        path: Routes.AdminUser,
        element: <UserPage />,
      },
      {
        path: Routes.AdminTariff,
        element: <TariffAdminPage />,
      },
      {
        path: Routes.AdminBots,
        element: <BotsAdminPage />,
        // index: true,
        children: [
          {
            path: Routes.AdminBotsAll,
            element: <AdminBotsAll />,
          },
          {
            path: Routes.AdminBots,
            element: <AdminBotsStat />,
          },
        ],
      },
      {
        path: Routes.AdminBot,
        element: <BotPage />,
      },
      {
        path: Routes.AdminFinance,
        element: <FinanceAdminPage />,
      },
      {
        path: Routes.AdminProfile,
        element: <AdminProfilePage />,
      },
      {
        path: Routes.AdminDocuments,
        element: <DocumentsPage />,
      },
      {
        path: Routes.AdminInviting,
        element: <AdminInvitingPage />,
      },
    ],
  },
  {
    element: <ConfirmPage />,
    path: '/confirm',
  },
  {
    element: <ResetPasswordPage />,
    path: '/reset-password',
  },
  {
    element: <RecoveryAlert />,
    path: '/recovery-alert',
  },
  {
    element: <ResetPasswordSuccessPage />,
    path: '/reset-password-success',
  },
  {
    element: <FailPage />,
    path: '/fail',
  },
]);
