import styles from './dashboard-page.module.sass';
import Tasks from '../../components/Dashboard/Tasks/Tasks';
import QtyParsMailing from '../../components/Dashboard/Graphs/QtyParsMailing';
import Wallet from '../../components/Dashboard/Wallet/Wallet';
import InfoBanner from '../../components/ui/infoBanner/infoBanner';
import ModalCentered from '../../components/ui/modal-window/ModalCentered';
import { useEffect, useState } from 'react';
import { api } from '../../services/api';
import {
  CURRENT_DATE_STRING,
  CURRENT_DATE_STRING_PLUS_THREE_DAY,
  CURRENT_DATE_STRING_PLUS_TWO_DAY,
  CURRENT_DATE_STRING_PLUS_ONE_DAY,
} from '../../consts/consts';

const MainPage = () => {
  const [userData, setUserData] = useState<string>();
  const [workTariffEnd, setWorkTariffEnd] = useState(false);
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    api.get('/user/me').then((response) => {
      const res = response.data.subscribe?.end_date;
      const endDateString = res?.toString();
      setUserData(endDateString);
    });
  }, []);

  useEffect(() => {
    if (userData) {
      const today = new Date().toISOString().slice(0, 10);
      const lastNotificationDate = localStorage.getItem('lastNotificationDate');

      if (!lastNotificationDate || lastNotificationDate !== today) {
        if (userData <= CURRENT_DATE_STRING) {
          setWorkTariffEnd(true);
          setModalText(
            'Тариф кончился, приобретите новый тариф или продлите текущий',
          );
          localStorage.setItem('lastNotificationDate', today);
        } else if (userData <= CURRENT_DATE_STRING_PLUS_ONE_DAY) {
          setWorkTariffEnd(true);
          setModalText('Остался 1 день использования тарифа');
          localStorage.setItem('lastNotificationDate', today);
        } else if (userData <= CURRENT_DATE_STRING_PLUS_TWO_DAY) {
          setWorkTariffEnd(true);
          setModalText('Осталось 2 дня использования тарифа');
          localStorage.setItem('lastNotificationDate', today);
        } else if (userData <= CURRENT_DATE_STRING_PLUS_THREE_DAY) {
          setWorkTariffEnd(true);
          setModalText('Осталось 3 дня использования тарифа');
          localStorage.setItem('lastNotificationDate', today);
        }
      }
    }
  }, [userData]);

  return (
    <main className={styles.wrapper}>
      <ModalCentered
        show={workTariffEnd}
        headerText="Информация о тарифе"
        bodyText={modalText}
        btnText="Закрыть"
        onHide={() => setWorkTariffEnd(false)}
      />
      <div className={styles.infoBanner__wrapper}>
        <div className={styles.infoBanner}>
          <InfoBanner
            style={{ height: '88px' }}
            text=" "
            linkText="Скачать пошаговую инструкцию по продвижению в Телеграм - https://clck.ru/38kn4H"
            linkURL="https://clck.ru/38kn4H"
            context="ad"
          />
        </div>
        <div className={styles.infoBanner}>
          <InfoBanner
            style={{ height: '88px' }}
            text=" "
            linkText="Курс «Продвижение в Телеграм» - https://clck.ru/37SdmQ"
            linkURL="https://clck.ru/37SdmQ"
            context="ad"
          />
        </div>
      </div>
      <div className={styles.HeadLineWrapper}>
        <div className={styles.wrapperLeftBlock}>
          <QtyParsMailing />
          <div className={styles.info}>
            <Tasks />
          </div>
        </div>
        <Wallet />
      </div>
    </main>
  );
};

export default MainPage;
