import React, { useEffect, useState } from 'react';
import TableCell from '../../ui/table/tableCell/TableCell';
import TableContainer from '../../ui/table/tableContainer/TableContainer';
import TableHead from '../../ui/table/tableHead/TableHead';
import TableRow from '../../ui/table/tableRow/TableRow';
import Table from '../../ui/table/Table';
import TableBody from '../../ui/table/tableBody/TableBody';
import { ParserTask } from '../../../types/parserTask';
import { api } from '../../../services/api';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';
import AdminInvitingForm from './AdminInvitingForm';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styles from './styles.module.sass';

export default function AdminInviting() {
  const [tasks, setTasks] = useState<ParserTask[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    const limit = 20;
    const offset = 0;
    const operation = 'inviting';
    api
      .get(
        `/telegram/tasks/?offset=${offset}&limit=${limit}&operation=${operation}`,
      )
      .then((response) => {
        const resp = response.data;
        const filteredTasks = resp.filter(
          (task: any) => task.work_status === 'in_processing',
        );
        setTasks(filteredTasks);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleInputChange = (event: any) => {
    setDataCount(event.target.value);
  };

  useEffect(() => {
    if (dataCount !== 0) {
      fetchData();
    }
  }, [dataCount]);

  const fetchData = () => {
    api
      .post(
        `/telegram/tasks/manual_invite_complete/${id}?data_count=${dataCount}`,
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {modalOpen ? (
        <Form className={styles.form}>
          <Form.Group className="mb-3">
            <Form.Label>
              Введите количество заинвайченных пользователей
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="количество"
              value={dataCount}
              onChange={handleInputChange}
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="secondary" onClick={() => setModalOpen(false)}>
              Отмена
            </Button>
            <Button variant="primary" type="submit" onClick={fetchData}>
              Submit
            </Button>
          </div>
        </Form>
      ) : null}
      <TableContainer style={{ height: '700px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Id</TableCell>
              <TableCell variant="head">Дата создания</TableCell>
              <TableCell variant="head">email</TableCell>
              <TableCell variant="head">Группы для инвайта</TableCell>
              <TableCell variant="head">Пользователи для инвайта</TableCell>
              <TableCell variant="head">Завершение задачи</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task) => (
              <TableRow key={task.id}>
                <TableCell>{task.id}</TableCell>
                <TableCell>
                  {new Date(task.created_at).toLocaleDateString('ru-Ru', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </TableCell>
                <TableCell>{task.user.email}</TableCell>
                <TableCell>
                  <ul>
                    {task.settings.groups.map((group, index) => (
                      <li key={index}>{group}</li>
                    ))}
                  </ul>
                </TableCell>
                <TableCell>
                  <ul>
                    {task.settings.users.map((user, index) => (
                      <li key={index}>{user}</li>
                    ))}
                  </ul>
                </TableCell>
                <TableCell>
                  <Btn_transparent
                    variant="blue"
                    onClick={() => {
                      setModalOpen(true);
                      setId(task.id);
                    }}
                  >
                    завершена
                  </Btn_transparent>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
