import { FC } from 'react';
import React, { useState } from 'react';
import Btn_transparent from '../../ui/btn_transparent/Btn_transparent';
import ModalWindow from '../../ui/modal-window/ModalWindow';
import styles from './tariff-fail-message.module.sass';
import ReplenishmentWindow from '../../ReplenishmentWindow/ReplenishmentWindow';

interface TariffFailMessageProps {
  isActive: boolean;
  setActive: (modalIsActive: boolean) => void;
}

const TariffFailMessage: FC<TariffFailMessageProps> = ({
  isActive,
  setActive,
}) => {
  const [isReplenishmentOpen, setIsReplenishmentOpen] = useState(false);

  const handleReplenishmentClick = () => {
    setActive(false);
    setIsReplenishmentOpen(true);
  };

  return (
    <>
      {isReplenishmentOpen && (
        <ReplenishmentWindow
          isActive={isReplenishmentOpen}
          setActive={() => setIsReplenishmentOpen(false)}
        />
      )}
      {isActive && (
        <ModalWindow isActive={isActive} setActive={setActive}>
          <div className={styles.wrapper}>
            <p className={styles.message}>
              Недостаточно средств
              <br /> для оплаты тарифа
            </p>
            <Btn_transparent
              variant="standart"
              type="submit"
              onClick={handleReplenishmentClick}
            >
              Пополнить баланс
            </Btn_transparent>
          </div>
        </ModalWindow>
      )}
    </>
  );
};

export default TariffFailMessage;
