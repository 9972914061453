import React, { useState } from 'react';
import styles from './advertising.module.sass';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';
import IconButton from '../../ui/iconButton/IconButton';

export default function Advertising() {
  const [opened, setOpened] = useState(true);

  return (
    <>
      {opened && (
        <div className={styles.advertising__wrapper}>
          <div className={styles.advertising__text}>
            <span>
              Зарегистрируйтесь сегодня и получите 50 ₽ на покупку пробного
              тарифа с доступом ко всему функционалу
            </span>
          </div>
          <IconButton
            className={styles.infoClose}
            onClick={() => setOpened(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </>
  );
}
